/*Required modules for bottom tabs nav
 *npm i --save react-router
 *npm i --save react-router-dom
 *npm i --save @fortawesome/fontawesome-svg-core  @fortawesome/free-solid-svg-icons @fortawesome/react-fontawesome
 *npm i --save reactstrap
*/
import React, { useState, useEffect } from 'react';
import { Nav, NavItem } from 'reactstrap';
import { NavLink, useHistory } from 'react-router-dom';
import { useAppContext } from "../../Config/contextLib";
import { APICALL } from "../../Services/ApiServices";
import { NavigationItems, Notifications } from "../../Services/ApiEndPoints";
import './footer.css';
import MenuOutlinedIcon from '@material-ui/icons/MenuOutlined';
import Button from "@material-ui/core/Button";
import Fab from "@material-ui/core/Fab";
import ClearIcon from "@material-ui/icons/Clear";
import '../../Static/css/common.css'
import { t } from '../../Translation/Translation';
import Home from '../../Static/images/home.png';
import Whoiswho from '../../Static/images/whoiswho.png';
import locations from '../../Static/images/footerlocation.png';
import News from '../../Static/images/news.png';
import Profile from '../../Static/images/profile.png';
import FloatEvent from '../../Static/images/FloatEvent.png';
import FloatLtip from '../../Static/images/Ltip.png';
import FloatEshop from '../../Static/images/Eshop.png';
import FloatEmbrace from '../../Static/images/Embrace.png';
import FloatDiscount from '../../Static/images/discount.png';
import FloatSettings from '../../Static/images/Settings.png';
import FloatLibrary from '../../Static/images/Signout.png';
import FloatLib from '../../Static/images/Folder.png';
import FloatSurvey from '../../Static/images/survey.png';
import NotificationBadge from 'react-notification-badge';
import { Effect } from 'react-notification-badge';
import { useClearCache } from "react-clear-cache";
import VacancyIcon from '../../Static/images/vacancy.png';
import IdeaBox from '../../Static/images/idea_box.png';
import SocialIcon from '../../Static/images/social_media.png';
import ToolBoxIcon from '../../Static/images/toolbox.png';
import EmailSignature from '../../Static/images/email_signature.png';
import { isTablet } from "react-device-detect";
import ScanQR from '../../Static/images/barcode-scanner_25px.png';
import Meeting from "../../Static/images/meeting.png";



const tabs = [{ route: "/news", icon: News, label: "News", badge: 0, key: 'newsPosts' },
{ route: "/ourlocations", icon: locations, label: "Location", width: "21", badge: 0, key: 'location' },
{ route: "/whoiswho", icon: Whoiswho, label: "Who is who", badge: 0, key: 'whoiswho' },
{ route: "/", icon: Home, label: "Home", badge: 0, key: 'home' }]

const floatingIcons = [
  { route: "/myprofile", icon: Profile, label: "Profile", badge: 0, key: 'myprofile' },
  { route: "/events", icon: FloatEvent, label: "Events", badge: 0, key: 'events' },
  { route: "/polls", icon: FloatSurvey, label: "Surveys", badge: 0, key: 'polls' },
  { route: "/lunchtips", icon: FloatLtip, label: "Lunch tips", badge: 0, key: 'lunchtip' },
  { route: "/meetingrooms", icon: Meeting, label: "Meeting rooms", badge: 0, key: 'meetingrooms' },
  { route: "/library", icon: FloatLib, label: "Library", badge: 0, key: 'library' },
  { route: "/eshop", icon: FloatEshop, label: "E-Shop", badge: 0, key: 'eshop' },
  { route: "/embrace", icon: FloatEmbrace, label: "Embrace", badge: 0, key: 'embrace' },
  { route: "/discounts", icon: FloatDiscount, label: "Discounts", badge: 0, key: 'discounts' },
  { route: "/SocialMedia", icon: SocialIcon, label: "Social media", baadge: 0, key: 'SocialMedia' },
  { route: "/vacancy", icon: VacancyIcon, label: "Vacancy", badge: 0, key: 'vacancy' },
  { route: "/ideabox", icon: IdeaBox, label: "Ideabox", badge: 0, key: 'Ideabox' },
  { route: "/toolbox", icon: ToolBoxIcon, label: "Toolbox", badge: 0, key: 'toolbox' },
  { route: "/scanQR", icon: ScanQR, label: "Scan QR", badge: 0, key: 'ScanQr' },
  { route: "/settings", icon: FloatSettings, label: "Settings", badge: 0, key: 'settings' },
  { route: "/signature", icon: EmailSignature, label: "Email signature", badge: 0, key: 'emailsignature' },
  { route: "signout", icon: FloatLibrary, label: "Sign out", badge: 0, key: 'signout' },
  // { route: "NMenu", icon: FloatLibrary, label: "New Menu", baadge : 0, key : 'NMenu' },

];

const items = {
  "newsPosts": { route: "/news", icon: News, label: "News", badge: 0, key: 'newsPosts' },
  "location": { route: "/ourlocations", icon: locations, label: "Location", width: "21", badge: 0, key: 'location' },
  "whoiswho": { route: "/whoiswho", icon: Whoiswho, label: "Who is who", badge: 0, key: 'whoiswho' },
  "home": { route: "/", icon: Home, label: "Home", badge: 0, key: 'home' },
  "profile": { route: "/myprofile", icon: Profile, label: "Profile", badge: 0, key: 'profile' },
  "events": { route: "/events", icon: FloatEvent, label: "Events", badge: 0, key: 'events' },
  "polls": { route: "/polls", icon: FloatSurvey, label: "Surveys", badge: 0, key: 'polls' },
  "lunchtip": { route: "/lunchtips", icon: FloatLtip, label: "Lunch tips", badge: 0, key: 'lunchtip' },
  "meetingrooms": { route: "/meetingrooms", icon: Meeting, label: "Meeting rooms", badge: 0, key: 'meetingrooms' },
  "library": { route: "/library", icon: FloatLib, label: "Library", badge: 0, key: 'library' },
  "eshop": { route: "/eshop", icon: FloatEshop, label: "E-Shop", badge: 0, key: 'eshop' },
  "embrace": { route: "/embrace", icon: FloatEmbrace, label: "Embrace", badge: 0, key: 'embrace' },
  "discounts": { route: "/discounts", icon: FloatDiscount, label: "Discounts", badge: 0, key: 'discounts' },
  "SocialMedia": { route: "/SocialMedia", icon: SocialIcon, label: "Social medias", badge: 0, key: 'SocialMedia' },
  "vacancy": { route: "/vacancy", icon: VacancyIcon, label: "Vacancy", badge: 0, key: 'vacancy' },
  "Ideabox": { route: "/ideabox", icon: IdeaBox, label: "Ideabox", badge: 0, key: 'Ideabox' },
  "toolbox": { route: "/toolbox", icon: ToolBoxIcon, label: "Toolbox", badge: 0, key: 'toolbox' },
  "scanqr": { route: "/scanQR", icon: ScanQR, label: "Scan QR", badge: 0, key: 'scanqr' },
  "settings": { route: "/settings", icon: FloatSettings, label: "Settings", badge: 0, key: 'settings' },
  "emailsignature": { route: "/signature", icon: EmailSignature, label: "Email signature", badge: 0, key: 'emailsignature' },
  "signout": { route: "signout", icon: FloatLibrary, label: "Sign out", badge: 0, key: 'signout' },
};

const Navigation = ({ settingtoggleClass }) => {
  const [floatIcons, setfloatIcons] = useState(false);
  const [floatMenuIcon, setfloatMenuIcon] = useState(<MenuOutlinedIcon />);
  const { isAuthenticated, userHasAuthenticated } = useAppContext();
  const [floatItems, setfloatItems] = useState([]);
  const [navItems, setnavItems] = useState([])
  const [floatname, setfloatname] = useState(t('Menu'));
  const get_id = localStorage.getItem('id');
  const { isLatestVersion, emptyCacheStorage } = useClearCache();
  // Checking the device is Desktop or not
  const DesktopMode = (/Android|webOS|iPhone|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) ? false : ((isTablet) ? false: true);
  let screenHeight = Math.max(document.documentElement.clientHeight || 0, window.innerHeight || 0);
  let FTBadgeTotal= 0;
  const [navHeight, setNavHeigt] = useState(0);
  const [isClickable, setisClickable] = useState();
  let floatMenuStyle = '';

  const clickAction = () => {
    if (floatIcons === true) {
      setfloatname(t('Menu'));

    } else {
      setfloatname(t('Close'));
    }
    setfloatIcons(!floatIcons);
    settingtoggleClass(!floatIcons);
    const val = floatIcons ? <MenuOutlinedIcon /> : <ClearIcon />;
    setfloatMenuIcon(val);
  };

  const clickActionForLabel = () => {
    if (floatIcons === true) {
      setfloatname(t('Menu'));
    }
  };

  useEffect(() => {
    let notification = {
      "request_type": "update",
      "type": ""
    }
    setNavHeigt(document.getElementById('app-navbar').offsetHeight);
    let currentpath = window.location.href;
    setisClickable(currentpath.indexOf("preview") > 0 ? false : true);
    // if (currentpath.indexOf("preview") > 0){
    //   let navbarClass = (DesktopMode === true) ?  'desktopModeNavBar' : 'navbar';
    //   document.getElementsByClassName(navbarClass)[0].style.pointerEvents = 'none';
    // }
    //Getting translation and storing in the localstorage.. 
    if (isAuthenticated && floatItems.length === 0) {
      APICALL.service(NavigationItems + '/' + get_id, "PUT", notification, 0, 0).then((result) => {
        if (result && result.length > 0) {
          result.forEach((val, i) => {
            if (val.visibility) {
              if (val.menuType === "Bottom Bar") {
                items[val.itemId].badge = val.badge;
                items[val.itemId].key = val.itemId;
                items[val.itemId].label = val.itemName;
                setnavItems((navItems) => [...navItems, items[val.itemId]]);

              } else if(DesktopMode === true) {
                if (items[val.itemId].key !== 'scanqr'){
                  items[val.itemId].badge = val.badge;
                  items[val.itemId].key = val.itemId;
                  items[val.itemId].label = val.itemName;
                  setfloatItems((floatItems) => [...floatItems, items[val.itemId]]);
                }
              }else {
                items[val.itemId].badge = val.badge;
                items[val.itemId].key = val.itemId;
                items[val.itemId].label = val.itemName;
                setfloatItems((floatItems) => [...floatItems, items[val.itemId]]);
              }
            }
          });
        } else {
          setnavItems(tabs);
          setfloatItems(floatingIcons);
        }
      }).catch(error => {
        console.error("Navigation error : ", error);
      });
    }

  }, []);
  const FooterRedirect = (key) => {
    if (items[key].badge > 0) {
      Object.keys(navItems).map((val) => (
        setnavItems(
          navItems.map((item) =>
            item.key === key
              ? {
                ...item,
                badge: 0,
              }
              : item
          )
        )
      ))
      updateNotificationAPI(key);
    }
    if (floatIcons) {
      setfloatIcons(false);
      settingtoggleClass(false);
      const val = floatIcons ? <MenuOutlinedIcon /> : <ClearIcon />;
      setfloatMenuIcon(val);
    }
  };
  const redirectPage = (key) => {
    setfloatname(t('Menu'))
    if (items[key].badge > 0) {
      Object.keys(floatItems).map((val) => (
        setfloatItems(
          floatItems.map((item) =>
            item.key === key
              ? {
                ...item,
                badge: 0,
              }
              : item
          )
        )
      ))
      updateNotificationAPI(key);
    }
    if (key === "signout") {
      logoutPage();
    } else {
      localStorage.removeItem("location");
      setfloatIcons(!floatIcons);
      settingtoggleClass(false);
      const val = floatIcons ? <MenuOutlinedIcon /> : <ClearIcon />;
      setfloatMenuIcon(val);
      history.push(items[key].route);
    }
  };
  // Notifications api 
  const updateNotificationAPI = (type) => {
    let notification = {
      "request_type": "update",
      "type": type
    }
    APICALL.service(NavigationItems + '/' + get_id, "PUT", notification, 0, 0).then((result) => {
      console.log("update Notification API", result);
    })
  }
  const history = useHistory();

  // Logout 
  const logoutPage = () => {
    let loginType = localStorage.getItem('loginType');
    if(loginType === "sso"){
      let auth_token = localStorage.getItem('auth_token');
      window.location.href = process.env.REACT_APP_BACKEND_URL+"sso-logout-api?rid=1&token="+auth_token;
    }
    setfloatIcons(!floatIcons);
    settingtoggleClass(false);
    userHasAuthenticated(false);
    if (!isLatestVersion) {
      emptyCacheStorage();
    }
    const val = floatIcons ? <MenuOutlinedIcon /> : <ClearIcon />;
    setfloatMenuIcon(val);
    let data = {
      "user_id": localStorage.getItem("id"),
      "type": "log_out"
    }
    APICALL.service(Notifications, "POST", data, 0, 0)
      .then(result => {
        console.log("Push Notifications", result)
      })
    localStorage.clear();
    //Deleting the cache in code
    try {
      caches.keys().then((keys) => {
        return Promise.all(
          keys
            .map((key) => caches.delete(key))
        );
      });
    } catch (e) {
      console.error(e);
    }

    history.replace("/login");
  };

  if (localStorage.getItem("userStatus") === "active") {
    logoutPage();
  }
  floatItems.map((floating, index) => (
    FTBadgeTotal = FTBadgeTotal + floating.badge
  ))
  return (
    <div className={`footer ${isTablet ? 'Tabletcontainer' : ''} `}>
      {/* Bottom Tab Navigator
      * Right side floating icons hiding for desktop devices
      *
      */}
      {DesktopMode === false && floatIcons && (
          <div className={`floating-menu-custom ${isTablet ? 'Tablet-footer' : ''} `}>
          <div className='customscroll menu-scroll' style = {{height:(isTablet && window.matchMedia("(orientation: portrait)").matches)
            //this one is to calcualte the height in case of tablet
            // total menu items * height of each menuitem occupying the space
             ? 'calc('+ (floatItems.length * 56) +'px + 1vh)' 
             // screenheight - navbar height - 2vh is approx number to meet customer
             : ( (floatItems.length * 56 + navHeight) > screenHeight) ? 'calc(' + screenHeight + 'px - ' + ( navHeight) + 'px - 2vh)' : 'calc('+ (floatItems.length * 56) +'px + 1vh)' }}>
          {floatItems.map((floating, index) => (
            <div className="m-2 text-right flaotMenus" key={`float-${index}`}>
              <div className="Notification-BadgeCount badgeDesign"><NotificationBadge count={floating.badge} effect={Effect.ROTATE_Y}/></div>
             <Button  onClick={() => redirectPage(floating.key)} className="textcolor" >{floating.label}</Button>
              <Fab
                className="bg-color"
                aria-label={floating.label}
                onClick={() => redirectPage(floating.key)}
              > 
                <img src={floating.icon} className="footericons" alt = {floating.key} />
              </Fab>
            </div>
          ))}
          </div>
        </div>
      )}
      {/* 
      * Changing the Navbar design for mobile bottom of page
      * For desktop Navbar should be left side.
      */}
      <nav id="app-navbar"
        className={DesktopMode === true ? "desktopModeNavBar" : "navbar fixed-bottom navbar-light px-0 bg-color container customContainer"}
        role="navigation"
      >
        <Nav className="w-100">
          <div className={DesktopMode === true ? "" : "d-flex flex-row justify-content-around w-100"}>
            <NavItem
              className={DesktopMode === true ? "humburgerMenu" : "hide-class"}
              key={`tab-4`}
              onClick={isClickable ? clickAction : null}
            >
              <NavLink to="#" className="nav-link" activeClassName="active" disabled={isClickable ? false : true} style={isClickable ? null : { color: "#fff" }}>
                <div className="row d-flex flex-column justify-content-center align-items-center tab-icon badgeDesign">
                  <NotificationBadge count={FTBadgeTotal} effect={Effect.ROTATE_Y} />
                  {floatMenuIcon}
                  <div className="tab-title">{floatname}</div>
                </div>
              </NavLink>
            </NavItem>
            {navItems.map((tab, index) => (
              <NavItem key={`tab-${index}`}
              onClick={isClickable ? clickActionForLabel : null}>
                <NavLink
                  to={tab.route}
                  onClick={() => FooterRedirect(tab.key)}
                  className="nav-link"
                  activeClassName="active"
                  disabled={isClickable ? false : true}
                >
                  <div className="row d-flex flex-column justify-content-center align-items-center tab-icon badgeDesign">
                    <NotificationBadge count={tab.badge} effect={Effect.ROTATE_Y} />
                    <img src={tab.icon} className="footericons" width={tab.width} alt={tab.key} />
                    <div className="tab-title textcolor2">{tab.label}</div>
                  </div>
                </NavLink>
              </NavItem>
            ))}
            {/* 
              Hiding Menu for Desktop devices
            */}
            <NavItem
              className={DesktopMode === true ? "hide-class" : "humburgerMenu"}
              // onClick={clickAction}
              onClick={isClickable ? clickAction : null}
            >
              <NavLink to="#" className="nav-link" activeClassName="active">

                <div className={DesktopMode === true ? "" : "row d-flex flex-column justify-content-center align-items-center tab-icon badgeDesign"}>
                  <NotificationBadge count={FTBadgeTotal} effect={Effect.ROTATE_Y} />
                  {floatMenuIcon}
                  <div className="tab-title">{floatname}</div>
                </div>
              </NavLink>
            </NavItem>
          </div>
        </Nav>
      </nav>
      {/* 
      * Left side floating icons for desktop devices
      */}
      {floatIcons &&
        <div className={DesktopMode === true ? "desktopModeFloatingIcons " : "hide-class"}>
          <div className='customscroll menu-scroll'>
            {floatItems.map((floating, index) => (
              <div className="" key={`float-${index}`}>
                <div className="Notification-BadgeCount badgeDesign"><NotificationBadge count={floating.badge} effect={Effect.ROTATE_Y} /></div>
                <Fab
                  className="floatingIconWidth"
                  aria-label={floating.label}
                  onClick={() => redirectPage(floating.key)}
                >
                  <img src={floating.icon} className="footericons" alt={floating.key} />
                </Fab>
                <Button onClick={() => redirectPage(floating.key)} className="textcolor">{floating.label}</Button>
              </div>
            ))}
          </div>
        </div>
      }
    </div>
  );
};
export default Navigation;