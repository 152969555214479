import React, { useState, useEffect } from "react";
import "./News.css";
import { NewsUrl, likes, ReactionsList } from "../../Services/ApiEndPoints";
// import { likes } from "../../Services/ApiEndPoints";
import { APICALL } from "../../Services/ApiServices";
import ItemDetails from "../Common/PageItemDetails";
// import SearchBox from "../Common/SearchBox";
import moment from "moment";
import { useHistory } from "react-router-dom";
import { t } from "../../Translation/Translation";
import NewsDetails from "./NewDetails";
import { useParams } from "react-router";
import BackButtonComponent from "../../Utilities/BackButtonComponent";
import previewFn from "../../Common/Preview";
import BackButton from "../../Utilities/BackButton";
// import { ReactionsList, likes } from "../../Services/ApiEndPoints";
import InfiniteScroll from "react-infinite-scroll-component";
import SearchBoxWithSubmit from "../Common/SearchBoxWithSubmit";
import newsletterFn from "../../Common/Newsletter";

function News() {
  const [newslist, setnewlist] = useState();
  const [searchVal, setsearchVal] = useState("");
  const [dataList, setdataList] = useState([]);
  // const excludeColumns = ["id", "date", "content"];
  const get_id = localStorage.getItem("id");
  const history = useHistory();
  const [DeviceMode, setDeviceMode] = useState(false);
  const [defaultitem, setdefaultitem] = useState();
  const [listviewMode, setlistviewMode] = useState(false);
  const [updateStatus, setupdateStatus] = useState();
  let params = useParams();
  const [backbuttonUrl, setbackbuttonUrl] = useState();
  const [urlState, seturlState] = useState(false);
  const [reactionsList, setReactionsList] = useState([]);
  const [newReaction, setNewReaction] = useState([]);
  const [newReactionEntryId, setNewReactionEntryId] = useState();
  let user_id = localStorage.getItem("id");
  const [offset, setoffset] = useState(0);
  const [hasMore, sethasMore] = useState(true);
  // const [searchVal, setsearchVal] = useState("");
  const [searchState, setSearchState] = useState(false);

  let resutingdata = [];
  useEffect(() => {
    //checking Device mode
    if (localStorage.getItem("DeviceMode") === "Desktop") {
      setDeviceMode(true);
    }
    let param = previewFn(params);
    if(param == null){
      param = newsletterFn(params);
    }
    let data = {
      user_id: get_id,
      param: param,
      search: searchVal,
      offset: offset,
    };
    APICALL.service(NewsUrl, "POST", data)
      .then((result) => {
        let dataList2 = [];
        if (result.status !== 404 && result.status !==401 && result.length !== 0) {
          if (!hasMore)sethasMore(true);
          Object.keys(result).map((value, i) => {
            resutingdata.push(result[value]);
          });
          // sorting  array based on postdate its dispalying
          let sortingOrder = resutingdata.sort((d1, d2) => {
            return (
              new Date(d1.post_date).getTime() -
              new Date(d2.post_date).getTime()
            );
          });
          //change post_date format
          sortingOrder.map(
            (value, i) => (
              (value["post_date"] = moment(new Date(value.post_date)).format(
                "DD/MM/YYYY"
              )),
              dataList2.push(value)
            )
          );
          //Always load the first item from List View by default in detail view page
          if (localStorage.getItem("DeviceMode") === "Desktop") {
            const lastItem = dataList2[dataList2.length - 1]
            // const lastItem = dataList2[0];
            setdefaultitem({ id: lastItem.id, type: "News" });
          }
          if (params.id !== undefined) {
            setdefaultitem({ id: params.id, type: "News" });
            setlistviewMode(true);
          }
          dataList2 = dataList2.reverse();
          setdataList(dataList2);
          setnewlist(dataList2);
        }
        else {
          sethasMore(false);
          if (localStorage.getItem("DeviceMode") === "Desktop") {
            setdefaultitem();
          }
          setnewlist("");
        }
      })
      .catch((error) => {
        console.error(error);
      });
  }, [searchState]);

  function fetchMoreData() {
    let count = offset + 1;
    let data = {
      user_id: get_id,
      offset: count,
      search: searchVal,
    };
    setoffset(count);
    APICALL.service(NewsUrl, "POST", data)
      .then((result) => {
        let dataList2 = [];
        if (result.status === 404) {
          sethasMore(false);
        }
        if (result) {
          Object.keys(result).map((value, i) => {
            resutingdata.push(result[value]);
          });
          // sorting  array based on postdate its dispalying
          let sortingOrder = resutingdata.sort((d1, d2) => {
            return (
              new Date(d1.post_date).getTime() -
              new Date(d2.post_date).getTime()
            );
          });
          //change post_date format
          sortingOrder.map(
            (value, i) => (
              (value["post_date"] = moment(new Date(value.post_date)).format(
                "DD/MM/YYYY"
              )),
              dataList2.push(value)
            )
          );
          dataList2 = dataList2.reverse();
          setdataList(dataList.concat(dataList2));
          setnewlist(dataList.concat(dataList2));
        }
      })
      .catch((error) => {
        console.error(error);
      });
  }

  //TO update detail page when user click on list item
  const updatesId = (e) => {
    setdefaultitem(e);
    if (localStorage.getItem("DeviceMode") === "Desktop") {
      setlistviewMode(false);
    } else {
      setlistviewMode(true);
    }
  };
  // To update likes counts
  const updateslikes = (e) => {
    updatingNewlistView(e);
    setupdateStatus(updateStatus ? false : true);
  };
  // updating newlistview setState values
  function updatingNewlistView(e) {
    setnewlist(
      newslist.map((item) =>
        item.id === e
          ? {
              ...item,
              user_like_status: item.user_like_status === true ? false : true,
            }
          : item
      )
    );
  }

  // updating newlistview setState values
  function updateReactionsInList(e, entryId, reaction) {
    setnewlist(
      newslist.map((item) =>
        item.id === entryId
          ? {
              ...item,
              reactions: reaction,
            }
          : item
      )
    );
    setNewReactionEntryId(entryId);
    setNewReaction(reaction);
  }

  useEffect(() => {
    const res = {
      user_id: user_id,
    };
    // api call to fetch url from backend
    APICALL.service(ReactionsList, "POST", res).then((result) => {
      if (result != null)
        if (result.STATUS === 201) {
          // set result in state
          setReactionsList(result.DATA);
        } else {
          setReactionsList([]);
        }
    });
  }, []);

  function UpdateDetailsLikes(e) {
    updatingNewlistView(e);
  }

  // const SearchElements = (e) => {
  //   setsearchVal(e.target.value);
  //   filterData(e.target.value);
  // }

  // filter records by search text
  // const filterData = (value) => {
  //   const lowercasedValue = value.toLowerCase().trim();
  //   if (lowercasedValue === "") setnewlist(dataList);
  //   else {
  //     const filteredData = (dataList ? Object.values(dataList).filter(item => {
  //       return Object.keys(item).some(key =>
  //         item[key] !== null ? excludeColumns.includes(key) ? false : item[key].toString().toLowerCase().includes(lowercasedValue) : null
  //       );
  //     }) : [null])
  //     setnewlist(filteredData)
  //   }
  // }

  // back button funtionality
  function historyBack() {
    let response = BackButton();
    history.push(response);
  }

  //backbotton form details page to listview
  const backButton = (e) => {
    setlistviewMode(e);
  };
  // store the url in session
  useEffect(() => {
    if (urlState === false) {
      if (params.id === undefined) {
        setbackbuttonUrl("/news");
      } else {
        setbackbuttonUrl("/news/" + params.id);
      }
      seturlState(true);
    }
  }, []);

  //search parameter passing to the function
  const SearchElements = (e) => {
    setsearchVal(e.target.value);
    // setoffset(0);
    // filterData(e.target.value);
  };

  const searchSubmit = (e) => {
    e.preventDefault();
    setoffset(0);
    setSearchState(!searchState);
  };

  /*
  ListView Compoment
  */
  const listMode = () => {
    console.log(defaultitem)
    return (
      <div className="container customContainer">
        <div className="col-md-12 topsection-wrap  mt-0 px-0 pt-4">
          {localStorage.getItem("DeviceMode") !== "Desktop" ? (
            <p className="browserback" onClick={historyBack}>
              back
            </p>
          ) : null}
          <h1 className="pagetitle textcolor1 text-center">{t("News")}</h1>
        </div>
        {/* <SearchBox onSearchValue={SearchElements} searchVal={searchVal} /> */}
        <SearchBoxWithSubmit
          onSearchValue={SearchElements}
          searchVal={searchVal}
          searchSubmit={searchSubmit}
        />

        <div className="post">
          {newslist === undefined && (
            <div className="col-12 text-center">
              <h5 className="text-center textcolor1"> {t("Loading news")}.</h5>{" "}
            </div>
          )}
          {newslist !== undefined && (
            <InfiniteScroll
              dataLength={newslist.length}
              next={fetchMoreData}
              hasMore={hasMore}
              loader={
                <h5 className="textcolor1 text-center">{t("Loading")}.</h5>
              }
              scrollableTarget="scrollableDiv"
            >
              {newslist !== undefined &&
                Object.keys(newslist)
                  // .reverse()
                  .map((value, i) => {
                    return (
                      <ItemDetails
                        key={i}
                        data={newslist[value]}
                        type="News"
                        updateslikes={updateslikes}
                        updatesId={updatesId}
                        updateReactionsInList={updateReactionsInList}
                        reactionsList={reactionsList}
                        newReactionEntryId={newReactionEntryId}
                        activeItem={defaultitem}
                      />
                    );
                  })}
            </InfiniteScroll>
          )}
          {newslist !== undefined && newslist.length < 1 ? (
            <div className="col-md-12 col-12 text-center">
              <h4 className="textcolor1">{t("No News Available")}</h4>
            </div>
          ) : null}
          {backbuttonUrl && (
            <BackButtonComponent backButtonLink={backbuttonUrl} />
          )}
        </div>
      </div>
    );
  };
  /*
   * check and split the page for desktop device by using DesktopMode variable
   * display list view and detalview side by side
   */
  return (
    <div>
      {DeviceMode === true ? (
        <div className="Desktop-mode-list-and-detail-view layoutSpace">
          <div className="Desktop-mode-list-view float-lg-left col-lg-5 pr-0">
            <div className="customscroll" id="scrollableDiv">
              {listMode()}{" "}
            </div>
          </div>
          <div className="Desktop-mode-Detail-view float-lg-left col-lg-7 pr-4 pl-1">
            <div className="customscroll">
              {defaultitem && (
                <NewsDetails
                  updatesEntityId={defaultitem}
                  CommentsStatus={false}
                  updateDetailslikes={UpdateDetailsLikes}
                  UpdateStatus={updateStatus}
                  updateReactionsInList={updateReactionsInList}
                  reaction={newReaction}
                  reactionsList={reactionsList}
                  newReactionEntryId={newReactionEntryId}
                />
              )}
            </div>
          </div>
        </div>
      ) : listviewMode === false && params.id === undefined ? (
        listMode()
      ) : (
        <NewsDetails
          updatesEntityId={defaultitem}
          CommentsStatus={false}
          updateDetailslikes={UpdateDetailsLikes}
          backButton={backButton}
          updateReactionsInList={updateReactionsInList}
          reaction={newReaction}
          reactionsList={reactionsList}
          newReactionEntryId={newReactionEntryId}
        />
      )}
    </div>
  );
}
export default News;
