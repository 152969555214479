import React, { useState, useEffect } from "react";
import ItemDetails from "../Common/PageItemDetails";
// import SearchBox from "../Common/SearchBox";
import { poll } from "../../Services/ApiEndPoints";
import { APICALL } from "../../Services/ApiServices";
import { t } from "../../Translation/Translation";
import { useHistory } from "react-router-dom";
import PollsDetails from "./PollsDetails";
import BackButtonComponent from "../../Utilities/BackButtonComponent";
import previewFn from "../../Common/Preview";
import { useParams } from "react-router";
import BackButton from "../../Utilities/BackButton";
import InfiniteScroll from "react-infinite-scroll-component";
import SearchBoxWithSubmit from "../Common/SearchBoxWithSubmit";

export default function Polls() {
  const [polls, setpolls] = useState();
  const [DeviceMode, setDeviceMode] = useState("false");
  const [defaultitem, setdefaultitem] = useState();
  const [listviewMode, setlistviewMode] = useState(false);
  const [searchVal, setsearchVal] = useState("");
  const [dataList, setdataList] = useState([]);
  // const excludeColumns = ["id", "date"];
  const history = useHistory();
  const [updatestatus, setupdatestatus] = useState();
  let params = useParams();
  const [backbuttonUrl, setbackbuttonUrl] = useState();
  const [urlState, seturlState] = useState(false);
  const [hasMore, sethasMore] = useState(true);
  const [offset, setoffset] = useState(0);
  const [searchState, setSearchState] = useState(false);

  useEffect(() => {
    if (urlState === false) {
      if (params.id === undefined) {
        setbackbuttonUrl("/polls");
      } else {
        setbackbuttonUrl("/polls/" + params.id);
      }
      seturlState(true);
    }
  }, []);

  function historyBack() {
    let response = BackButton();
    history.push(response);
  }

  useEffect(() => {
    //checking Device mode
    if (localStorage.getItem("DeviceMode") === "Desktop") {
      setDeviceMode(true);
    }
    const get_id = localStorage.getItem("id");
    let param = previewFn(params);
    let data = {
      user_id: get_id,
      type: "view",
      param: param,
      search: searchVal,
      offset: offset,
    };
    APICALL.service(poll, "POST", data).then((result) => {
      let dataList2 = [];
      if (result.status !== 404 && result.status !== 401) {
        if (!hasMore) sethasMore(true);
        try {
          Object.keys(result).map((value, i) => {
            dataList2.push(result[value]);
            // sorting  array based on postdate its dispalying
            dataList2.sort((d1, d2) => {
              return (
                new Date(d1.post_date).getTime() -
                new Date(d2.post_date).getTime()
              );
            });
            dataList2 = dataList2.reverse();
            setdataList(dataList2);
            setpolls(dataList2);
          });
          //Always load the first item from List View by default in detail view page
          setdefaultitem({ id: dataList2[0].id, type: "Surveys" });
          if (params.id !== undefined) {
            setdefaultitem({ id: params.id, type: "Surveys" });
            setlistviewMode(true);
          }
        } catch (e) {
          console.error(e);
        }
      } else {
        sethasMore(false);
        if (localStorage.getItem("DeviceMode") === "Desktop") {
          setdefaultitem();
        }
        setpolls("");
      }
    });
  }, [searchState]);

  function fetchMoreData() {
    const get_id = localStorage.getItem("id");
    let param = previewFn(params);
    let count = offset + 1;
    let data = {
      user_id: get_id,
      type: "view",
      param: param,
      search: searchVal,
      offset: count,
    };
    setoffset(count);
    APICALL.service(poll, "POST", data).then((result) => {
      let dataList2 = [];
      if (result.status === 404) {
        sethasMore(false);
      }
      if (result.status !== 404 && result.status !== 401) {
        try {
          Object.keys(result).map((value, i) => {
            dataList2.push(result[value]);
            // sorting  array based on postdate its dispalying
            dataList2.sort((d1, d2) => {
              return (
                new Date(d1.post_date).getTime() -
                new Date(d2.post_date).getTime()
              );
            });
            dataList2 = dataList2.reverse();
            setdataList(dataList.concat(dataList2));
            setpolls(dataList.concat(dataList2));
          });
        } catch (e) {
          console.error(e);
        }
      }
    });
  }

  // filter records by search text
  const SearchElements = (e) => {
    setsearchVal(e.target.value);
    // filterData(e.target.value);
  };

  //TO update detail page when user click on list item
  const updatesId = (e) => {
    setdefaultitem(e);

    if (localStorage.getItem("DeviceMode") === "Desktop") {
      setlistviewMode(false);
    } else {
      setlistviewMode(true);
    }
  };

  // filter records by search text
  // const filterData = (value) => {
  //   const lowercasedValue = value.toLowerCase().trim();
  //   if (lowercasedValue === "") setpolls(dataList);
  //   else {
  //     const filteredData = (dataList ? Object.values(dataList).filter(item => {
  //       return Object.keys(item).some(key =>
  //         item[key] != null ? excludeColumns.includes(key) ? false : item[key].toString().toLowerCase().includes(lowercasedValue) : null
  //       );
  //     }) : [null])
  //     setpolls(filteredData)
  //   }
  // }

  // updating newlistview setState values when user click vote button
  const updateslikes = (e) => {
    setupdatestatus(e);
    Object.keys(polls).map((key) =>
      setpolls(
        polls.map((item) =>
          item.id === e
            ? {
                ...item,
                user_response_status:
                  polls[key].user_response_status === true ? false : true,
              }
            : item
        )
      )
    );
  };
  //backbotton form details page to listview
  const backButton = (e) => {
    setlistviewMode(e);
  };

  const searchSubmit = (e) => {
    e.preventDefault();
    setoffset(0);
    setSearchState(!searchState);
  };

  /*
   ListView Compoment
   */
  const listMode = () => {
    return (
      <div className="container customContainer">
        <div className="col-md-12 topsection-wrap  mt-0 px-0 pt-4">
          <p
            className={DeviceMode === true ? "hide-class" : "browserback"}
            onClick={historyBack}
          >
            back
          </p>
          {backbuttonUrl && (
            <BackButtonComponent backButtonLink={backbuttonUrl} />
          )}
          <h1 className="pagetitle textcolor1 text-center">{t("Surveys")}</h1>
        </div>
        {/* <SearchBox onSearchValue={SearchElements} searchVal={searchVal} /> */}
        <SearchBoxWithSubmit
          onSearchValue={SearchElements}
          searchVal={searchVal}
          searchSubmit={searchSubmit}
        />
        <div className="post">
          {polls === undefined && (
            <div className="col-12 text-center">
              <h5 className="text-center textcolor1">
                {" "}
                {t("Loading surveys")}.
              </h5>{" "}
            </div>
          )}
          {polls !== undefined && (
            <InfiniteScroll
              dataLength={polls.length}
              next={fetchMoreData}
              hasMore={hasMore}
              loader={
                <h5 className="textcolor1 text-center">{t("Loading")}.</h5>
              }
              scrollableTarget="scrollableDiv"
            >
              {polls !== undefined &&
                Object.keys(polls).map((value, i) => {
                  return (
                    <ItemDetails
                      data={polls[value]}
                      type="Surveys"
                      key={i}
                      updateslikes={updateslikes}
                      updatesId={updatesId}
                      activeItem={defaultitem}
                    />
                  );
                })}
            </InfiniteScroll>
          )}
          {polls !== undefined && polls.length < 1 ? (
            <div className="col-md-12 col-12 text-center">
              <h4 className="textcolor1">{t("No Surveys Available")}</h4>
            </div>
          ) : null}
          {/* {polls === undefined || polls.length === 0 &&polls[0] === '404' &&<h5 className="col-12 text-center" > {t("No Surveys Available")} </h5>} */}
        </div>
      </div>
    );
  };
  /*
   * check and split the page for desktop device by using DesktopMode variable
   * display list view and detalview side by side
   */
  return (
    <div>
      {DeviceMode === true ? (
        <div className="Desktop-mode-list-and-detail-view layoutSpace ">
          <div className="Desktop-mode-list-view float-lg-left col-lg-5 pr-0">
            {/* <div className="customscroll">{listMode()}</div> */}
            <div className="customscroll" id="scrollableDiv">
              {" "}
              {listMode()}{" "}
            </div>
          </div>
          <div className="Desktop-mode-Detail-view float-lg-left col-lg-7 pr-4 pl-1">
            <div className="customscroll">
              {" "}
              {defaultitem && (
                <PollsDetails
                  updatesEntityId={defaultitem}
                  updateslikes={updateslikes}
                  Results={false}
                  UpdateStatus={updatestatus}
                />
              )}
            </div>
          </div>
        </div>
      ) : listviewMode === false && params.id === undefined ? (
        listMode()
      ) : (
        <PollsDetails
          updatesEntityId={defaultitem}
          updateslikes={updateslikes}
          Results={false}
          UpdateStatus={updatestatus}
          backButton={backButton}
        />
      )}
    </div>
  );
}
