import React, { useState, useEffect } from "react";
import { Discount } from "../../Services/ApiEndPoints";
import { APICALL } from "../../Services/ApiServices";
import SinglePageLayout from "../Common/singlePageLayout";

function Discounts() {
  const [Discountsdata, setDiscountsdata] = useState();

  useEffect(() => {
    let User_id = localStorage.getItem("id");
    const res = {
      user_id: User_id,
    };
    APICALL.service(Discount, "POST", res).then((result) => {
      if (result != null) setDiscountsdata(result.discount);
    });
  }, []);

  return (
    <SinglePageLayout
      header={"Discounts"}
      layoutData={Discountsdata}
      url={"/discounts"}
    />
  );
}

export default Discounts;
