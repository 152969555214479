import React, { useState, useRef } from "react";
import { Document, Page, pdfjs } from "react-pdf";
import "./pdf.css";
import '../../Static/css/common.css';
import { t } from '../../Translation/Translation';
pdfjs.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

/**
 * 
 * @pdf for getting the url {*} props 
 * @wrapperDivSize for getting the pdf default width fit to screen4
 * @type (1 => for showing the single page with next button, 2=> showing the all the screens)
 */
const PDFReader = (props) => {
    const [numPages, setNumPages] = useState(null);
    const [pageNumber, setPageNumber] = useState(1); //setting 1 to show fisrt page
    const pdf = props.pdf;
    const pdfDisplayType = useRef(props.type);
    function onDocumentLoadSuccess({ numPages }) {
        setNumPages(numPages);
        setPageNumber(1);
    }

    function changePage(offset) {
        setPageNumber((prevPageNumber) => prevPageNumber + offset);
    }

    function previousPage() {
        changePage(-1);
    }

    function nextPage() {
        changePage(1);
    }

    return (
      <>
        {props.wrapperDivSize && (
          <>
            {(pdfDisplayType.current === 2) ? (<div className="document-pdf">
              <Document file={pdf} onLoadSuccess={onDocumentLoadSuccess} scale={2} >
                {Array.from(new Array(numPages), (el, index) => (
                  <Page
                    key={`page_${index + 1}`}
                    pageNumber={index + 1}
                    renderMode="svg"
                    renderTextLayer={false}
                    width={props.wrapperDivSize}
                    scale={1}
                  />
                ))}
              </Document>
            </div>)
              : (<div className="document-pdf">
                <Document file={pdf} onLoadSuccess={onDocumentLoadSuccess} scale={2} >
                  <Page
                    pageNumber={pageNumber}
                    renderMode="svg"
                    renderTextLayer={false}
                    width={props.wrapperDivSize}
                  />
                </Document>
                {numPages > 0 && (
                  <div>
                    <p className="mt-2"> {t('Page')} {pageNumber || (numPages ? 1 : "--")} {t('of')} {numPages || "--"} </p>
                    <button type="button" className="bg-color btn float-left  pdfButtons" disabled={pageNumber <= 1} onClick={previousPage}> {t('Previous')} </button>
                    <button type="button" className="bg-color btn float-right pdfNext pdfButtons" disabled={pageNumber >= numPages} onClick={nextPage} > {('Next')} </button>
                  </div>
                )}
              </div>)}
            {/*End of page naviation button*/}
          </>
        )}
      </>
    );
};

export default PDFReader
