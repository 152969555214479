import React, { useState, useEffect } from 'react';
import { LibraryUrl } from "../../Services/ApiEndPoints";
import { APICALL } from "../../Services/ApiServices";
import { useHistory } from "react-router-dom";
import LibraryHome from "./LibraryHome";
import LibraryList from "./LibraryList";
import LibraryView from "./LibraryView";
import { t } from '../../Translation/Translation'
import BackButtonComponent from '../../Utilities/BackButtonComponent';
import BackButton from "../../Utilities/BackButton";
import "./Library.css";

function Library(props) {
  const [Librarylist, setLibrarylist] = useState()
  const [dataList, setdataList] = useState([]);
  const [pageType, setpageType] = useState("home");
  const [listValue, setListValue] = useState("");
  const [title, setTitle] = useState(t("Library"));
  const [fileUrl, setfileUrl] = useState("");
  const history = useHistory();
  const DesktopMode = (localStorage.getItem('DeviceMode')).includes('Desktop');
  const [backbuttonUrl, setbackbuttonUrl] = useState();
  const [urlState, seturlState] = useState(false);
  const [highlightitem, setHighlightitem] = useState();

  let location = "";
  const [backButton, setBackButton] = useState();
  if (props.location.state) {
    location = props.location.state.location;
    localStorage.setItem("location", location);
  }

  useEffect(() => {
    if (localStorage.getItem("location") && !location) {
      location = localStorage.getItem("location");
    }
    APICALL.service(LibraryUrl, "GET")
      .then(result => {
        if (result) {
          localStorage.setItem("library", JSON.stringify(result));
          result = JSON.parse(localStorage.getItem("library"));
          Object.keys(result).map((value, i) => {
            if (location === "" || result[value].location === location) {
              // result[value].data[0] = APICALL.proxy_url + result[value].data[0];
              dataList[value] = result[value];
              dataList[value]["id"] = value;
            }
          })
          setLibrarylist(dataList)
          setdataList(dataList);
          // check desktop mode or not
          // Define Default detail page as per Api data for desktop device
          if (DesktopMode === true) {
            if (dataList[(Object.keys(dataList)[0])].data.length === 1) {
              setfileUrl(dataList[(Object.keys(dataList)[0])].data[0]);
              setpageType("view");
              setTitle(dataList[(Object.keys(dataList)[0])].title);
              setHighlightitem(dataList[(Object.keys(dataList)[0])].id)
            }
            else {
              setListValue(Object.keys(dataList)[0]);
              setpageType("list");
              setTitle(dataList[(Object.keys(dataList)[0])].title);
              setHighlightitem(dataList[(Object.keys(dataList)[0])].id)
            }
          }
        }
      })
      .catch(error => {
        setLibrarylist(undefined)
      });
  }, []);
  /***
  * Store url in session storage for back button functionality
  */
  useEffect(() => {
    if (urlState === false) {
      setbackbuttonUrl('/library');
      seturlState(true);
    }
  }, [])
  
  //Remove the last item from array and push the url for back button 
  function historyBack() {
      let response = BackButton();
        history.push(response);
  }

  const previewContent = (id, ClickLibrary) => {

    // if (pageType === "list") {
    //   // check the document is clicked or not by ClickLibrary variable
    //   // variable is true goto detials page from list view
    //   if (ClickLibrary === true) {
    //     setfileUrl(id);
    //     setpageType("view");
    //   }
    //   else {
    //     setListValue(id);
    //     setpageType("list");
    //     setTitle(dataList[id].title);
    //   }
    // } 
    //Getting the view from the list
    setHighlightitem(id)

    if (ClickLibrary === true) {
      setfileUrl(id);
      setpageType("view");
      setBackButton(true);
    } else if (dataList[id].data.length === 1) { //getting the view from the home page
      setfileUrl(dataList[id].data[0]);
      setpageType("view");
      setTitle(dataList[id].title);
      setBackButton(false);
    } else {
      setListValue(id);
      setpageType("list");
      setTitle(dataList[id].title);
      setBackButton(false);

    }
  }

  const libraryback = () => {
    setfileUrl("");
    if (pageType === "home") {
      historyBack();
    } else if (pageType === "list") {
      setTitle(t("Library"));
      setListValue("");
      setpageType("home");
    } else {

      if (listValue !== "") {
        setpageType("list");
      } else {
        setTitle(t("Library"));
        setpageType("home");
      }
    }
  }
  const LibraryPage = () => {
    return (
      <div className="container customContainer">
        <div className="col-md-12 topsection-wrap mt-0 px-0 pt-4">
          {/*
          * Hide back button for desktop device.
          */}
          <p className={DesktopMode ? "hide-class" : "browserback"} onClick={libraryback}>back</p>
          {backbuttonUrl && <BackButtonComponent backButtonLink={backbuttonUrl} />}
          {/* 
           * For Desktop mode hide title change for list view page.
           */}
          {DesktopMode === false && <h1 className="pagetitle textcolor1 text-center">{title}</h1>}
          {/* 
           * Hard code the title in list view page for desktop mode to prevent title change
           */}
          {DesktopMode === true && <h1 className="pagetitle textcolor1 text-center">{t("Library")}</h1>}
        </div>
        {/* 
          Hide Libray Home, Library View and Libray list for desktop listview to prevent page change on listview page 
        */}
        {DesktopMode === false && pageType === "home" && (
          <LibraryHome
            dataList={dataList}
            Librarylist={Librarylist}
            previewContent={previewContent}
          />
        )}
        {DesktopMode === false && pageType === "list" && (
          <LibraryList
            dataList={dataList}
            id={listValue}
            previewContent={previewContent}
          />
        )}
        {DesktopMode === false && pageType === "view" && <LibraryView data={fileUrl} />}
        {DesktopMode === true && (
          <LibraryHome
            dataList={dataList}
            Librarylist={Librarylist}
            previewContent={previewContent}
            highlightitem={highlightitem}
          />
        )}
      </div>
    )
  }
  return (
    <>
      {/* 
    * check and split the page for desktop device by using DesktopMode variable
    * display list view and detalview side by side
    */}
      {
        DesktopMode === true ? <div className="Desktop-mode-list-and-detail-view layoutSpace">
          <div className="Desktop-mode-list-view float-lg-left col-lg-5 pr-0 ">
          <div className="customscroll">{LibraryPage()}</div>
          </div>
          <div className="Desktop-mode-Detail-view float-lg-left col-lg-7 pr-4 pl-1">
            {/* {defaultitem && <NewsDetails updatesEntityId = {defaultitem} /> } */}
            <div className="col-md-12 topsection-wrap mt-0 px-0 pt-4">
              {/* hide back button for listview page for desktop version */}
              <p className={DesktopMode ? backButton === true && pageType !== "list" ? "browserback" : "hide-class" : "browserback"} onClick={libraryback}>back</p>
              <h1 className="pagetitle textcolor1 text-center">{title}</h1>
            </div>
            {pageType === "list" && (
              <LibraryList
                dataList={dataList}
                id={listValue}
                previewContent={previewContent}
              />
            )}
            {pageType === "view" && <LibraryView data={fileUrl} />}
          </div>
        </div> :
          LibraryPage()
      }
    </>
  );
}
export default Library;
