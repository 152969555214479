import React, {useState} from 'react'
import { Link } from "react-router-dom";
import './Login.css';

const EmailForm = ({ onSubmit, updateField, form}) => {
    const [showPassword, setShowPassword] = useState(false);

    return (
      <div className="mt-5">
        <p className="textcolor11 mt-4 mb-2 pagetitle">Login</p>
        <form onSubmit={onSubmit} className="loginForm ">
          <div className="mt-5">
            <div className="form-group mb-3">
              <label className="textcolor1">Email</label>
              <input
                type="email"
                name="email"
                className="form-control"
                value={form.email}
                onChange={updateField}
                id="email"
                required
                aria-describedby="emailHelp"
                placeholder="Email"
              />
            </div>
            <div className="form-group mb-3">
              <label className="textcolor1">Password</label>
              <div className="passsection">
                <input
                  type={showPassword ? "text" : "password"}
                  name="password"
                  id="password"
                  required
                  className="form-control"
                  placeholder="Password"
                  value={form.password}
                  onChange={updateField}
                />
                <span
                  className={ showPassword ? "text passvisible" : "password passvisible" }
                  onClick={() => setShowPassword(!showPassword)}
                ></span>
              </div>
            </div>
            <div className="form-group">
              <p className="text-right">
                <Link to="/forgotpassword">Forgot password?</Link>
              </p>
            </div>
            {/* <div className="col-md-12 p-0 pt-5 mt-3">
              <div className="form-group">
                <label className="form-check-label checkboxWrap">
                  <input
                    className="form-check-input"
                    name="termsStatus"
                    value={form.termsStatus}
                    type="checkbox"
                    onChange={updateField}
                  />
                  <span>
                    I agree to the{" "}
                    <a className="textcolor1 terms">Terms of Use</a> and {" "}
                    <a className="textcolor1 terms">
                      Privacy Policy of Bleckmann
                    </a>
                  </span>
                  <span className="checkmark"></span>
                </label>
              </div>
            </div> */}
            <div className="col-md-12 text-center mt-5 p-0 pt-5">
              <button
                type="submit"
                className="btn btn-block mybtn bg-color tx-tfm bottom-buttons"
              >
                {" "}
                Login{" "}
              </button>
            </div>
          </div>
        </form>
      </div>
    );
}

export default EmailForm
