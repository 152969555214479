import React from 'react'
import CloseIcon from '../../Static/images/close.png';
import './FullScreenImage.css';
import ClearIcon from "@material-ui/icons/Clear";


function FullScreenImage({imageSrc, DisableFullScreenImage}) {
    const imageData = imageSrc;
    function CloseImage() {
        DisableFullScreenImage();
    }
    return (
        <div className="w-100">
            <ClearIcon className="float-right close-icon-style textcolor1" src={CloseIcon} onClick={CloseImage} />
            <img src={imageData} alt="fullScreenImage" className="w-100 image-full-screen-style"></img>
        </div>
    )
}

export default FullScreenImage

