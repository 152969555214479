/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Redirect } from "react-router-dom";
import BaseRouter from "./Config/routes";
import "./App.css";
import { AppContext } from "./Config/contextLib";
import Footer from "./Pages/Footer/footer";
import { APICALL } from "./Services/ApiServices";
import { DataTranslations } from "./Services/ApiEndPoints";
import TermsConditionModal from "./Pages/Settings/TermsConditionModal";
import LoadingIcon from "./Utilities/LoadingIcon";
import { UserProfile } from "./Services/ApiEndPoints";
import ScrollToTop from "./Utilities/ScrollToTop";
import NoMatchPage from "./Common/NoMatchPage";
import { isTablet, deviceDetect, CustomView } from "react-device-detect";
import { OCAlertsProvider } from "@opuscapita/react-alerts";
import { t } from './Translation/Translation';

function App() {
  const [isAuthenticated, userHasAuthenticated] = useState(
    JSON.parse(localStorage.getItem("isAuthenticated"))
  );
  const [termsCondition, settermsCondition] = useState(
    JSON.parse(localStorage.getItem("termsCondition") === "undefined" ? "false" : localStorage.getItem("termsCondition"))
  );
  const [toggleClass, settoggleClass] = useState(false);
  const [translations, setTranslations] = useState(null);
  const [render, setRender] = useState(false);
  const [language, setLanguage] = useState();
  const get_id = localStorage.getItem("id");
  const [userStatus, setUserStatus] = useState(true);
  const [desktopMode, setdeskopMode] = useState(false);
  const [previewAccess, setPreviewAccess] = useState(false);
  const [previewEnabled, setpreviewEnabled] = useState(false);
  const firstlogin = localStorage.getItem('firstlogin');

  if (document.getElementById("loading-icon") !== null) {
    document.getElementById("loading-icon").style.display = "none";
    // .setAttribute("style", "display:none;");
  }

  if (isTablet) {
    localStorage.setItem("DeviceMode", "Mobile");
    // let deviceVersion = deviceDetect;
  }

  useEffect(() => {
  // console.log("browser : ", navigator.userAgent);
  const vw = Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0)
  const vh = Math.max(document.documentElement.clientHeight || 0, window.innerHeight || 0)

  // console.log('vh : ', vh, ' vw : ', vw);
  if (/Android|webOS|iPhone|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
    // true for mobile device
    localStorage.setItem("DeviceMode", 'Mobile');
  } else {
    // false for not mobile device
    localStorage.setItem("DeviceMode", 'Desktop');
    setdeskopMode(true);
  }
  _getPreviewAccessRights();
}, [])

  const [count, setcount] = useState(0);
  const [PreservedUrl, setPreservedUrl] = useState("/");
  if (!isAuthenticated && count === 0) {
    if (window.location.pathname === "/login") {
      localStorage.setItem('preservedURL', '/');
      setPreservedUrl("/");
    }
   else if (window.location.pathname === "/ideabox") {
      localStorage.setItem('preservedURL', '/ideabox');
      setPreservedUrl("/ideabox");
    } else {
      let url = window.location.pathname;
      if (!url.includes("/sso-middlewere") && !url.includes("/sso-logout")){
        localStorage.setItem('preservedURL', url);
        setPreservedUrl(url);
      }

    }
    setcount(count + 1);
  }

  useEffect(() => {
    if (!isAuthenticated) {
      document.body.classList.add("login");
    } else {
      document.body.classList.remove("login");
    }
    //Getting translation and storing in the localstorage..

    APICALL.service(DataTranslations, "GET", "", 0, 0).then((result) => {
      if (result !== null) {
        setTranslations(result);
        localStorage.setItem("translations", JSON.stringify(result));
      }
    });

    APICALL.service(DataTranslations, "GET", "", 0, 0).then((result) => {
       if (result !== null) {
         setTranslations(result);
         localStorage.setItem("translations", JSON.stringify(result));
       }
     });
  }, []);

  useEffect(() => {
    settermsCondition(true);

    if (isAuthenticated) {
      APICALL.service(UserProfile + get_id, "GET", 0, 0).then((res) => {
        try {
          if (res.status !== 400 || res.state !== 500) {
            localStorage.setItem("termsCondition", res.termsAndConditions);
            settermsCondition(res.termsAndConditions);
          } else {
            localStorage.setItem("termsCondition", true);
            settermsCondition(true);
            if (res.user_status === 'suspended') {
              setUserStatus(false);
              localStorage.setItem("userStatus", false);
              localStorage.setItem("isAuthenticated", false);
              userHasAuthenticated(false);
              localStorage.clear();
            }
          }
        } catch (e) {
          console.error(e);
        }
      });
    }
  }, [isAuthenticated]);

  //Setting the value is flaoting icon opened or closed for adding the blur on content page
  const settingtoggleClass = (val) => {
    settoggleClass(val);
    // (val) ? document.body.style.overflow = 'hidden' : document.body.style.overflow = 'auto';
    (val) ? document.body.setAttribute("style", "position:fixed; overflow:hidden;") : document.body.removeAttribute("style");
  };
  document.addEventListener("contextmenu", (event) => event.preventDefault());

  const _getPreviewAccessRights = () => {
    let currentPath = window.location.href;
    let userRoles = ["admin", "cadmin", "madmin"];
    if (currentPath.includes("preview") > 0) {
      setpreviewEnabled(true);
      let currentRoles = JSON.parse(localStorage.getItem("Roles"));
      currentRoles !== null &&
        currentRoles.map((Roles, i) => {
          const data = userRoles.filter((cRoles) => cRoles === Roles);
          if (data.length > 0) {
            return setPreviewAccess(true);
          } else {
            setPreviewAccess(false);
          }
        });
    } else {
      setpreviewEnabled(false);
    }


	  

  };
  if(window.self !== window.top){
    return (
      <div className = "unauthorisedAccess">
        <h1>{t("ACCESS DENIED")}</h1>
        <hr></hr>
        <h3>{t("You are not authorised to access this url within the iframe")}</h3>
      </div>
    );
  }
  else{
  return (
    // storing the user login details in app context so we can use these details anywhere in the application
    <AppContext.Provider value={{ isAuthenticated, userHasAuthenticated, render, setRender, setLanguage}}>
      <Router basename={process.env.REACT_APP_ROUTER_BASE || ""}>
        {/* while calling content must send the menu status */}
        <>
          {/* <div className={(localStorage.getItem("DeviceMode")==="Mobile")?toggleClass ? "animated content" : "content":"content"}> */}
          <div
            className={`${toggleClass ? "animated content" : "content"} ${isTablet ? "Tabletcontainer" : ""
              }`}
          >
            <OCAlertsProvider
              containerStyle={{
                top: "-5px",
                maxWidth: "500px",
                bottom: "0px",
                height: "0px",
                position: "fixed",
              }}
            />
            {previewEnabled === true && (
              <div className="preview-text">
                <h1>{t("Preview")}</h1>
              </div>
            )}

            <ScrollToTop />
            {/* Content of the url loading here if user is not login then it will redirect to user login page */}
            {previewEnabled ? (
              previewAccess ? (
                <BaseRouter></BaseRouter>
              ) : (
                <NoMatchPage />
              )
            ) : (
              <BaseRouter></BaseRouter>
            )}
            {/* {!isAuthenticated &&
              !firstlogin &&
              (
                <Redirect
                  to={{
                    pathname: "/login",
                    state: {
                      PreservedUrl: PreservedUrl,
                      // getPreviewAccessRights: getPreviewAccessRights,
                    },
                  }}
                />
              )} */}
              {!isAuthenticated &&
                    !firstlogin &&
                    PreservedUrl !== "/ideabox" && (
                      <Redirect
                        to={{
                          pathname: "/login",
                          state: {
                            PreservedUrl: PreservedUrl,
                            // getPreviewAccessRights: getPreviewAccessRights,
                          },
                        }}
                      />
               )}


            {!userStatus && <Redirect to="/login" />}
          </div>
          {/* footer bar is adding only if user is authenticated */}
          {isAuthenticated && (
            <Footer settingtoggleClass={settingtoggleClass} language={language} />
          )}
          {termsCondition === false && get_id !== null && (
            <TermsConditionModal
              isAuthenticated={isAuthenticated}
              get_id={get_id}
            />
          )}
          <LoadingIcon />
        </>
      </Router>
    </AppContext.Provider>
  );
  }
}

export default App;
