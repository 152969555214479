import React, { useState, useEffect } from "react";
import { LunchTipsDetailUrl } from "../../Services/ApiEndPoints";
import { APICALL } from "../../Services/ApiServices";
import { t } from "../../Translation/Translation";
import ItemDetails from "../Common/PageItemDetails";
import { useHistory } from "react-router-dom";
import LunchTipsDetail from "./LunchTipsDetails";
import AddLunchTips from "./AddLunchTip";
import { useParams } from "react-router";
import BackButtonComponent from "../../Utilities/BackButtonComponent";
import previewFn from "../../Common/Preview";
import BackButton from "../../Utilities/BackButton";
import InfiniteScroll from "react-infinite-scroll-component";
import SearchBoxWithSubmit from "../Common/SearchBoxWithSubmit";
import FilterPopUp from "../../Utilities/Popup/FilterPopup";

function LunchTips(props) {
  const [LunchTipsList, setLunchTipsList] = useState();
  const [fixeddataList, setFixedDataList] = useState([]);
  const history = useHistory();
  const [searchVal, setsearchVal] = useState("");
  const [filterPopup, setFilterPopup] = useState(false);
  
  let params = useParams();

  const [DeviceMode, setDeviceMode] = useState("false");
  const [defaultitem, setdefaultitem] = useState();
  const [listviewMode, setlistviewMode] = useState(false);
  const [addMode, setAddMode] = useState(false);
  const [backbuttonUrl, setbackbuttonUrl] = useState();
  const [urlState, seturlState] = useState(false);
  const [hasMore, sethasMore] = useState(true);
  const [offset, setoffset] = useState(0);
  const [searchState, setSearchState] = useState(false);
  const [locationIds, setLocationIds] = useState([]);
  const [countryIds, setCountryIds] = useState([]);
  const [clearFilterValues,setClearFilterValues] = useState(false);
  const [highlightitem, setHighlightitem] = useState();

  let location = "";
  if (props.location.state){
    location = props.location.state.location
    localStorage.setItem('LunchtipLocation', props.location.state.location)
  }

  useEffect(() => {
    var get_id = localStorage.getItem("id");
    if (localStorage.getItem("DeviceMode") === "Desktop") {
      setDeviceMode(true);
    }

    // var location = props.match.params.title;
    if (localStorage.getItem('LunchtipLocation') && !location){
      localStorage.setItem('LunchtipLocation', '')
    }
    
    if(clearFilterValues){
      location = ''
    }
    let param = previewFn(params);
    // let countryIds = 
    //calling api according to data passed in url, if not null then filtering with location
    // let filterinputdata = {
    //   user_id: get_id,
    //   search: location,
    //   type: "view",
    //   param: param,
    //   offset: offset,
    //   // search: searchVal,
    //   locationIds : locationIds,
    //   countryIds:countryIds
    // };
    // let listinputdata = {
    //   user_id: get_id,
    //   type: "view",
    //   param: param,
    //   offset: offset,
    //   search: searchVal,
    //   locationIds : locationIds,
    //   countryIds:countryIds
    // };
    let data = {
      user_id: get_id,
      type: "view",
      param: param,
      offset: offset,
      search: location ? searchVal || location : searchVal,
      locationIds : locationIds,
      countryIds:countryIds
    };
    // if (!location) {
      var url = LunchTipsDetailUrl;
      var type = "POST";
    //   var data = listinputdata;
    // } else {
      // url = LunchTipsDetailUrl;
      // type = "POST";
      // data = filterinputdata;
    // }
    let dataList;
    APICALL.service(url, type, data)
      .then((result) => {
        if (result.status !== 404 && result.status !== 401) {
          if (!hasMore) sethasMore(true);
          dataList = result;

          dataList = alphabetical_sort_object_of_objects(dataList, "title");
          //default lunchtip for detail page
          const lastItem = dataList[0];

          setLunchTipsList(dataList);
          setFixedDataList(dataList);
          if (params.id !== undefined) {
            setHighlightitem({"id": params.id, "type": "lunchtips"});
            setdefaultitem(params.id);
            if (localStorage.getItem("DeviceMode") === "Desktop") {
              setlistviewMode(false);
            } else {
              setlistviewMode(true);
            }
          } else {
            setHighlightitem({"id": lastItem.id, "type": "lunchtips"});
            setdefaultitem(lastItem.id);
          }
        } else {
          sethasMore(false);
          if (localStorage.getItem("DeviceMode") === "Desktop") {
            setdefaultitem();
          }
          setLunchTipsList("");
        }
      })
      .catch((error) => {
        console.error(error);
        // setLunchTipsList(undefined);
      });
      setClearFilterValues(false)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchState]);

  //function to sort based in alphabetic order
  function alphabetical_sort_object_of_objects(data, attr) {
    var arr = [];
    for (var prop in data) {
      if (data.hasOwnProperty(prop)) {
        var obj = {};
        obj[prop] = data[prop];
        obj.tempSortName = data[prop][attr].toLowerCase();
        arr.push(obj);
      }
    }
    arr.sort(function (a, b) {
      var at = a.tempSortName,
        bt = b.tempSortName;
      return at > bt ? 1 : at < bt ? -1 : 0;
    });
    var result = [];
    for (var i = 0, l = arr.length; i < l; i++) {
      obj = arr[i];
      delete obj.tempSortName;
      for (prop in obj) {
        if (obj.hasOwnProperty(prop)) {
          var id = prop;
        }
      }
      var item = obj[id];
      result.push(item);
    }
    return result;
  }

  function fetchMoreData() {
    let count = offset + 1;
    var get_id = localStorage.getItem("id");
    // var location = props.match.params.title;
    let param = previewFn(params);
    //calling api according to data passed in url, if not null then filtering with location
    // let filterinputdata = {
    //   user_id: get_id,
    //   // location: location,
    //   type: "view",
    //   param: param,
    //   offset: count,
    //   search: location ? searchVal || location : location,
    //   locationIds : locationIds,
    //   countryIds:countryIds
    // };
    // let listinputdata = {
    //   user_id: get_id,
    //   type: "view",
    //   param: param,
    //   offset: count,
    //   search: location ? searchVal || location : searchVal,
    //   locationIds : locationIds,
    //   countryIds:countryIds
    // };
    let data = {
      user_id: get_id,
      type: "view",
      param: param,
      offset: count,
      search: location ? searchVal || location : searchVal,
      locationIds : locationIds,
      countryIds:countryIds
    };
    // if (!location) {
      var url = LunchTipsDetailUrl;
      var type = "POST";
    //   var data = listinputdata;
    // } else {
    //   url = LunchTipsDetailUrl;
    //   type = "POST";
    //   data = filterinputdata;
    // }
    let dataList;
    setoffset(count);
    APICALL.service(url, type, data)
      .then((result) => {
        if (result.status === 404) {
          sethasMore(false);
        }
        if (result.status !== 404) {
          dataList = result;

          dataList = alphabetical_sort_object_of_objects(dataList, "title");

          setLunchTipsList(fixeddataList.concat(dataList));
          setFixedDataList(fixeddataList.concat(dataList));
        }
      })
      .catch((error) => {
        console.error(error);
        // setLunchTipsList(undefined);
      });
      // setClearFilterValues(true)
  }

  // set back button urls
  useEffect(() => {
    if (urlState === false) {
      if (params.id === undefined) {
        setbackbuttonUrl("/lunchtips");
      } else {
        setbackbuttonUrl("/lunchtips/" + params.id);
      }
      seturlState(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [backbuttonUrl]);

  // back button funtionality
  function historyBack() {
    let response = BackButton();
    history.push(response);
  }

  //redirect to add lunchtip page
  const RedirectAddTip = () => {
    if (localStorage.getItem("DeviceMode") === "Desktop") {
      setAddMode(true);
    } else {
      history.push("/addlunchtip/");
    }
  };

  //TO update detail page when user click on any list item
  const updatesId = (e) => {
    setHighlightitem({"id": e, "type": "lunchtip"});
    setdefaultitem(e);
    setAddMode(false);
    setbackbuttonUrl("/lunchtips/" + e);
    if (localStorage.getItem("DeviceMode") === "Desktop") {
      setlistviewMode(false);
    } else {
      setlistviewMode(true);
    }
  };

  //backbotton form details page to listview
  const backButton = (e) => {
    setlistviewMode(e);
  };

  //search elements entered
  const SearchElements = (e) => {
    setsearchVal(e.target.value);
    // filterData(e.target.value);
  };

  //filter records by search text
  // let filteredData;
  // const filterData = (value) => {
  //   const lowercasedValue = value.toLowerCase().trim();
  //   if (lowercasedValue === "");
  //   else {
  //     filteredData = fixeddataList.filter((item) => {
  //       return Object.keys(item).some((key) =>
  //         item[key] != null
  //           ? excludeColumns.includes(key)
  //             ? false
  //             : item[key].toString().toLowerCase().includes(lowercasedValue)
  //           : null
  //       );
  //     });
  //   }
  //   if (filteredData !== undefined) {
  //     setLunchTipsList(filteredData);
  //   } else {
  //     setLunchTipsList(fixeddataList);
  //   }
  // };

  const searchSubmit = (e) => {
    e.preventDefault();
    setoffset(0);
    setLocationIds([])
    setCountryIds([]);
    setSearchState(!searchState);
  };

  //list view of lunchtips
  const listMode = () => {
    return (
      <div className="container customContainer">
        <div className="col-md-12 topsection-wrap  mt-0 px-0 pt-4">
          {/* <div className="col-2"><p className="browserback" onClick={history.goBack}>back</p></div> */}
          <div className="row">
            <div className="col-2">
              <p
                className={
                  DeviceMode === true ? "hide-class" : "browserback top_aligner"
                }
                onClick={historyBack}
              >
                back
              </p>
            </div>
            {backbuttonUrl && (
              <BackButtonComponent backButtonLink={backbuttonUrl} />
            )}
            <h1 className="pagetitle textcolor1 col-8 text-center">
              {t("Lunch tips")}
            </h1>
            <div
              className="col-2 pr-0 textcolor1 mt-1 customCursor"
              onClick={() => RedirectAddTip()}
            >
              Add
            </div>
          </div>
        </div>
        <div className="search-lunchtip">
          {/* <SearchBox onSearchValue={SearchElements} searchVal={searchVal} /> */}
          <SearchBoxWithSubmit
            onSearchValue={SearchElements}
            searchVal={searchVal}
            searchSubmit={searchSubmit}
          />
        </div>
        <div className="col-md-12 col-12 p-0">
          <div className="input-group col-12 my-4 p-0 filter-parent">
          <button
            className="bg-color "
            // onClick={setFilterPopup(true)}
            onClick={() => clearFilter(true)}
          >
            {t("Clear Filter")}
          </button>
          <button
            className="bg-color ml-3"
            // onClick={setFilterPopup(true)}
            onClick={() => setFilterPopup(true)}
          >
            {t("Filter")}
          </button>
          </div>
        </div>
        <div className="post">
          {LunchTipsList === undefined && (
            <div className="col-12 text-center">
              <h5 className="text-center textcolor1">
                {" "}
                {t("Loading Lunch tips")}.
              </h5>{" "}
            </div>
          )}
          {LunchTipsList !== undefined && (
            <InfiniteScroll
              dataLength={LunchTipsList.length}
              next={fetchMoreData}
              hasMore={hasMore}
              loader={
                <h5 className="textcolor1 text-center">{t("Loading")}.</h5>
              }
              scrollableTarget="scrollableDiv"
            >
              {LunchTipsList !== undefined &&
                Object.keys(LunchTipsList).map((value, i) => {
                  return (
                    <div key={i}>
                      <ItemDetails
                        data={LunchTipsList[value]}
                        type="Tips"
                        updatesId={updatesId}
                        activeItem={highlightitem}
                      />
                    </div>
                  );
                })}
            </InfiniteScroll>
          )}
          {LunchTipsList === undefined ||
            (LunchTipsList.length === 0 && (
              <h5 className="col-12 text-center textcolor1">
                {" "}
                {t("No Lunch tips found ")}{" "}
              </h5>
            ))}
        </div>
      </div>
    );
  };

  const handleFilter = (data) =>{

    if(data['location'] && data['location'] != []){
      setLocationIds(data['location'])
    }else{
      setLocationIds([])
    }

    if(data['countryLunchTips'] && data['countryLunchTips'] != []){
      setCountryIds(data['countryLunchTips'])
    }else{
      setCountryIds([])
    }
    setsearchVal('')
    setoffset(0)
    setFilterPopup(false)
    setSearchState(!searchState);
  }

  const clearFilter = () =>{
    setClearFilterValues(true)
    setoffset(0);
    setCountryIds([])
    setLocationIds([])
    location ='';
    setsearchVal('')
    setSearchState(!searchState);
  }

  //adding list and detail pages in separate div if device is desktop
  return (
    <div>
      {DeviceMode === true ? (
        <div className="Desktop-mode-list-and-detail-view layoutSpace">
          <div className="Desktop-mode-list-view float-lg-left col-lg-5 pr-0">
            {/* <div className="customscroll"> {listMode()} </div> */}
            <div className="customscroll" id="scrollableDiv">
              {" "}
              {listMode()}{" "}
            </div>
          </div>
          <div className="Desktop-mode-Detail-view float-lg-left col-lg-7 pr-4 pl-1">
            <div className="customscroll">
              {" "}
              {addMode === false ? (
                defaultitem && <LunchTipsDetail updatesEntityId={defaultitem} />
              ) : (
                <AddLunchTips />
              )}{" "}
            </div>
          </div>
        </div>
      ) : listviewMode === false && params.id === undefined ? (
        listMode()
      ) : (
        <LunchTipsDetail
          updatesEntityId={defaultitem}
          backButton={backButton}
        />
      )}
      <FilterPopUp
        stateLocation = {localStorage.getItem('LunchtipLocation')}
        show={filterPopup}
        type={"lunchtips"}
        onHide={() => setFilterPopup(false)}
        title={"Filter Lunchtips"}
        location={true}
        countryLunchtips={true}
        locationIds={locationIds}
        countryLunchTipsIds={countryIds}
        handleFilter={handleFilter}
        clearFilterValues={clearFilterValues}
      />
    </div>
  );
}
export default LunchTips;
