import React, { useState, useEffect } from 'react';
import { LunchTipsUrl, FoodTypeUrl } from "../../Services/ApiEndPoints";
import { APICALL } from "../../Services/ApiServices";
import { useHistory } from "react-router-dom";
import "./LunchTips.css"
import { t } from '../../Translation/Translation';
// import { Multiselect } from 'multiselect-react-dropdown';
import { APICALL as AXIOS } from "../../Services/AxiosServices";
import "../../Static/css/common.css";
import Rating from '@material-ui/lab/Rating';
import usePlacesAutocomplete, {
  getGeocode,
  getLatLng,
} from "use-places-autocomplete";
import LocationOnMap from "../../Pages/Locations/LocationOnMap";
import Popup from "../../Utilities/Popup/modelPopup";
import makeAnimated from "react-select/animated";
import MultiSelect from "../../Utilities/MultiSelect.js";
import { components } from "react-select";


function AddLunchTips() {
  const [dropDownList, setDropDownList] = useState([]);
  const [weburl, setWeburl] = useState("");
  const [locations, setLocationsList] = useState([]);
  const [message, setMessage] = useState('');
  const [title, setTitle] = useState('');
  const [address, setAddress] = useState('');
  const history = useHistory();
  const [selectedItem, setselectedItem] = useState([]);
  const multiselectRef = React.createRef();
  const [error, setError] = useState(false);
  const [values, setValues] = useState(0);
  const [hover, setHover] = useState(-1);
  const [popupstate, setPopupState] = useState(false);
  const [DeviceMode, setDeviceMode] = useState('false');

  const formData = new FormData();

  const {
    ready,
    value,
    suggestions: { status, data },
    setValue,
    clearSuggestions,
  } = usePlacesAutocomplete({
    requestOptions: {
      /* Define search scope here */
    },
    debounce: 300,
  });

  useEffect(() => {
    var get_id = localStorage.getItem('id')
    if(localStorage.getItem('DeviceMode') === 'Desktop'){
      setDeviceMode(true);
     }
    //api call to get all food types to display in dropdown
    let data = {
      "user_id": get_id
    }
    let dataList = [];
    APICALL.service(FoodTypeUrl, "POST", data)
      .then(result => {
        Object.keys(result).map((value, i) => {
          dataList.push({ 'value': i, 'label': result[value] })
        })
        //function to sort in alphabetic order
        function alphabetical_sort_object_of_objects(data, attr) {
          var arr = [];
          for (var prop in data) {
            if (data.hasOwnProperty(prop)) {
              var obj = {};
              obj[prop] = data[prop];
              obj.tempSortName = data[prop][attr].toLowerCase();
              arr.push(obj);
            }
          }

          arr.sort(function (a, b) {
            var at = a.tempSortName,
              bt = b.tempSortName;
            return at > bt ? 1 : (at < bt ? -1 : 0);
          });

          var result = [];
          for (var i = 0, l = arr.length; i < l; i++) {
            obj = arr[i];
            delete obj.tempSortName;
            for (prop in obj) {
              if (obj.hasOwnProperty(prop)) {
                var id = prop;
              }
            }
            var item = obj[id];
            result.push(item);
          }
          return result;
        }
        dataList = alphabetical_sort_object_of_objects(dataList, 'label')
        setDropDownList(dataList)
      });

  }, []);
  
  //close popup
  const closePopup = () => {
    setMessage("")
    if (error === false) {
      if(localStorage.getItem('DeviceMode') === 'Desktop'){
        resetForm();
      }else{ 
        window.location.reload(false);
      }
    }
  }

  const Option = (props) => {
    return (
      <div>
        <components.Option {...props}>
          <label>{props.label}</label>
        </components.Option>
      </div>
    );
  };

  const animatedComponents = makeAnimated();

  const MultiValue = (props) => (
    <components.MultiValue {...props}>
      <span>{props.data.label}</span>
    </components.MultiValue>
  );


  //function to reset form
  const resetForm = () => {
    setselectedItem([])
    setWeburl("");
    setValue("");
    setValues("");
    setLocationsList({ lat: null, lng: null })
  }

  const handleInput = (e) => {
    // Update the keyword of the input element
    if (e.target.value === '') {
      setLocationsList({ lat: null, lng: null })
    }
    setValue(e.target.value);
  };

  const handleSelect = ({ description }) => () => {
    // When user selects a place, we can replace the keyword without request data from API
    // by setting the second parameter to "false"
    setAddress(description);
    setValue(description, false);
    clearSuggestions();


    // Get latitude and longitude via utility functions
    getGeocode({ address: description })
      .then((results) => getLatLng(results[0]))
      .then(({ lat, lng }) => {
        setLocationsList({ lat, lng })
      })
      .catch((error) => {

      });
  };

  const backfunction = () => {
    if(localStorage.getItem('DeviceMode') === 'Desktop'){
      history.replace("/lunchtips");
    }else{ 
      history.goBack()
    }
  }

  //getting suggestions based on entered text
  const renderSuggestions = () =>
    data.map((suggestion) => {
      const {
        id,
        structured_formatting: { main_text, secondary_text },
      } = suggestion;
      return (
        <li className="border-bottom" key={id} onClick={handleSelect(suggestion)}>
          <strong>{main_text}</strong> <small>{secondary_text}</small>
        </li>
      );
    });

  //Function to add all data inside formdata 
  const OnSubmitAddTip = () => {
    var data = "true"

    if (selectedItem !== []) {
      Object.keys(selectedItem).map((value, i) => {
        formData.append('addFoodType[' + value + ']', selectedItem[value].label)
      })
    }

    formData.append('rating', values)
    if (address !== "") {
      formData.append('title', address)
    } else {
      data = "false"
    }
    formData.append('url', weburl)
    formData.append('lat', locations['lat'])
    formData.append('lng', locations['lng'])

    return data;
  }

  //Funtion for api call to add lunch tip
  async function apicall(event) {
    event.preventDefault()

    var response = OnSubmitAddTip();
    if (response === "true") {
      AXIOS.service(LunchTipsUrl, "POST", formData)
        .then(result => {
          setMessage(result.message);
          setTitle(t("Success"))
          setPopupState(true)
          setError(false)

        })
        .catch(error => {
          setMessage(t("Please select available food"));
          setTitle(t("Alert"))
          setError(true)
          setPopupState(true)
        })
    } else {
      setMessage(t("Select location of lunchtip"))
      setTitle(t("Alert"))
      setError(true)
      setPopupState(true)
    }
  }

  return (
    <div className="container customContainer">
      <div className="col-md-12 topsection-wrap  mt-0 px-0 pt-4">
        <p className={DeviceMode === true ? "hide-class" : "browserback"} onClick={backfunction}>back</p>
        <h1 className="pagetitle textcolor1 text-center">{t("Add Lunchtip")}</h1>
      </div>
      <br></br>
      <div className="post">
        <form className="fieldsWrapps" id="form" onSubmit={(e) => apicall(e)}>
          <div className="form-group searchsection addtipssearch" >
            <input
              className="form-control"
              value={value}
              onChange={handleInput}
              disabled={!ready}
              placeholder={t("Search your location here")}
            />
            {status === "OK" && <ul className="border" >{renderSuggestions()}</ul>}
          </div>
          <div className="col-12 my-3 px-0">{locations['lat'] != null ?
            <LocationOnMap lats={locations['lat']} long={locations['lng']} mapaddress={address} zoomLevel={17} type={"addlocation"} /> : null}
          </div>

          {popupstate === true && <Popup
            show={message !== "" ? true : false}
            onHide={closePopup}
            size={"lg"}
            title={(title)}
            body={message}
          />}

          <div className="form-group">
            <label className="textcolor1 addlunchlable">{t("Visit website")}</label>
            <input
              type="text"
              name="weburl"
              className="form-control"
              value={weburl}
              id="weburl"
              placeholder={t("Visit website")}
              onChange={(e) => setWeburl(e.target.value)} 
            />
          </div>

          <div className="form-group">
            <lable className="textcolor1 addlunchlable">{t("Food available")}</lable>
            <MultiSelect
              options={dropDownList}
              className = "lunchtip_foodtype"
              isMulti
              closeMenuOnSelect={false}
              blurInputOnSelect={false}              hideSelectedOptions={true}
              components={{ Option, MultiValue, animatedComponents }}
              onChange={(e) => setselectedItem(e)}
              allowSelectAll={true}
              value={selectedItem}
            />
            {/* <Multiselect
              options={dropDownList}
              onClick={(e) => setselectedItem(e)}
              ref = {multiselectRef}
              displayValue="foodtype"
              placeholder={t("Select")}
              avoidHighlightFirstOption={true}
            /> */}
          </div>


          <div className="col-12 mt-2 px-0 py-2">
            <h6 className="my-2 textcolor1">{t("Rate this Lunchtip")}</h6>
            <div className="rate-center-align">
              <Rating className="itemrate rateSection"
                name="hover-feedback"
                value={values}
                precision={0.5}
                onChange={(event, newValue) => {
                  setValues(newValue);
                }}
                size="large"
                onChangeActive={(event, newHover) => {
                  setHover(newHover);
                }}
              />
            </div>
          </div>

          <div className="col-md-12 col-12 pl-0 px-0 text-center votePopup mt-3 mb-4">
            <button type="submit" className="btn  m-0 rateSave  bg-color font-weight-bold">{t("Submit for approval")}</button>
          </div>
        </form>

      </div>
    </div>
  )

}
export default AddLunchTips;

