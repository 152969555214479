import React, { useState, useEffect } from "react";
import "./whoiswho.css";
import { Form, Modal } from "react-bootstrap";
import { AllUsers } from "../../Services/ApiEndPoints";
import { APICALL } from "../../Services/ApiServices";
import WhoiswhoComponent from "../WhoIsWho/WhoiswhoComponent";
import { useHistory } from "react-router-dom";
import { t } from "../../Translation/Translation";
import WhoisWhoDetails from "./WhoisWhoDetails";
import { useParams } from "react-router";
import BackButton from "../../Utilities/BackButton";
import BackButtonComponent from "../../Utilities/BackButtonComponent";

export default function Whoiswho() {
  /**
   *  show,handleclose,handleshow are used for Modal or popup
   *
   */
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const history = useHistory();
  const Atoz = [
    "A",
    "B",
    "C",
    "D",
    "E",
    "F",
    "G",
    "H",
    "I",
    "J",
    "K",
    "L",
    "M",
    "N",
    "O",
    "P",
    "Q",
    "R",
    "S",
    "T",
    "U",
    "V",
    "W",
    "X",
    "Y",
    "Z",
  ];
  const DesktopMode = localStorage.getItem("DeviceMode").includes("Desktop");
  const [backbuttonUrl, setbackbuttonUrl] = useState();
  const [urlState, seturlState] = useState(false);
  useEffect(() => {
    if (urlState === false) {
      setbackbuttonUrl("/whoiswho");
      seturlState(true);
    }
  }, []);

  function historyBack() {
    let response = BackButton();
    history.push(response);
  }

  /**
   * searchedData and searches are used for creating search array
   */
  const [userResult, setUserResult] = useState();
  let users = JSON.parse(localStorage.getItem("users")) || "";
  const [userData, setuserData] = useState(users);
  const [searchedData, setSearchedData] = useState("");
  const [offsetUserData, setoffsetUserData] = useState([]);
  const [loadingValue, setloadingValue] = useState(true);
  const [offsetValue, setoffsetValue] = useState(0);
  const [hasMorevalue, sethasMorevalue] = useState(true);
  const [userId, setUserId] = useState();
  const [listviewMode, setlistviewMode] = useState(false);
  let params = useParams();

  /**
   *
   * calling CreateSearchArray function onsubmit event for search/filter form
   * search result  for on submit
   * eg: "suresh kumar bangalore"
   * devidedinto: "suresh kumar bangalore" "suresh kumar" "kumar bangalore" "suresh" "kumar" "bangalore"
   *final result from filter is stored in filteredData variable line number 68
   *exclude column is used for exclude the api result fields from filter
   */

  let count = 0;
  const CreateSearchArray = (e) => {
    let dummy1 = [];
    let dummy = [];
    let searches = [];
    let Search;
    e.preventDefault();
    // split the seached string
    const arr = searchedData.split(" ");
    if (searchedData !== "") {
      arr.forEach((e) => {
        searches.push(e);
      });
      Array.from(new Set(searches));
      /**
       * Set is used to remove duplicates
       */
      const uniqueFilterValues = [...new Set(searches)];
      let arry;
      let excludeColumns;

      excludeColumns = ["id", "profile_photo"];
      let filteredData;

      const filterData = (value) => {
        try {
          count++;
          const lowercasedValue = value.toLowerCase().trim();
          if (lowercasedValue === "");
          else {
            try {
              if (userData) {
                filteredData = userData.filter((item) => {
                  return Object.keys(item).some((key) =>
                    item[key] !== null
                      ? excludeColumns.includes(key)
                        ? false
                        : item[key]
                          .toString()
                          .toLowerCase()
                          .includes(lowercasedValue)
                      : null
                  );
                });
              }
              if (filteredData.length === 0) {
                Search = 1;
              }
              for (let i = 0; i < filteredData.length; i++) {
                dummy.push(filteredData[i]);
              }
            } catch (e) {
              console.error(e);
            }
          }
        } catch (e) {
          console.error(e);
        }
        return arry;
      };
      let SearchedFinalResult;
      if (uniqueFilterValues.length > 0)
        for (var key in uniqueFilterValues) {
          filterData(uniqueFilterValues[key]);
        }

      const lookup = dummy.reduce((a, e) => {
        a[e.id] = ++a[e.id] || 0;
        return a;
      }, {});
      if (count > 1) {
        dummy = dummy.filter((e) => lookup[e.id]);
      } else {
        arry = dummy;
      }
      SearchedFinalResult = [...new Set(dummy)];
      if (Search === 1) {
        SearchedFinalResult = dummy1;
        setUserResult(dummy1);
      } else {
        setUserResult(SearchedFinalResult);
        if (
          SearchedFinalResult !== undefined &&
          SearchedFinalResult.length > 0 &&
          DesktopMode === true
        ) {
          setUserId(SearchedFinalResult[0].id);
          sethasMorevalue(false);
        }
      }
      // if Search is empty then load defualt users array
    } else { 
      setUserResult();
      sethasMorevalue(true);
      setoffsetValue(0);
      offsetFunction();
    }
  };

  /**
   *
   * onWhoiswhoFilterSubmit and filter show there are the A-Z filter popup related functions
   */
  const filtershow = (e) => {
    filterDataAZ(e);
    handleClose();
  };
  const excludeColumn = [
    "firstName",
    "id",
    "email",
    "sensitiveInfo",
    "profile_photo",
    "country",
    "location",
    "department",
    "personalEmail",
    "workEmail",
    "personalPhoneNumber",
    "userJobFunction",
    "workPhoneNumber",
    "userHobbies",
  ];
  let filteredDataAZ;
  const filterDataAZ = (value) => {
    if (value !== undefined) {
      const lowercasedValue = value.toLowerCase();
      if (lowercasedValue === "") {
      } else {
        if (userData !== undefined) {
          try {
            filteredDataAZ = userData.filter((item) => {
              return Object.keys(item).some((key) =>
                item[key] !== null
                  ? excludeColumn.includes(key)
                    ? false
                    : item[key]
                      .toString()
                      .toLowerCase()
                      .startsWith(lowercasedValue)
                  : null
              );
            });
            setUserResult(filteredDataAZ);
            //set default value for details page on desktop mode
            if (DesktopMode === true) {
              setUserId(filteredDataAZ[0].id);
            }
            sethasMorevalue(false);
          } catch (e) {
            console.error(e);
          }
        }
      }
    }
  };

  /**
   * 'uid' is for storing userID which we'll fetch from local storage
   * 'sort_by_key' function is used for sorting array data in alphabetical order based on first name
   */

  useEffect(() => {
    let load = users === "" ? 1 : 0;
    APICALL.service(AllUsers, "GET", "", 0, load).then((result) => {
      try {
        if (result.status === 201) {
          let ress = result.data;
          function sort_by_key(array, key) {
            return array.sort(function (a, b) {
              var x = a[key].toLowerCase();
              var y = b[key].toLowerCase();
              return x < y ? -1 : x > y ? 1 : 0;
            });
          }
          ress = sort_by_key(ress, "lastName");
          localStorage.setItem("users", JSON.stringify(ress));

          if (params.id !== undefined) {
            setUserId(params.id);
            if (localStorage.getItem("DeviceMode") === "Desktop") {
              setlistviewMode(false);
            } 
            else {
              setlistviewMode(true);
            }
          } 

          if (JSON.stringify(ress) !== JSON.stringify(users)) {
            setuserData(ress);
          }
        }
      } catch (e) {
        console.error(e);
      }
    });
  }, []);

  function fetchMoreData() {
    setoffsetValue(1 + offsetValue);
  }

  useEffect(() => {
    offsetFunction();
  }, [offsetValue]);

  function offsetFunction() {
    let offsetParameter = {
      offset: offsetValue,
    };
    APICALL.service(AllUsers, "POST", offsetParameter).then((result) => {
      try {
        if (result.status === 201) {
          let offsetRes = result.data;
          function sort_by_key(array, key) {
            return array.sort(function (a, b) {
              var x = a[key].toLowerCase();
              var y = b[key].toLowerCase();
              return x < y ? -1 : x > y ? 1 : 0;
            });
          }
          offsetRes = sort_by_key(offsetRes, "lastName");
          if (offsetRes.length < 1) {
            sethasMorevalue(false);
            setloadingValue(false);
          }
          setoffsetUserData(offsetUserData.concat(offsetRes));

          // check desktop mode and params.id from url parameter if undifined set default details page with top most user id
          if (
            DesktopMode === true &&
            params.id === undefined &&
            offsetValue === 0
          ) {
            setUserId(offsetRes[0].id);
          }
        }
      } catch (e) {
        console.error(e);
      }
    });
  }
  // store searched data in variable
  const SearchedData = (e) => {
    e.preventDefault();
    setSearchedData(e.target.value);
  };
  //backbotton form details page to listview
  const backButton = (e) => {
    setlistviewMode(e);
  };
  const WhoisWhoTitle = () => {
    return (
      <div className="container customContainer">
        <div className="col-md-12 topsection-wrap  mt-0 px-0 pt-4">
          <div className="row">
            <div className="col-2">
              <p
                className={
                  DesktopMode ? "hide-class" : "browserback top_aligner"
                }
                onClick={historyBack}
              >
                back
              </p>
              {backbuttonUrl && (
                <BackButtonComponent backButtonLink={backbuttonUrl} />
              )}
            </div>
            <h1 className="pagetitle col-8 textcolor1 text-center mt-1">
              {t("Who is who")}
            </h1>
            <a
              type="button"
              className="Filter-button azbutton col-2"
              onClick={handleShow}
            >
              <h1 className="textcolor1 ">
                <span>{t("A")}</span>
                <span className="azarrow">-</span>
                <span>{t("Z")}</span>
              </h1>
            </a>
          </div>
        </div>
        <div className="d-flex justify-content-between position-sticky align-items-center my-2">
          <div className="col-2 text-right pr-0">
            <Modal
              dialogClassName="modal-dialog-centered azpopup"
              show={show}
              onHide={handleClose}
              className="whoiswho-button-filter"
            >
              <div className="row">
                <div className="col-sm-12 text-center azpopupinner">
                  {Atoz.map((data, index) => (
                    <div className="sectionchar" key={index}>
                      <a
                        type="button"
                        className="btn btn-primary btn-md"
                        value={data}
                        onClick={() => filtershow(data)}
                      >
                        {data}
                      </a>
                    </div>
                  ))}
                </div>
              </div>
            </Modal>
          </div>
        </div>
        <Form
          onSubmit={CreateSearchArray}
          className="whoiswho-search mt-2 mb-4"
        >
          <Form.Control
            type="text"
            name="search"
            placeholder={t("Search")}
            onChange={(e) => SearchedData(e)}
            autoComplete="off"
          />
        </Form>
      </div>
    );
  };
  // updating user id based on click function
  const WhoiswhoUserId = (id) => {
    setUserId(id);
    // Checking Desktop mode and shows listview
    // onclick function shows details view
    if (DesktopMode === true) {
      setlistviewMode(false);
    } else {
      setlistviewMode(true);
    }
  };
  return (
    <div>
      {DesktopMode === true ? (
        <div className="Desktop-mode-list-and-detail-view layoutSpace">
          <div className="Desktop-mode-list-view float-lg-left col-lg-5 pr-0">
            <div className="customscroll" id="scrollableDiv">
              {" "}
              {WhoisWhoTitle()}
              <WhoiswhoComponent
                issearchResult={userResult}
                userListData={offsetUserData}
                fetchMoreData={fetchMoreData}
                hasMorevalue={hasMorevalue}
                WhoiswhoUserId={WhoiswhoUserId}
                activeItem={userId}
              />
            </div>
          </div>
          <div className="Desktop-mode-Detail-view float-lg-left col-lg-7 pr-4 pl-1">
            <div className="customscroll">
              {userId && <WhoisWhoDetails WhoiswhoUserId={userId} />}{" "}
            </div>
          </div>
        </div>
      ) : // preventing display listview if params.id is there
        listviewMode === false && params.id === undefined ? (
          <div>
            {WhoisWhoTitle()}
            <WhoiswhoComponent
              issearchResult={userResult}
              userListData={offsetUserData}
              fetchMoreData={fetchMoreData}
              hasMorevalue={hasMorevalue}
              WhoiswhoUserId={WhoiswhoUserId}
            />
          </div>
        ) : (
          <WhoisWhoDetails WhoiswhoUserId={userId} backButton={backButton} />
        )}
    </div>
  );
}
