import React from 'react'
import Avatar from '../../../Static/images/Avatar_dummy.jpeg';
import '../../../Static/css/common.css';
import moment from 'moment';
import { useHistory } from "react-router-dom";
import renderHTML from 'react-render-html';
import { Emoji } from "emoji-mart";

function CommentitemDetails(comment) {

   const get_id = localStorage.getItem("id");
   const history = useHistory();
   const AppleDevice = iOS();

   function iOS() {
      return [
        'iPad Simulator',
        'iPhone Simulator',
        'iPod Simulator',
        'iPad',
        'iPhone',
        'iPod'
      ].includes(navigator.platform)
      // iPad on iOS 13 detection
      || (navigator.userAgent.includes("Mac") && "ontouchend" in document)
    }
    //Redirect the profile details page
   const redirectprofile =(id) =>{
      if(id === get_id){
         var url = "/myprofile/";
        history.push({
          pathname: url,
        })
      }else{
         var path = "/whoiswho/" + id;
         history.push({
           pathname: path,
         })
      }

   }

   const _emojiSpan = (emoji) => {
      return (
        <span contentEditable={false} dangerouslySetInnerHTML={{
          __html: Emoji({
            html: true,
            set: (AppleDevice) ? 'apple' : 'google',
            emoji: emoji,
            size: 16,
          })
        }}></span>
      )
    }
    /**
     * 
     * @param {} text : This function accepts text (String) as a parameter and will replace unicode characters
     * to their equivalent emoji's if found in the string.
     * The String.fromCodePoint helps us here to get 4 bytes of unicode support as well compared to older methods. 
     */
    const renderEmoji = (text) => {
      let newText = text;
      newText = newText.replace(/\\u\d+/g, function(char){
        return String.fromCodePoint(char.replace(/\\u/g, ''));
      })
    
      return newText;
    }
   
 function parseEmojis(data){
   if(typeof(data) === "string"){
      data = renderEmoji(data);
      data = data.replace(/:yes:/g,':+1:');
      let regex = /:([^:]+):/g;
      let emojis = data.match(regex);
      if(emojis){
         for (let emoji = 0; emoji < emojis.length ; emoji++){
            let emojiElement = _emojiSpan(emojis[emoji]);
            let emojiHTML = (emojiElement.props.dangerouslySetInnerHTML.__html) ? emojiElement.props.dangerouslySetInnerHTML.__html : "";
            data = data.replace(emojis[emoji], emojiHTML)

         }
   
      }
      return renderHTML(data);
   }
 }
 const data = comment.comment;
   return (
      
      <div className="comment card  mb-2" >
         <div className="card-body comment-body" key={data.id}>
            <div className="row  my-2">
               <div className=" px-0 text-center commentImg">{<img className="user-img rounded-circle" src={data.profile_photo ? data.profile_photo : Avatar} alt="user-img"></img>} </div>
               <div className=" pl-0 commentBody">
                  <span className="commentdetail p-2 mb-0">
                     <span><span className="float-left col-6 pl-0">
                     <strong  className="textcolor1 mb-1 Cname" onClick={() => redirectprofile(data.commeter_id)}>{data.commeted_by}</strong></span>
                     <span className="float-right small col-6 px-0 text-right">{moment(new Date(data.date)).format('DD/MM/YYYY HH:mm')}</span>
                     </span>
                        <p className=" commenttext col-md-12 col-12 text-left "  >{parseEmojis(data.comment)}</p>
                     </span>
                    
               </div>
            </div>
         </div>
      </div>
   )
}
export default CommentitemDetails
