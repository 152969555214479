import React, { useState, useEffect } from "react";
import "./Login.css";
import { useAppContext } from "../../Config/contextLib";
import { LoginUrl } from "../../Services/ApiEndPoints";
import { Notifications,DataTranslations } from "../../Services/ApiEndPoints";
import { APICALL } from "../../Services/ApiServices"
import Alert from 'react-bootstrap/Alert'
// import firebase from "../../Config/firebase";
import firebase from 'firebase/app';
import { t } from '../../Translation/Translation';
import { useHistory, useLocation } from "react-router-dom";
import '../../Static/css/common.css'
import EmailForm from './EmailForm'
import MobileForm from './MobileForm'
import LoginSelection from './LoginSelection'
// import axios from 'axios'
// import ModelLR from './images/modelLR.jpg';
export default function Login(props) {
  const [formType, setFormType] = useState("form");
  const [message, setMessage] = useState('');
  let DesktopMode;
 
  const [form, setState] = useState({
    "email": "",
    "password": "",
    "mobile": "",
    "termsStatus": false
  });

  useEffect(() => {
    // const get_id = localStorage.getItem("id");
         if(!JSON.parse(localStorage.getItem("translations"))){
         //Getting translation and storing in the localstorage.. 
          APICALL.service(DataTranslations, "GET", "", 0, 0).then((result) => {
            localStorage.setItem("translations", JSON.stringify(result));
         });
         }
    },[]);

  const { isAuthenticated, userHasAuthenticated } = useAppContext();
  const history = useHistory();
  const location = useLocation();
  
  if (JSON.parse(isAuthenticated)) {
    history.push("/");
  }
  // check device is mobile or desktop
  if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
    DesktopMode=false;
    localStorage.setItem("DeviceMode", 'Mobile');
  }
  else{
    DesktopMode=true;
    localStorage.setItem("DeviceMode", 'Desktop');
  }

  // Resolve recaptch verifier function
  const setUpRecaptcha = () => {
    // e.preventDefault();
    window.recaptchaVerifier = new firebase.auth.RecaptchaVerifier('recaptcha-container', {
      'size': 'invisible',
      'callback': function (response) {
         // console.log(response)
        // reCAPTCHA solved, allow signInWithPhoneNumber.
        onSignInSubmit();
      }
    });
  };

  const updateField = e => {
    //Updating the details
    if (e.target.name === "termsStatus") (e.target.value = !JSON.parse(e.target.value));
    setState({
      ...form,
      [e.target.name]: e.target.value
    });

    
  };
  

  function updatepersonalnumber(mobNumber) {
    // setpersonalnumber(mobNumber);
    setState({
      ...form,
      "mobile": "+" + mobNumber
    });
  }


  const signIn = (mobile) => {
    setUpRecaptcha();
    // let phoneNumber = "+91" + email;
    let appVerifier = window.recaptchaVerifier;
    //Firebase function to send OTP to given number
    firebase.auth().signInWithPhoneNumber(mobile, appVerifier)

      .then(function (confirmationResult) {
       // console.log(window.confirmationResult);
        // SMS sent. Prompt user to type the code from the message, then sign the
        // user in with confirmationResult.confirm(code).
        window.confirmationResult = confirmationResult;
        history.push({
          pathname: '/otp',
          state: { number: mobile, destinationUrl:props.location.state.PreservedUrl }
        })
      })
      .catch(function (error) {
        console.log(error);
        console.log(window.confirmationResult);
        setMessage('Something wrong, please try again later')
        return;
      });
  }

  //Sign in function to send otp if mobile number is given
  function onSignInSubmit(event) {
    event.preventDefault();
    setMessage("");
    // if (!JSON.parse(form.termsStatus)) {
    //   setMessage("Please accept term & condition.");
    //   return;
    // }

    let data = {
      'actions': "login-otp",
      "phone":  form.mobile //"8970041535"
    }
    APICALL.service(LoginUrl, "POST", data)
      .then(result => {
       // console.log(result)

        if (result.status === 400) {
          setMessage(result.message)
        }else {
          askForPermissioToReceiveNotifications(result.data[0].id)  
            localStorage.setItem("id", result.data[0].id);
            localStorage.setItem("lastLoginDate", result.data[0].lastLoginDate); 
            signIn(form.mobile)
          }
        })
      .catch(error => {
        console.error(error)
        setMessage("Invalid mobile number");
      })
  };

   const askForPermissioToReceiveNotifications = async (e) => {
    try {
      
      const messaging = firebase.messaging();
      await messaging.requestPermission();
      const token = await messaging.getToken();
      //console.log('token do usuário:', token);
      let fcm={
        "user_id": localStorage.getItem("id"),
        "reg_token": token
      }
     // console.log("fcm token",fcm);
      APICALL.service(Notifications, "POST", fcm)
      .then(result => {
        console.log("Push Notifications",result)
      })
    // })
      return token;
    } catch (error) {
      console.error("notfcm",error);
    }
    

    
  }
  //Api call function for email login 
  const handleSubmit = (e) => {
    e.preventDefault();
   // console.log("terms status : ", form.termsStatus);
    setMessage("");
    // if (!JSON.parse(form.termsStatus)) {
    //   setMessage("Please accept term & condition.");
    //   return;
    // }
    let data = {
      'actions': 'login-password',
      "username": form.email,        //"admin",
      "password": form.password,     //"superuser"
    }
    
    APICALL.service(LoginUrl, "POST", data)
      .then(result => {
       if (result.status !== 200) {
          setMessage("Invalid Email or Password");
       } else {
          askForPermissioToReceiveNotifications()
          localStorage.setItem("id", result.data[0].id);
          localStorage.setItem("firstname", result.data[0].firstName);
          localStorage.setItem("Roles", JSON.stringify(result.data[0].roles));
          if (result.data[0].lastLoginDate == null) {
            userHasAuthenticated(false);
            localStorage.setItem("isAuthenticated", false);
            localStorage.setItem('firstlogin',true);
            document.body.classList.remove('login');
            history.push("/termsConditions");
          }else {
            userHasAuthenticated(true);
            localStorage.setItem("isAuthenticated", true);
            document.body.classList.remove('login');
            // props.location.state._getPreviewAccessRights();
            // history.push(localStorage.getItem("preservedURL"));
            (localStorage.getItem("preservedURL").includes("preview") > 0) ?  window.location.assign(localStorage.getItem("preservedURL")) : history.push(localStorage.getItem("preservedURL"));
            // history.push("/");     
          }
        }      
      })
    }
  function formTypeSubmit(val) {
    setMessage("");
    setFormType(val);
  }
  // create component
const LoginPage=()=>{
  return(
    <section className="container customContainer">
     
    {(formType !== "form") && <div onClick={() => setFormType("form")}>
      <p className="mt-5 ml-2 pl-2 browserback">back</p>
    </div>
    }
  
  

  <div className="col-md-12">
    {message.length > 0 && (
      <Alert variant="danger" onClose={() => setMessage("")} dismissible>
        {/* <Alert.Heading>Oh snap! You got an error!</Alert.Heading> */}
        <p>{t(message)}</p>
      </Alert>
    )}
  </div>
  <div className="col-lg-6 m-auto TabletLayout">
    {(formType === "form") && <LoginSelection formTypeSubmitCallback={formTypeSubmit} />}
    {(formType === "email") && <EmailForm onSubmit={handleSubmit} updateField={updateField} form={form}/>}
    {(formType === "mobile") && <MobileForm onSubmit={onSignInSubmit} updateField={updateField} form={form} updatepersonalnumber={updatepersonalnumber} />}
  </div>
  {/* <div className="loading" id="loading-icon" style={{ display: "none" }}> <div> <svg width="100" height="100" viewBox="0 0 38 38" xmlns="http://www.w3.org/2000/svg" > <defs> <linearGradient x1="8.042%" y1="0%" x2="65.682%" y2="23.865%" id="a" > <stop offset="0%"></stop> <stop offset="63.146%"></stop> <stop offset="100%"></stop> </linearGradient> </defs> <g fill="none"> <g transform="translate(1 1)"> <path d="M36 18c0-9.94-8.06-18-18-18" id="Oval-2" stroke="#EC661C" transform="rotate(122.524 18 18)" > <animateTransform attributeName="transform" type="rotate" from="0 18 18" to="360 18 18" dur="0.9s" repeatCount="indefinite" ></animateTransform> </path> <circle fill="#fff" cx="36" cy="18" r="1" transform="rotate(122.524 18 18)" > <animateTransform attributeName="transform" type="rotate" from="0 18 18" to="360 18 18" dur="0.9s" repeatCount="indefinite" ></animateTransform> </circle> </g> </g> </svg> </div> </div> */}
</section>
  )
}
// 
  return (
   <>
   {/*
      * Check desktop mode and split div for desktop 
     */}
   {
     DesktopMode === true ? <div className="col-md-12 layoutSpace">
            
     <div className ="col-md-5 float-lg-left login-bg">
     {/* <img src={ModelLR} className="Login-Banner"/> */}
     </div>
     <div className = "col-md-7 float-lg-right tablet-full-width">
      {LoginPage()}
     </div>
   </div>:
   LoginPage()
   }
   </>
  );
}

