import React, { useState, useEffect } from 'react';
import { AboutDisclaimers } from "../../Services/ApiEndPoints";
import { APICALL } from "../../Services/ApiServices";
import { t } from '../../Translation/Translation';
import { useHistory } from 'react-router-dom';
import '../../Static/css/common.css';
import BackButtonComponent from '../../Utilities/BackButtonComponent';
import BackButton from "../../Utilities/BackButton";

export default function Disclaimer({ backButton }) {
  const [disclaimer, setDisclaimer] = useState(".");
  const history = useHistory();
  const DesktopMode = (localStorage.getItem('DeviceMode')).includes('Desktop');
  const [backbuttonUrl, setbackbuttonUrl] = useState();
  const [urlState, seturlState] = useState(false);
  useEffect(() => {
    let User_id = localStorage.getItem("id");
    const res = {
      "user_id": User_id
    }
    APICALL.service(AboutDisclaimers, "POST", res)
      .then(result => {
        if (result != null)
          setDisclaimer(result.disclaimer);
      });
  }, []);
  // create as html
  function createMarkup() {
    if(disclaimer){
      let data = disclaimer.replaceAll("<p></p>", "<br />");
    return { __html: data };
    }
    return { __html: disclaimer };
  }
  /***
  * Store url in session storage for back button functionality
  */
  useEffect(() => {
    if (urlState === false) {
      setbackbuttonUrl('/settings/3');
      seturlState(true);
    }
  }, [])

  //Remove the last item from array and push the url for back button 
  function historyBack() {
    let response = BackButton("/settings");
    if (response === false) {
      backButton(false, 3);
      history.push('/settings');
    }
    else {
      history.push(response);
    }
  }

  return (
    <div className=" customContainer py-3 space-layout-single">

      {/* <div className="col-md-12 row mt-4">
          <div className="col-2"> <p className="browserback" onClick={history.goBack}>back</p></div>
          <h1 className="pagetitle textcolor1 col-10 text-center">{t("Privacy disclaimer")}</h1>
        </div> */}
      <div className="col-md-12 topsection-wrap  mt-0 px-0 pt-4">
        <p className={DesktopMode === true ? "hide-class" : "browserback"} onClick={historyBack}>back</p>
        {backbuttonUrl && <BackButtonComponent backButtonLink={backbuttonUrl} />}
        <h1 className="pagetitle textcolor1 text-center">{t("Privacy disclaimer")}</h1>
      </div>
      <div className="col-md-12 col-12 px-1 my-4">
        <div className="Richtexterditor" dangerouslySetInnerHTML={createMarkup()} />
      </div>
    </div>
  );
}

