import React from 'react';
import './Login.css';
import '../../Static/css/common.css'
import PhoneInput from 'react-phone-input-2';
// import 'react-phone-input-2/lib/style.css';
import './reactphone.css';

const MobileForm = ({ onSubmit, updateField, form, updatepersonalnumber }) => {

  // function validateFormMobile() {
  //   if (form.mobile.length > 0) {
  //     return true;
  //   } else {
  //     return false;
  //   }
  // }

  // const phonenumber = (val) => {
  //   var phoneno = /^(\+\d{1,3}[- ]?)?\d{10}$/;
  //   if (val.match(phoneno)) {
  //     return true;
  //   } else {
  //     return false;
  //   }
  // };

  function phonechange(phone) {

    updatepersonalnumber(phone.phone);
  }

  // function onSignInSubmit(event) {
  //   event.preventDefault();
  //   mobileSubmitCallBack(mobile);
  // }

  return (
    <div className=" pt-4">
      <form onSubmit={onSubmit} className="pt-2 loginForm mobileform">
        <p className="textcolor11  mb-1 pagetitle">Login</p>

        <div className="form-group countyNumber">
          <label className="textcolor1">Phone number</label>
          <PhoneInput
            country={"be"}
            onlyCountries={['be', 'nl', 'fr', 'de', 'es', 'ie', 'dk', 'se', 'no', 'lu', 'pl', 'it', 'gb', 'us', 'tr', 'ch', 'in']}
            autocompleteSearch={false}
            value={form.mobile}
            onChange={(phone) => phonechange({ phone })}
            // placeholder=''
            updateField
          />
        </div>

        {/* 
        <div className="col-md-12 p-0 pt-3">
          <div className="form-group checkbox-bottom">
            <label className="form-check-label checkboxWrap">
              <input
                className="form-check-input"
                name="termsStatus"
                value={form.termsStatus}
                type="checkbox"
                onChange={updateField}
              />
              <span>
                {('I agree to the')} <a className="textcolor1 terms">{('Terms of Use')}</a>{" "}
                {('and')}{" "}
                <a className="textcolor1 terms">{('Privacy Policy of Bleckmann')}</a>
              </span>
              <span className="checkmark"></span>
            </label>
          </div>
        </div> */}

        <div className="col-md-12 px-0 text-center ">
          <div id="recaptcha-container"></div>
          <button
            type="submit"
            // disabled={!validateFormMobile()}
            className="btn btn-block mybtn btn-primary  button-bottom bg-color tx-tfm"
          >
            {" "}
            Send my code{" "}
          </button>
        </div>
      </form>
    </div>
  );
};

export default MobileForm
