import React, { useState, useEffect } from "react";
import { SocialMediaLink } from "../../Services/ApiEndPoints";
import { APICALL } from "../../Services/ApiServices";
import { t } from "../../Translation/Translation";
import { useHistory } from "react-router-dom";
import "./socialMedia.css";
import BackButtonComponent from "../../Utilities/BackButtonComponent";
import BackButton from "../../Utilities/BackButton";

function SocialMedia() {
  // set social media in state
  const [SocialMediaURL, setSocialMediaURL] = useState(null);
  // in case of url not tr set message
  const [Message, setMessage] = useState("");
  const [backbuttonUrl, setbackbuttonUrl] = useState();
  const [urlState, seturlState] = useState(false);
  const history = useHistory();
  const [DeviceMode, setDeviceMode] = useState(false);

  useEffect(() => {
    let User_id = localStorage.getItem("id");
    const res = {
      user_id: User_id,
    };
    // api call to fetch url from backend
    APICALL.service(SocialMediaLink, "POST", res).then((result) => {
      if (result != null)
        if (result.STATUS === 201) {
          // set result in state
          setSocialMediaURL(result.DATA.mediaURL);
        } else {
          setMessage(result.DATA.message);
        }
    });
  }, []);

  /***
   * Store url in session storage for back button functionality
   */
  useEffect(() => {
    if (urlState === false) {
      setbackbuttonUrl("/socialmedia");
      seturlState(true);
    }
    if (localStorage.getItem("DeviceMode") === "Desktop") {
      setDeviceMode(true);
    }
  }, []);

  //Remove the last item from array and push the url for back button
  function historyBack() {
    let response = BackButton();
    history.push(response);
  }

  return (
    <div
      className={`${
        DeviceMode
          ? "Desktop-mode-list-and-detail-view layoutSpace "
          : "customContainer py-3 space-layout-single"
      }`}
    >
      <div className={`${DeviceMode ? "container" : ""}`}>
        <div className="topsection-wrap mob-button-wrap mt-0 px-0 pt-4">
          <p className="browserback" onClick={historyBack}>
            back
          </p>
          {backbuttonUrl && (
            <BackButtonComponent backButtonLink={backbuttonUrl} />
          )}
          <h1 className="pagetitle textcolor1 text-center">
            {t("Social medias")}
          </h1>
        </div>
        <div className="text-center col-md-12 col-lg-12 col-sm-12 px-0">
          {SocialMediaURL === null ||
          SocialMediaURL === undefined ||
          SocialMediaURL === "" ? (
            <div className="test-center mx-md-5 mt-5">
              <h3 className="col mx-md-5 mt-5">{Message}</h3>
            </div>
          ) : (
            <div className="social-media-iframe">
              <iframe
                title="social-media"
                src={SocialMediaURL}
                width="100%"
                height="100%"
                style={{ height: "100vh" }}
                scrolling="no"
                frameborder="0"
                allowfullscreen="true"
                allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default SocialMedia;
