import React from 'react';
import ImageGallery from 'react-image-gallery';
import "./image-gallery.css";
import "./Slider.css"
// import { Redirect } from "react-router-dom";


const PREFIX_URL = 'src/static/imgs/';


export default class Slider extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      showIndex: false,
      showBullets: true,
      infinite: true,
      showGalleryFullscreenButton: true,
      showGalleryPlayButton: true,
      showNav: true,
      slideDuration: 450,
      slideInterval: (parseInt(this.props.bannerTime) * 1000),
      slideOnThumbnailOver: false,
      showVideo: {},
      images: this.props.sliderData,
      fullscreen: this.props.EnableFullScreenImage
    };
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.slideInterval !== prevState.slideInterval ||
      this.state.slideDuration !== prevState.slideDuration) {
      // refresh setInterval
      this._imageGallery.pause();
      this._imageGallery.play();
    }
  }

  componentDidMount() {
    // if (!JSON.parse(localStorage.getItem("id"))) return;
    // APICALL.service(Banner, "GET")
    //   .then(result => {
    //   //  var firstKey = Object.keys(result)[0];
    //     console.log("banner data.. : ", result.data);
    //     this._constructImageData(result.data);
    //             console.log("banner time : ", result.banner_time);

    //     this.setState({ slideInterval: (JSON.parse(result.banner_time) * 1000) });
    //    // console.log("data banner : ", result[firstKey])
    //   })
    //   .catch(error => {
    //     console.log("banner error : ", error);
    //   //  setMessage("Error Login user, Please check it again");
    //   })


    this._imageGallery.play();
  }


  _onImageClick(event) {
    var index = this._imageGallery.getCurrentIndex();
    if (this.state.images[index].link) {
      this.props.history.push(this.state.images[index].link)
    }
    else {
    this.props.EnableFullScreenImage(this.state.images[index].original);
    }
    // if (this.state.images[index].entry_type === "Events") {
    //   this.props.history.push("/event-details/" + this.state.images[index].id);
    // } else if (this.state.images[index].entry_type === "News") {
    //   this.props.history.push("/news-details/" + this.state.images[index].id);

    // }

  }

  _onImageLoad(event) {
    //console.log('loaded image', event.target.src);
  }

  _onSlide(index) {
    this._resetVideo();
    //console.log('slid to index', index);
  }

  _onPause(index) {
   // console.log('paused on index', index);
  }

  _onScreenChange(fullScreenElement) {
    //console.log('isFullScreen?', !!fullScreenElement);
  }

  _onPlay(index) {
    //console.log('playing from index', index);
  }

  _constructImageData(data) {
    data.forEach((val, i) => {
      //console.log(i, " : values : ", val)
      const obj = { 'original': val.teaserImage, 'thumbnail': val.teaserImage, 'id': val.id, 'entry_type': val.entry_type };
      this.setState({
        images: [...this.state.images, obj]
      });
    })
  }

  _getStaticImages() {
    let images = [];
    for (let i = 1; i < 6; i++) {
      images.push({
        original: `${PREFIX_URL}img${i}.jpg`,
        thumbnail: `${PREFIX_URL}img${i}.jpg`
      });
    }

    return images;
  }

  _resetVideo() {
    this.setState({ showVideo: {} });
    if (this.state.showPlayButton) {
      this.setState({ showGalleryPlayButton: true });
    }

    if (this.state.showFullscreenButton) {
      this.setState({ showGalleryFullscreenButton: true });
    }
  }

  _toggleShowVideo(url) {

    this.state.showVideo[url] = !Boolean(this.state.showVideo[url]);
    this.setState({
      showVideo: this.state.showVideo
    });

    if (this.state.showVideo[url]) {
      if (this.state.showPlayButton) {
        this.setState({ showGalleryPlayButton: false });
      }

      if (this.state.showFullscreenButton) {
        this.setState({ showGalleryFullscreenButton: false });
      }
    }
  }

  _renderVideo(item) {
    return (
      <div>
        {
          this.state.showVideo[item.embedUrl] ?
            <div className='video-wrapper'>
              <div className='close-video' onClick={this._toggleShowVideo.bind(this, item.embedUrl)} > </div>
              <iframe title="video" width='560' height='315' src={item.embedUrl} frameBorder='0' allowFullScreen > </iframe>
            </div>
            :
            <div onClick={this._toggleShowVideo.bind(this, item.embedUrl)}>
              <div className='play-button'></div>
              <img className='image-gallery-image' alt="..." src={item.original} />
              {
                item.description &&
                <span
                  className='image-gallery-description'
                  style={{ right: '0', left: 'initial' }}
                >
                  {item.description}
                </span>
              }
            </div>
        }
      </div>
    );
  }

  render() {
    return (

      <section className='app'>
        <ImageGallery
          ref={i => this._imageGallery = i}
          items={this.state.images}
          lazyLoad={false}
          onClick={this._onImageClick.bind(this)}
          onImageLoad={this._onImageLoad}
          onSlide={this._onSlide.bind(this)}
          onPause={this._onPause.bind(this)}
          onScreenChange={this._onScreenChange.bind(this)}
          onPlay={this._onPlay.bind(this)}
          infinite={true}
          showBullets={true}
          showIndex={true}
          showNav={true}
          showThumbnails={false}
          showPlayButton={false}
          showFullscreenButton={false}
          slideDuration={parseInt(this.state.slideDuration)}
          slideInterval={parseInt(this.state.slideInterval)}
          additionalClass="app-image-gallery  mt-4 mb-5"
        />
      </section>
    );
  }

}
