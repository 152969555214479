import React, { useState, useEffect } from "react";
import { Avatar } from 'antd';
import { useParams } from "react-router";
import { UserOutlined } from '@ant-design/icons';
import { UserProfile } from "../../Services/ApiEndPoints";
import { APICALL } from "../../Services/ApiServices";
import './whoiswho.css';
import { useHistory, Link } from 'react-router-dom';
import { t } from '../../Translation/Translation'
import PhoneInput from 'react-phone-input-2';
import FullScreenImage from "../../Utilities/FullScreenImage/FullScreenImage";
import BackButtonComponent from '../../Utilities/BackButtonComponent'
import BackButton from "../../Utilities/BackButton";


export default function WhoisWhoDetails({ WhoiswhoUserId, backButton }) {
    // let params = useParams();
    const [profiledata, setprofiledata] = useState({})
    const [profilehobbies, setprofilehobbies] = useState([''])
    // const [knownLanguage, setknownLanguage] = useState([''])
    const [locations, setLocations] = useState('');
    const [locationID, setLocationID] = useState();
    const history = useHistory();
    const [fullscreenState, setfullscreenState] = useState(false);
    const [imageSrc, setimageSrc] = useState("");
    const DesktopMode = (localStorage.getItem('DeviceMode')).includes('Desktop');
    const [backbuttonUrl, setbackbuttonUrl] = useState();
    const [urlState, seturlState] = useState(false);

    useEffect(() => {
        if (urlState === false && WhoiswhoUserId !== undefined) {
            setbackbuttonUrl('/whoiswho/' + WhoiswhoUserId);
            seturlState(true);
        }
    }, [WhoiswhoUserId])

    // back button funtionality
    function historyBack() {
        let response = BackButton("/whoiswho");
        if (response === false) {
            backButton(false);
            history.push('/whoiswho');
        }
        else {
            history.push(response);
        }
    }


    useEffect(() => {
        APICALL.service(UserProfile + WhoiswhoUserId, "GET")
            .then(result => {
                try {
                    let resulting_data = [];
                    setprofiledata(result);
                    (result.userHobbies !== null && Object.keys(result.userHobbies).map((key, i) => (
                        resulting_data[i] = result.userHobbies[key])))
                    setprofilehobbies(resulting_data);//storing hobbies as an array in profilehobbies 

                    Object.keys(result.location).map((key) => {
                        setLocations((result.location)[key]);
                        setLocationID(key);
                    });
                } catch (e) {
                    console.error(e);
                }

            });

    }, [WhoiswhoUserId]);

    var hobbieslist = "";
    hobbieslist = profilehobbies.map((m, i) =>
        <span key={i}>{m}{profilehobbies[i + 1] ? ', ' : ''}</span>
    );

    let mail, phone;


    function EnableFullScreenImage(imgdata) {
        setimageSrc(imgdata);
        setfullscreenState(true);
    }
    function DisableFullScreenImage() {
        setimageSrc("");
        setfullscreenState(false);
    }

    return (
        fullscreenState ? <FullScreenImage imageSrc={imageSrc} DisableFullScreenImage={DisableFullScreenImage} /> :
            profiledata && <div className='container customContainer mb-5'>
                <div className="col-md-12 topsection-wrap  mt-0 px-0 pt-4">
                    <p className={DesktopMode === true ? "hide-class" : "browserback"} onClick={historyBack}>back</p>
                    {backbuttonUrl && <BackButtonComponent backButtonLink={backbuttonUrl} />}
                    <h1 className="pagetitle textcolor1 text-center">{t('Profile')}</h1>
                </div>
                <div className="col-md-12 col-12 px-0 text-center pt-3 py-2">
                    <Avatar size={85} icon={<UserOutlined />} src={profiledata.profile_photo} onClick={() => profiledata.profile_photo === "" ? null : EnableFullScreenImage(profiledata.profile_photo)} />
                </div>
                <div className="text-center m-1">
                    <h4 className="textcolor1 font-weight-normal">{profiledata.firstName} {profiledata.lastName}</h4>
                </div>
                <div className="text-center">
                    <h6 className="font-weight-normal">{profiledata.userJobFunction}</h6>
                </div>
                <div className="text-center p-0">
                    <div className="row justify-content-center">
                        {mail = (((profiledata.workEmail === null) || (profiledata.workEmail === '')) ? profiledata.personalEmail : profiledata.workEmail), <a href={"mailto:" + mail} className={((profiledata.workEmail === null) || (profiledata.workEmail === "")) ? profiledata.sensitiveInfo === true ? (((profiledata.personalEmail === "") || (profiledata.personalEmail === null)) ? "disable-class emaildisable" : "emailactive") : "disable-class emaildisable" : "emailactive"}><p className="wswicons innericon  mb-0"></p></a>}
                        {phone = ((profiledata.workPhoneNumber === "") || (profiledata.workPhoneNumber === null)) ? profiledata.personalPhoneNumber : profiledata.workPhoneNumber, (phone === null || phone === undefined) ? null : phone = phone.replace("+", ""), <a target="_blank" href={"https://api.whatsapp.com/send?phone=" + phone} className={((profiledata.workPhoneNumber === ("")) || (profiledata.workPhoneNumber === null)) ? profiledata.sensitiveInfo === true ? (((profiledata.personalPhoneNumber === "") || (profiledata.personalPhoneNumber === null)) ? "disable-class whatsappdisable" : "whatsapactive") : "disable-class whatsappdisable" : "whatsapactive"} ><p className="wswicons innericon whatsAppinner mb-0"></p></a>}
                        <a href={"tel:" + phone} className={((profiledata.workPhoneNumber === ("")) || (profiledata.workPhoneNumber === null)) ? profiledata.sensitiveInfo === true ? (((profiledata.personalPhoneNumber === "") || (profiledata.personalPhoneNumber === null)) ? "disable-class disbalephone" : "activephone") : "disable-class disbalephone" : "activephone"} ><p className="wswicons innericon mb-0"></p></a>
                    </div>
                </div>
                <div className={((profiledata.department === '') || (profiledata.department === null)) ? "hide-class" : (localStorage.getItem('DeviceMode') === "Desktop") ? "col-md-12 col-12 px-5 my-4" : "col-md-12 col-12"}>
                    <h5 className="textcolor1 font-weight-normal">{t('Department')}</h5>
                    <h5 className="font-weight-normal">{profiledata.department}</h5>
                </div>
                <div className={((locations === '') || (locations === null)) ? "hide-class" : (localStorage.getItem('DeviceMode') === "Desktop") ? "col-md-12 col-12 px-5 my-4" : "col-md-12 col-12"}>
                    <h5 className="textcolor1 font-weight-normal">{t('Location')}</h5>
                    <Link to={('/ourlocations/' + locationID)} className="innerpagetitle">{locations}</Link>
                </div>
                <div className={((profiledata.workEmail === '') || (profiledata.workEmail === null)) ? "hide-class" : (localStorage.getItem('DeviceMode') === "Desktop") ? "col-md-12 col-12 px-5 my-4" : "col-md-12 col-12"}>
                    <h5 className="textcolor1 font-weight-normal">{t('Work email address')}</h5>
                    <h5 className="font-weight-normal">{profiledata.workEmail}</h5>
                </div>
                <div className={((profiledata.workPhoneNumber === '') || (profiledata.workPhoneNumber === null)) ? "hide-class" : (localStorage.getItem('DeviceMode') === "Desktop") ? "col-md-12 col-12 px-5 my-4 flag-phone" : "col-md-12 col-12 flag-phone"}>
                    <h5 className="textcolor1 font-weight-normal">{t('Work phone number')}</h5>
                    <h5 className="font-weight-normal">
                        <PhoneInput
                            country={'us'}
                            value={profiledata.workPhoneNumber}
                            updateField
                            disabled
                        /></h5>
                </div>
                <div className={((hobbieslist !== undefined && hobbieslist.length <= 0) || (hobbieslist === null) || (hobbieslist === undefined)) ? "hide-class" : (localStorage.getItem('DeviceMode') === "Desktop") ? "col-md-12 col-12 px-5 my-4" : "col-md-12 col-12"}>
                    <h5 className="textcolor1 font-weight-normal">{t('Hobbies')}</h5>
                    <h5 className="font-weight-normal">{hobbieslist}</h5>
                </div>
                <div className={((profiledata.funFact === '') || (profiledata.funFact === null)) ? "hide-class" : (localStorage.getItem('DeviceMode') === "Desktop") ? "col-md-12 col-12 px-5 my-4" : "col-md-12 col-12"}>
                    <h5 className="textcolor1 font-weight-normal">{t('Fun facts')}</h5>
                    <h5 className="font-weight-normal">{profiledata.funFact}</h5>
                </div>
                <div className={(profiledata.sensitiveInfo === false) ? "hide-class" : (localStorage.getItem('DeviceMode') === "Desktop") ? "col-md-12 col-12 px-5 my-4" : "col-md-12 col-12"}>
                    <h5 className={((profiledata.personalEmail === '') || (profiledata.personalEmail === null)) ? "hide-class" : "textcolor1 font-weight-normal"}>{t('Private email address')}</h5>
                    <h5 className="font-weight-normal">{profiledata.personalEmail}</h5>
                </div>
                <div className={(profiledata.sensitiveInfo === false) ? "hide-class" : (localStorage.getItem('DeviceMode') === "Desktop") ? "col-md-12 col-12 px-5 my-4 flag-phone" : "col-md-12 col-12  flag-phone"}>
                    <h5 className={((profiledata.personalPhoneNumber === '') || (profiledata.personalPhoneNumber === null)) ? "hide-class" : "textcolor1 font-weight-normal"}>{t('Private phone number')}</h5>
                    <h5 className={((profiledata.personalPhoneNumber === '') || (profiledata.personalPhoneNumber === null)) ? "hide-class" : "font-weight-normal"}>
                        <PhoneInput
                            // country={'us'}
                            value={profiledata.personalPhoneNumber}
                            updateField
                            disabled
                        />
                    </h5>

                </div>
            </div>
    )
}
