import React from 'react';
import filledLikeIcon from './images/like.png';
import filledHeartIcon from './images/heart.png';
import filledIdeaIcon from './images/love.png';
import filledClappIcon from './images/clap.png';
import likeIcon from './images/like-l.png';
import heartIcon from './images/heart-l.png';
import loveIcon from './images/love-l.png';
import clappIcon from './images/clap-l.png';
import './reactionpopup.css';

const ReactionsPopup = (props) => {
    let reactions = props.data;
    let entryId = props.entryId;
    let count = props.reactionCountObj;

    // rendering the reaction components in popup
    const reactionIcon = (reaction) => {
        switch(reaction.reactionKey){
            case "like":
                return getReactionDiv(reaction, filledLikeIcon, likeIcon, count.likes_count)
            case "heart":
                return getReactionDiv(reaction, filledHeartIcon, heartIcon, count.heart_count)
            case "love":
                return getReactionDiv(reaction, filledIdeaIcon, loveIcon, count.love_count)
            case "clap":
                return getReactionDiv(reaction, filledClappIcon, clappIcon, count.clap_count)
        }
    }

    // div structure for reaction icons
    const getReactionDiv = (reaction, filledIcon, icon, ReactionCount) => {
        return (
            <span className='d-flex align-content-center align-items-center justify-content-center reaction-icon-rigt-space'>
                <span className='d-flex align-content-center align-items-center justify-content-center' key={reaction.reactionKey}  onClick={(e)=>props.clickOnReacion(e, reaction, entryId)}>
                    {
                        // if user reacted rendering filled icons else empty icons
                        (props.ReactedKey === reaction.reactionKey) ? 
                            <span className='popup-icon-section' title={filledIcon}>
                                <img src={filledIcon} className='popup-icon' alt="filledIcon" />
                        </span>
                        : 
                         <span className='popup-icon-section' title={icon}>
                            <img src={icon}  className='popup-icon'  alt="icon"/>
                         </span>
                    }
                </span>
                {/* adding counts reaction counts */}
                { ReactionCount > 0 &&
                    <span className='reaction-count'>
                        {ReactionCount}
                    </span>
                }
            </span>
        );
    }
  return(
      <span className='d-flex icon-border'>
      {
          reactions.map((reaction, key) => (
            <span key={"reactions"+key}>
                {
                    // calling reaction icon rendering function
                    reactionIcon(reaction)
                }
            </span>
          ))
      }
      </span>
  );
}

export default ReactionsPopup;