import React, {useState} from 'react'
import QrReader from 'react-qr-reader'
import Popup from '../../Utilities/Popup/modelPopup';
import { APICALL } from "../../Services/ApiServices";
// import BackButtonComponent from "../../Utilities/BackButtonComponent";
import { MeetingsList } from "../../Services/ApiEndPoints";

import { useHistory } from "react-router-dom";
import { Result } from 'antd';

function ScanQR(props) {

    const history = useHistory();
    const [popupMessage, setPopupMessage] = useState("");
    let count = 0

    //Function to redirect after scanning
    const handleScan = (data) => {
           
        if (data !== 'null' && data !== null && data !== undefined && count===0) {
            count = count + 1
            let apiData = {
                "flow" : "room",
                "roomtoken" : data
            }
            let url = MeetingsList + '/' + localStorage.getItem('id')
            
            APICALL.service(url, "PUT", apiData)
                .then((result) => {
                    
                    if (result.status === 200){
                        let id = result.schedules[0].id
                        let meetingUrl = '/meetingrooms/' + id
                        history.push(meetingUrl);
                    }else{
                        setPopupMessage('No meeting room available for this qr code')
                    }
              })
        }
    }

    //Function to handle error in scanning
    const handleError = () => {
        setPopupMessage('Please allow the camera permission to scan QR')
    }

    return (
        <div>
            <h3 className="textcolor1 mt-4 mb-5 text-center">Scan QR code</h3>
            {/* <h5 className="my-4 text-center">Place the qr code in the below box to scan</h5> */}
            <Popup
              show={popupMessage !== "" ? true : false}
              onHide={() => setPopupMessage('')}
              size={"lg"}
              title={"Alert"}
              body={popupMessage}
            />
            <QrReader
                delay={300}
                facingMode ={'environment'}
                onError={() => handleError()}
                onScan={(e) => handleScan(e)}
                style={{ width: '96%', margin: 'auto'}}
                className="scan-box"
            />
        </div>
    );
}
export default ScanQR;