import React, { useEffect, useState } from "react";
import pdfIcon from "./Icons/pdf.png";
import videoIcon from "./Icons/play-button.png";
import "./Library.css";
import { APICALL } from "../../Services/ApiServices";

const LibraryList = (props) => {
  const data = JSON.parse(localStorage.getItem("library"));
  const id = props.id;
  const [DeviceMode, setDeviceMode] = useState(false);
  const getLibraryIcon = (value) => {
    var file_type = APICALL.get_url_extension(value);
    var videos = ["avi", "mpeg", "mp4", "mpg"];
    if (file_type === "pdf") {
      return (
        <img
          src={pdfIcon}
          alt="Lights"
          width="100%"
          className="libicons libinnerdetail libicons-thumbnail"
        />
      );
    } else if (videos.includes(file_type.toLowerCase())) {
      return (
        <img
          src={videoIcon}
          alt="Lights"
          width="100%"
          className="libicons libinnerdetail libicons-thumbnail"
        />
      );
    } else {
      return (
        <img
          src={value}
          alt="Lights"
          width="100%"
          className="libicons libinnerdetail image"
        />
      );
    }
  };

  useEffect(() => {
    if (localStorage.getItem("DeviceMode") === "Desktop") {
      setDeviceMode(true);
    }
  }, []);

  // const previewContent = (value) => {
  //   var url = "/library-view/";
  //   history.push({
  //     pathname: url,
  //     state: { data: value, title: data[id].title},
  //   });
  // };
  return (
    <div className="container  pl-0 py-3 mt-2">
      <div className="row px-3 detail-Section">
        {Object.keys(data[id].data).map((value, i) => {
          return (
            <div
              key={i}
              // className="grid col-4 col-sm-3 col-md-3 col-lg-3 "
              className={`grid col-4 col-sm-4 col-md-4 ${
                DeviceMode ? "col-lg-3" : "col-lg-4"
              }`}
              onClick={() => props.previewContent(data[id].data[value], true)}
            >
              <div className="contentWrap">
                <div className="contentoverlay"></div>
                {/* <img
                src={getLibraryIcon(data[id].data[value])}
                alt="Lights"
                width="100%"
                className="libicons libinnerdetail"
              /> */}
                {getLibraryIcon(data[id].data[value])}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default LibraryList;
