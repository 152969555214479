import React,{useState,useEffect} from 'react';
import { Modal } from "react-bootstrap";
import { UserProfile, DataTranslations } from "../../../Services/ApiEndPoints";
import { APICALL } from "../../../Services/ApiServices";
import './BdayPopup.css';
import {t} from '../../../Translation/Translation';
import defaultImg from './user.png';
export default function BDayPopup() {
    const [show, setShow] = useState(true);
    const handleClose = () => setShow(false);
    // const handleShow = () => setShow(true);
    const[user,setUser]=useState('');
    const [get_id]=useState(JSON.parse(localStorage.getItem("id")))

    useEffect(() => {
    // const get_id = localStorage.getItem("id");
         if(!JSON.parse(localStorage.getItem("translations"))){
         //Getting translation and storing in the localstorage.. 
          APICALL.service(DataTranslations, "GET", "", 0, 0).then((result) => {
            localStorage.setItem("translations", JSON.stringify(result));
          });
         }
    APICALL.service(UserProfile + get_id, "GET")
        .then(result => {
            setUser(result);
            localStorage.setItem('activeLanguage',result.active_language['value'])
            
        }).catch(error => {
            console.error("profile error : ", error);
        })
    },[]);
/**
 * converting date format dd-m-yyyy hr-m-s to yyyy-mm-dd format
 */
    let today = new Date();
    let dd=today.getDate();
    let mm=today.getMonth()+1;
    if (dd < 10) { 
        dd = '0' + dd; 
    } 
    if (mm < 10) { 
        mm = '0' + mm; 
    } 
    let yyyy=today.getFullYear();
    // let yyyy=2022
    let todayDate = mm + "-"+ dd;
    // let todayDate = '2022-09-17'
    // console.log("Today date- ->",todayDate);
    let userBirthDate,BirthDateCompare;
    try{
        if (user) {
        userBirthDate=user.birthDate.split('-');
        BirthDateCompare=userBirthDate[1]+"-"+userBirthDate[2];
    }
}catch(e){
    console.error(e);
}
    // console.log("todayDate--->",todayDate);
    // console.log("birth date -->",BirthDateCompare);
    // let todayDate = '2022-09-17'
   // console.log("Today date- ->",todayDate);
   // console.log("birth date -->",user.birthDate);
    /**
     * checking session has BDAYPOPUP variable
     * if not only i will make variable to false
     * based on this popup run only once
     */
    if(sessionStorage.getItem("BDAYPOPUP")===null)
        sessionStorage.setItem("BDAYPOPUP",false);
    /**
     * closing popup with set variable as true
     */    
    // if ((parseInt(localStorage.getItem('Bdate'))+1) ===yyyy) {
    // }         
    const popupclose=()=>{
        sessionStorage.setItem("BDAYPOPUP",true);
        localStorage.setItem('BDate',yyyy)
        handleClose();
    }
  return (
   user && <div>
        {/* checking conditions to show popup */}
          {user && <Modal dialogClassName="modal-dialog-centered bdaypopup" show={((todayDate === BirthDateCompare) && (sessionStorage.getItem("BDAYPOPUP") === "false"||(sessionStorage.getItem("BDAYPOPUP")==="true")) && ((localStorage.getItem('BDate')===null)||((parseInt(localStorage.getItem('BDate'))+1) ===yyyy))) ? show : false} className="text-center" >
              <Modal.Body className="align-center Modal-BDay" >
                  <button className="float-right p-0 m-0 bg-color birthadayButtonClose" onClick={popupclose} >x</button>
                  <img alt="user-img" className={(user.profile_photo===null || user.profile_photo==="")?'hide-class':"rounded-circle"} height="150px" src={user.profile_photo} />
                  <img alt="user-img" className={(user.profile_photo===null || user.profile_photo==="")?"rounded-circle":'hide-class'} height="150px" src={defaultImg} />
                  {/* <h4 className="text-center">{user.firstName}</h4> */}
                  {/* {user.lastName} */}
                  {/* <p className="text-center">"{t(BDayWish)}"</p> */}
              </Modal.Body>
              <div className="text-center buttonDiv"><button variant="secondary" className="btn bg-color col-12 col-md-12" onClick={popupclose}>
                 {t('Happy Birthday!')} <br /> {t('From Bleckmann with Love')}
              </button></div>
          </Modal>
          }
    </div>
  );
}