import React, { useState, useEffect } from "react";
import { toolBoxEndpoint } from "../../Services/ApiEndPoints";
import { APICALL } from "../../Services/ApiServices";
import SinglePageLayout from "../Common/singlePageLayout";

function Toolbox() {
  const [toolBox, setToolBoxData] = useState();

  useEffect(() => {
    let userId = localStorage.getItem("id");
    const param = {
      user_id: userId,
    };
    APICALL.service(toolBoxEndpoint, "POST", param).then((result) => {
      if (result != null) setToolBoxData(result.bcrew_toolbox);
    });
  }, []);

  return (
    <SinglePageLayout
      header={"Toolbox"}
      layoutData={toolBox}
      url={"/toolbox"}
    />
  );
}

export default Toolbox;
