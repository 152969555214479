import React, { useState, useEffect, useRef } from "react";
import { t } from "../../Translation/Translation";
import Select from "react-select";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { APICALL } from "../../Services/ApiServices";
import { SignatureDataFetch } from "../../Services/ApiEndPoints";
import "./signature.css";
import CryptoJS from "crypto-js";
import { useHistory } from "react-router-dom";
import BackButtonComponent from "../../Utilities/BackButtonComponent";
import BackButton from "../../Utilities/BackButton";

function SignatureNewMail(props) {
  const Countries = [
    "be",
    "nl",
    "fr",
    "de",
    "es",
    "ie",
    "dk",
    "se",
    "no",
    "lu",
    "pl",
    "it",
    "gb",
    "us",
    "tr",
    "ch",
    "in",
  ];
  const [entity, setEntity] = useState();
  const [addresses, setAddresses] = useState();
  const [name_msg, setName_msg] = useState("");
  const [job_title_msg, setjob_title_msg] = useState("");
  const [number_msg, setnumber_msg] = useState("");
  const [email_msg, setEmail_msg] = useState("");
  const [disclaimer, setDisclaimer] = useState();
  const name_exception = t("Please add your name");
  const jobTitle_exception = t("Please add job title");
  const mobile_exception = t("Please add at least one number");
  const email_exception = t("Please add proper email address");
  const [disabledFields, setdisabledFields] = useState({
    website: "",
    Bleckmann_entity: "",
  });
  const [DeviceMode, setDeviceMode] = useState(false);
  const [DisclaimerStatus, setDisclaimerStatus] = useState();
  const history = useHistory();
  const [backbuttonUrl, setbackbuttonUrl] = useState();
  const [urlState, seturlState] = useState(false);
  const mobilenumber = useRef();
  const directnumber = useRef();
  const officenumber = useRef();

  useEffect(() => {
    if (urlState === false) {
      setbackbuttonUrl("/signature/" + 1);
      seturlState(true);
    }
  }, []);

  function handleSubmit(e) {
    e.preventDefault();
    if (props.signatureForm.user_name === "") {
      setName_msg(name_exception);
    }

    if (props.signatureForm.job_title === "") {
      setjob_title_msg(jobTitle_exception);
    }

    if (props.signatureForm.email === "") {
      setEmail_msg(email_exception);
    }

    // if (
    //   props.signatureForm.mobile === "" &&
    //   props.signatureForm.direct_phone === "" &&
    //   props.signatureForm.office_phone === ""
    // ) {
    //   setnumber_msg(mobile_exception);
    // } else 
    if (
      props.signatureForm.user_name !== "" &&
      props.signatureForm.job_title !== "" &&
      email_msg === "" &&
      props.signatureForm.email !== "" 
      // &&
      // (props.signatureForm.mobile !== "" ||
      //   props.signatureForm.direct_phone !== "" ||
      //   props.signatureForm.office_phone !== "")
    ) {
      // if (
      //   props.signatureForm.mobile.length > 5 ||
      //   props.signatureForm.direct_phone.length > 5 ||
      //   props.signatureForm.office_phone.length > 5
      // )
        downloadXML();
      // else {
      //   setnumber_msg(t("Please enter valid number"));
      // }
    }
  }

  function nameSplitter(name, value) {
    if (value === "first") return name.trim().split(" ", 1)[0];
    else if (value === "last") {
      let first_half = name.trim().split(" ", 1)[0];
      return name.trim().substr(first_half.length, name.length).trim();
    }
  }

  function decryptAndDownload(data) {
    let passPhrase = process.env.REACT_APP_AUTHENTICATION_TOKEN;
    let encrypted_json_string = data;
    var string1 = JSON.stringify(encrypted_json_string);
    var obj_json = JSON.parse(string1);
    var encrypted = obj_json.ciphertext;
    var salt = CryptoJS.enc.Hex.parse(obj_json.salt);
    var iv = CryptoJS.enc.Hex.parse(obj_json.iv);
    var key = CryptoJS.PBKDF2(passPhrase, salt, {
      hasher: CryptoJS.algo.SHA512,
      keySize: 64 / 8,
      iterations: 999,
    });
    var decrypted = CryptoJS.AES.decrypt(encrypted, key, { iv: iv });
    var url = decrypted.toString(CryptoJS.enc.Utf8);
    return url;
  }

  function downloadXML() {
    const get_id = localStorage.getItem("id");
    let mobile_number = ((mobilenumber.current.state.formattedNumber) !== null ? (mobilenumber.current.state.formattedNumber) : props.signatureForm.mobile);
    let direct_number = ((directnumber.current.state.formattedNumber) !== null ? (directnumber.current.state.formattedNumber) : props.signatureForm.direct_phone);
    let office_number = ((officenumber.current.state.formattedNumber) !== null ? (officenumber.current.state.formattedNumber) : props.signatureForm.office_phone);
    let data = {
      user_id: get_id,
      mail: "new",
      salutation: props.signatureForm.salutation,
      firstname: nameSplitter(props.signatureForm.user_name, "first"),
      lastname: nameSplitter(props.signatureForm.user_name, "last"),
      jobtitle: props.signatureForm.job_title,
      mobile: mobile_number.length > 5 ? mobile_number : '',
      direct: direct_number.length > 5 ? direct_number : '',
      office: office_number.length > 5 ? office_number : '',
      address: props.bleckmann_address.label,
      entity: props.bleckmann_entity.label,
      usermail: props.signatureForm.email,
    };
    APICALL.service(SignatureDataFetch, "POST", data).then((result) => {
      if (result !== undefined) {
        try {
          if (result.status === 200) {
            let newMail = decryptAndDownload(result.data.new);
            let replyMail = decryptAndDownload(result.data.reply);
            window.open(newMail, "_blank");
            window.open(replyMail, "_self");
          }
        } catch (e) {
          console.error(e);
        }
      }
    });
  }

  function PhoneChange(phone, type) {
    props.UpdateNumbers(phone, type);
  }

  // create as html
  function createMarkup() {
    if(disclaimer){
      let data = disclaimer.replaceAll("<p></p>", "<br />");
      return { __html: data };
    }
    return { __html: disclaimer };
  }

  function NumberValidation(e, type) {
    switch (type) {
      case "mobile":
        if (
          props.signatureForm.direct_phone === "" &&
          props.signatureForm.office_phone === "" &&
          e === ""
        )
          return setnumber_msg(mobile_exception);
        else return setnumber_msg("");
      case "direct_phone":
        if (
          props.signatureForm.mobile === "" &&
          props.signatureForm.office_phone === "" &&
          e === ""
        )
          return setnumber_msg(mobile_exception);
        else return setnumber_msg("");
      case "office_phone":
        if (
          props.signatureForm.mobile === "" &&
          props.signatureForm.direct_phone === "" &&
          e === ""
        )
          return setnumber_msg(mobile_exception);
        else return setnumber_msg("");
      default:
        break;
    }
  }

  //To fetch data from API
  useEffect(() => {
    //checking Device mode
    if (localStorage.getItem("DeviceMode") === "Desktop") {
      setDeviceMode(true);
    }
    let data = {
      mail: "new",
    };
    const get_id = localStorage.getItem("id");
    APICALL.service(SignatureDataFetch + "/" + get_id, "PUT", data).then(
      (result) => {
        if (result !== undefined) {
          try {
            setDisclaimer(result.desclaimer.desclaimer);
            setDisclaimerStatus(result.desclaimer.newStatus);
            setdisabledFields({
              ...disabledFields,
              website: result.website,
              Bleckmann_entity: result.bleckmann_entity,
            });
            if (result.entities.length > 0) {
              props.SelectionChange(result.entities[0], "bleckmann_entity");
              setEntity(result.entities);
            }
            if (result.locations.length > 0) {
              props.SelectionChange(result.locations[0], "bleckmann_address");
              setAddresses(result.locations);
            }
          } catch (e) {
            console.error(e);
          }
        }
      }
    );
  }, []);

  function handleSelectChange(e, type) {
    props.SelectionChange(e, type);
  }

  function handleValidations(e, type) {
    if (e.target.value === "") {
      if (type === "user_name") setName_msg(name_exception);
      if (type === "job_title") setjob_title_msg(jobTitle_exception);
    } else if (e.target.value !== "") {
      if (type === "user_name") setName_msg("");
      if (type === "job_title") setjob_title_msg("");
    }
  }

  function ValidateEmail(e) {
    var mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    if (e.target.value === "") {
      setEmail_msg(email_exception);
      return;
    } else {
      if (e.target.value.match(mailformat)) {
        setEmail_msg("");
      } else {
        setEmail_msg(email_exception);
      }
    }
  }

  // back button funtionality
  function historyBack() {
    let response = BackButton("/signature");
    if (response === false) {
      props.backButton(false);
      history.push("/signature");
    } else {
      history.push(response);
    }
  }

  return (
    <div className="container customContainer">
      <div className="mb-5">
        <div className="justify-content-md-center rounded mb-5">
          <div className="col-md-12  mt-4 mob-space-location">
            <div className="row">
              {DeviceMode ? (
                <div className="col-md-12 topsection-wrap  mt-0 ">
                  <h1 className="signature_title textcolor1 text-center">
                    {t("Signature for an email")}
                  </h1>
                </div>
              ) : (
                <>
                  <div className="col-md-12 topsection-wrap  mt-0 ">
                    {" "}
                    <p className="browserback top_wrap" onClick={historyBack}>
                      back
                    </p>
                    {backbuttonUrl && (
                      <BackButtonComponent backButtonLink={backbuttonUrl} />
                    )}
                    <h1 className="signature_title textcolor1 text-center">
                      {t("Signature for an email")}
                    </h1>
                  </div>
                </>
              )}
            </div>

            <br></br>
            <div className="post">
              <form
                className="ideaboxform"
                id="form"
                onSubmit={(e) => handleSubmit(e)}
              >
                <div className="row signature_label">
                  <div className={`${DeviceMode ? "col-md-3" : "col-12"}`}>
                    <div className={`${DeviceMode ? "form-group" : ""}`}>
                      <label className="textcolor1 signature_label_font">
                        {t("Closing regards")}
                      </label>
                    </div>
                  </div>
                  <div className={`${DeviceMode ? "col-md-9" : "col-12"}`}>
                    <div className="form-group">
                      <input
                        className="form-control"
                        name="salutation"
                        value={props.signatureForm.salutation}
                        onChange={(event) => props.updateField(event)}
                        placeholder=""
                      />
                    </div>
                  </div>
                </div>

                <div className="row justify-content-end">
                  <div className={`${DeviceMode ? "col-md-9" : "col-12"}`}>
                    {name_msg.length > 0 && (
                      <div>
                        <small className="text-danger text-center">
                          {name_msg}
                        </small>
                      </div>
                    )}
                  </div>
                </div>

                <div className="row signature_label">
                  <div className={`${DeviceMode ? "col-md-3" : "col-12"}`}>
                    <div className={`${DeviceMode ? "form-group" : ""}`}>
                      <label className="textcolor1 signature_label_font">
                        {t("Name")}
                      </label>
                    </div>
                  </div>
                  <div className={`${DeviceMode ? "col-md-9" : "col-12"}`}>
                    <div className="form-group">
                      <input
                        className="form-control"
                        name="user_name"
                        value={props.signatureForm.user_name}
                        onChange={(event) => {
                          props.updateField(event);
                          handleValidations(event, "user_name");
                        }}
                        placeholder=""
                      />
                    </div>
                  </div>
                </div>

                <div className="row justify-content-end">
                  <div className={`${DeviceMode ? "col-md-9" : "col-12"}`}>
                    {job_title_msg.length > 0 && (
                      <small className="text-danger text-center">
                        {job_title_msg}
                      </small>
                    )}
                  </div>
                </div>
                <div className="row signature_label">
                  <div className={`${DeviceMode ? "col-md-3" : "col-12"}`}>
                    <div className={`${DeviceMode ? "form-group" : ""}`}>
                      <label className="textcolor1 signature_label_font">
                        {t("Job title")}
                      </label>
                    </div>
                  </div>
                  <div className={`${DeviceMode ? "col-md-9" : "col-12"}`}>
                    <div className="form-group">
                      <input
                        type="text"
                        name="job_title"
                        value={props.signatureForm.job_title}
                        onChange={(event) => {
                          props.updateField(event);
                          handleValidations(event, "job_title");
                        }}
                        className="form-control"
                        placeholder=""
                      />
                    </div>
                  </div>
                </div>

                <div className="row justify-content-end">
                  <div className={`${DeviceMode ? "col-md-9" : "col-12"}`}>
                    {number_msg.length > 0 && (
                      <small className="text-danger text-center">
                        {number_msg}
                      </small>
                    )}
                  </div>
                </div>
                <div className="row signature_label">
                  <div className={`${DeviceMode ? "col-md-3" : "col-12"}`}>
                    <div className={`${DeviceMode ? "form-group" : ""}`}>
                      <label className="textcolor1 signature_label_font">
                        {t("Mobile phone")}
                      </label>
                    </div>
                  </div>
                  <div className={`${DeviceMode ? "col-md-9" : "col-12"}`}>
                    <div className="form-group">
                      <PhoneInput
                        country={""}
                        onlyCountries={Countries}
                        value={props.signatureForm.mobile}
                        onChange={(phone, value, data) => {
                          data.persist();
                          let phonevalue = data.target != null ? data.target.value : phone
                          PhoneChange( phonevalue , "mobile");
                          NumberValidation(phonevalue, "mobile");
                        }}
                        ref={mobilenumber}
                        placeholder=""
                        inputProps={{
                          name: "mobile",
                          autoFocus: false,
                        }}
                        inputStyle={{
                          width: "100%",
                        }}
                      />
                    </div>
                  </div>
                </div>

                <div className="row signature_label">
                  <div className={`${DeviceMode ? "col-md-3" : "col-12"}`}>
                    <div className={`${DeviceMode ? "form-group" : ""}`}>
                      <label className="textcolor1 signature_label_font">
                        {t("Direct phone")}
                      </label>
                    </div>
                  </div>
                  <div className={`${DeviceMode ? "col-md-9" : "col-12"}`}>
                    <div className="form-group">
                      <PhoneInput
                        country={""}
                        onlyCountries={Countries}
                        value={props.signatureForm.direct_phone}
                        onChange={(phone, value, data) => {
                          data.persist();
                          let phonevalue = data.target != null ? data.target.value : phone
                          PhoneChange( phonevalue , "direct_phone");
                          NumberValidation(phonevalue, "direct_phone");
                        }}
                        ref={directnumber}
                        placeholder=""
                        inputProps={{
                          name: "direct_phone",
                          autoFocus: false,
                        }}
                        inputStyle={{
                          width: "100%",
                        }}
                      />
                    </div>
                  </div>
                </div>

                <div className="row signature_label">
                  <div className={`${DeviceMode ? "col-md-3" : "col-12"}`}>
                    <div className={`${DeviceMode ? "form-group" : ""}`}>
                      <label className="textcolor1 signature_label_font">
                        {t("Office phone")}
                      </label>
                    </div>
                  </div>
                  <div className={`${DeviceMode ? "col-md-9" : "col-12"}`}>
                    <div className="form-group">
                      <PhoneInput
                        country={""}
                        onlyCountries={Countries}
                        value={props.signatureForm.office_phone}
                        onChange={(phone, value, data) => {
                          data.persist();
                          let phonevalue = data.target != null ? data.target.value : phone
                          PhoneChange(phonevalue, "office_phone");
                          NumberValidation(phonevalue, "office_phone");
                        }}
                        ref={officenumber}
                        placeholder=""
                        inputProps={{
                          name: "office_phone",
                          autoFocus: false,
                        }}
                        inputStyle={{
                          width: "100%",
                        }}
                      />
                    </div>
                  </div>
                </div>

                <div className="row justify-content-end">
                  <div className={`${DeviceMode ? "col-md-9" : "col-12"}`}>
                    {email_msg.length > 0 && (
                      <small className="text-danger text-center">
                        {email_msg}
                      </small>
                    )}
                  </div>
                </div>
                <div className="row signature_label">
                  <div className={`${DeviceMode ? "col-md-3" : "col-12"}`}>
                    <div className={`${DeviceMode ? "form-group" : ""}`}>
                      <label className="textcolor1 signature_label_font">
                        {t("Email address")}
                      </label>
                    </div>
                  </div>
                  <div className={`${DeviceMode ? "col-md-9" : "col-12"}`}>
                    <div className="form-group">
                      <input
                        type="text"
                        name="email"
                        value={props.signatureForm.email}
                        onChange={(event) => {
                          props.updateField(event);
                          ValidateEmail(event);
                        }}
                        className="form-control"
                        placeholder=""
                      />
                    </div>
                  </div>
                </div>

                <div className="row signature_label">
                  <div className={`${DeviceMode ? "col-md-3" : "col-12"}`}>
                    <div className={`${DeviceMode ? "form-group" : ""}`}>
                      <label className="textcolor1 signature_label_font">
                        {t("Website")}
                      </label>
                    </div>
                  </div>
                  <div className={`${DeviceMode ? "col-md-9" : "col-12"}`}>
                    <div className="form-group">
                      <input
                        type="text"
                        className="form-control"
                        name="website"
                        placeholder=""
                        value={disabledFields.website}
                        onChange={(event) => props.updateField(event)}
                        disabled
                      />
                    </div>
                  </div>
                </div>

                <div className="row signature_label">
                  <div className={`${DeviceMode ? "col-md-3" : "col-12"}`}>
                    <div className={`${DeviceMode ? "form-group" : ""}`}>
                      <label className="textcolor1 signature_label_font">
                        {t("Bleckmann entity")}
                      </label>
                    </div>
                  </div>
                  <div className={`${DeviceMode ? "col-md-9" : "col-12"}`}>
                    <div className="form-group option_selector">
                      <Select
                        options={entity}
                        value={props.bleckmann_entity}
                        onChange={(e) =>
                          handleSelectChange(e, "bleckmann_entity")
                        }
                      />
                    </div>
                  </div>
                </div>
                {disabledFields.Bleckmann_entity != null ? (
                <div className="row justify-content-end">
                  <div className={`${DeviceMode ? "col-md-9" : "col-12"}`}>
                    <div className="form-group">
                      <input
                        type="text"
                        className="form-control"
                        name="Bleckmann_entity"
                        placeholder=""
                        value={disabledFields.Bleckmann_entity}
                        onChange={(event) => props.updateField(event)}
                        disabled
                      />
                    </div>
                  </div>
                </div>) : null }

                <div className="row signature_label">
                  <div className={`${DeviceMode ? "col-md-3" : "col-12"}`}>
                    <div className={`${DeviceMode ? "form-group" : ""}`}>
                      <label className="textcolor1 signature_label_font">
                        {t("Address")}
                      </label>
                    </div>
                  </div>
                  <div className={`${DeviceMode ? "col-md-9" : "col-12"}`}>
                    <div className="form-group option_selector">
                      <Select
                        options={addresses}
                        value={props.bleckmann_address}
                        onChange={(e) =>
                          handleSelectChange(e, "bleckmann_address")
                        }
                      />
                    </div>
                  </div>
                </div>

                {DisclaimerStatus && DisclaimerStatus ? (
                  <div className="row signature_label">
                    <div className={`${DeviceMode ? "col-md-3" : "col-12"}`}>
                      <div className={`${DeviceMode ? "form-group" : ""}`}>
                        <label className="textcolor1 signature_label_font">
                          {t("Disclaimer")}
                        </label>
                      </div>
                    </div>
                    <div className={`${DeviceMode ? "col-md-9" : "col-12"}`}>
                      <div className="form-group">
                        <div
                          className="Richtexterditor disclaimer_class"
                          dangerouslySetInnerHTML={createMarkup()}
                        />
                      </div>
                    </div>
                  </div>
                ) : null}

                <div className="row justify-content-end">
                  <div className={`${DeviceMode ? "col-md-9" : "col-12"}`}>
                    <div className="form-group">
                      <div className="col-md-12 col-12 pl-0 px-0 text-center votePopup mt-3 mb-4">
                        <button
                          type="submit"
                          className="btn m-0 rateSave bg-color font-weight-bold w-100"
                        >
                          {t("Generate Signature")}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SignatureNewMail;
