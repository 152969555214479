import React, { useState, useEffect } from 'react';
import { Comments } from "../../../Services/ApiEndPoints";
import { APICALL } from "../../../Services/ApiServices";
import CommentList from './CommentList';
import CommentItem from './CommentitemDetails';
import {useHistory} from 'react-router-dom';
import { t } from '../../../Translation/Translation'
import Popup from "../../../Utilities/Popup/modelPopup";

function CommentDetails({updatesEntityId,backButtons}) {
    const [comments, setcomments] = useState()
    // eslint-disable-next-line no-unused-vars
    const history = useHistory();
    const get_id = localStorage.getItem("id");
    const [message, setMessage] = useState("")

    const goBackButton = () => {
      backButtons(true)
    }
        
    useEffect(() => {  
        const data = {
            'user_id': get_id,
            "type": "view",
            "entry_id": updatesEntityId
        }
        //feteching all comments list for partculat EntityId
        APICALL.service(Comments, "POST",data)
        .then(result => {
            var dataList = []
          if(result) {
            Object.keys(result).map((value, i) => ( 
                dataList.push(result[value])
            ))
              setcomments(dataList);
          }
          
        })
        .catch(error => {
          console.error("Comments error : ", error);
        })
      // eslint-disable-next-line react-hooks/exhaustive-deps
      },[]);
    //to send in the API for backend
      const updateField = value => {
       const get_id = localStorage.getItem("id");
       const data = {
         'user_id': get_id,
         "type": "create",
         "entry_id": updatesEntityId,
         "comment": value.comment,
         "tagged_user" : value.tagged_id ? value.tagged_id : null,
         "comment_date" : new Date().toISOString(),
       } 
       APICALL.service(Comments, "POST", data)
         .then(result => {
           if (result.data === "success") {
            let data = {
              'user_id': get_id,
              "type": "view",
              "entry_id": updatesEntityId
          }
          //feteching comments list after submit the commnents
            APICALL.service(Comments, "POST",data)
                .then(result => {
            var dataList = []
          if(result) {
            Object.keys(result).map((value, i) => ( 
                dataList.push(result[value])
            ))
              setcomments(dataList);
          }
          
        });
           }
          })
          .catch(error => {
            setMessage(t("Error posting comments please try again"));
          })
          
        }

        const closeAlertPopup = () => {
          setMessage("");
        }
    return (
        <div className ="container customContainer mobileSpace">
        <div className="col-md-12 topsection-wrap  mt-0 mb-4 px-0 pt-4">
           <p className="browserback" onClick={goBackButton}>back</p>  
           <h1 className="pagetitle textcolor1 text-center">{t('Comments')}</h1>
         </div>
        <Popup
          show={message !== "" ? true : false}
          onHide={closeAlertPopup}
          size={"lg"}
          title={"Alert"}
          body={message}
        />
      {  comments !== undefined && comments.status !== '404' && 
      Object.keys(comments).reverse().map((value, i) => {
         return (
             <CommentItem comment={comments[value]} key ={i} />
         );
         
       })}
          <CommentList updateField = {updateField} types ={'details'}/>
          </div>
        
    )
}

export default CommentDetails
