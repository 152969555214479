import React, { useState, useEffect } from "react";
import BigScheduleCalendar from "../../Utilities/BigCalendar/BigScheduleCalendar";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
// import WeekCalendar from "../../Utilities/BigCalendar/WeekCalendar";
import MobileCalendar from "../../Utilities/BigCalendar/MobileCalendar"
import FloatEvent from "../../Static/images/FloatEvent.png";
import "./meetingrooms.css";
import MeetingRoomPopup from "../../Utilities/Popup/MeetingRoomPopup";
import { APICALL } from "../../Services/ApiServices";
import { MeetingsList } from "../../Services/ApiEndPoints";
import moment from "moment";
import { useHistory } from "react-router-dom";
import BackButtonComponent from "../../Utilities/BackButtonComponent";
import BackButton from "../../Utilities/BackButton";
import { t } from "../../Translation/Translation";

function MeetingDetails({
  updatesEntityId,
  dataLength,
  IdList,
  updateDataforID,
  backButton,
}) {
  const [statusupdate, setstatusupdate] = useState(false);
  const [startDate, setStartDate] = useState(new Date());
  const [calendarToggle, setcalendarToggle] = useState(false);
  const [popupStatus, setpopupStatus] = useState(false);
  const [popupData, setpopupData] = useState({
    type: 1,
    title: "",
    entryId: 0,
    errorstatus: 0,
    date: startDate,
    attendees: [],
    location: "",
    start_time: "",
    buttonStatus: 1,
    end_time: "",
    subject: "Meeting by" + " " + localStorage.getItem("firstname"),
    button1: "",
    button2: "",
    timeList: [],
    usersList:[]
  });
  const [schedulerData, setSchedulerData] = useState();
  const [arrowVisibility, setarrowVisibility] = useState(false);
  const [leftArrowState, setleftArrowState] = useState(false);
  const [rightArrowState, setrightArrowState] = useState(false);
  const [urlState, seturlState] = useState(false);
  const [backbuttonUrl, setbackbuttonUrl] = useState();
  const [pageTitles, setPageTitles] = useState({
    location: "",
    room: "",
  });
  const weekday = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];

  const history = useHistory();
  const [timeList, setTimeList] = useState([]);


  useEffect(() => {
    if (urlState === false && updatesEntityId !== undefined) {
      setbackbuttonUrl("/meetingrooms/" + updatesEntityId);
      seturlState(true);
    }
  }, [updatesEntityId]);

  useEffect(() => {
    if (IdList !== undefined) {
      IdList[0] !== updatesEntityId
        ? setleftArrowState(true)
        : setleftArrowState(false);
      IdList[dataLength - 1] !== updatesEntityId
        ? setrightArrowState(true)
        : setrightArrowState(false);
    }
  }, [updatesEntityId, IdList, dataLength]);

  // function FetchtimeZone(date) {
  //   const dateAsString = date.toString();
  //   return dateAsString.match(/\(([^\)]+)\)$/)[1];
  // }

  useEffect(() => {
    if (dataLength > 1) {
      setarrowVisibility(true);
    }
    const get_id = localStorage.getItem("id");

    let data = {
      flow: "schedule",
      roomid: updatesEntityId,
      // date: moment(startDate).format("YYYY-MM-DD"),
      date: {
        "date": moment(startDate).format("YYYY-MM-DD"),
        // "timeZone": FetchtimeZone(startDate)
        "timeZone": Intl.DateTimeFormat().resolvedOptions().timeZone
      }
    };
    let schedulerResponseData = [];
    APICALL.service(MeetingsList + "/" + get_id, "PUT", data).then((result) => {
      try {
        setPageTitles({
          ...pageTitles,
          location: result.location_name,
          room: result.room_name,
        });
        setTimeList([])
        if (result.status === 200) {
          let meetings = result.schedules;
          Object.keys(result.schedules).map((value, i) =>
            schedulerResponseData.push(getDateAndTime(meetings[value], startDate))
          );
          setSchedulerData(schedulerResponseData);
        } else if (result.status === 201) {
          setSchedulerData([]);
        }
      } catch (e) {
        console.error(e);
      }
    });
  }, [updatesEntityId, startDate, statusupdate]);

  function getDateAndTime(meetings, date) {

    let result = [];
    result = {
      id: meetings.id,
      title: meetings.title,
      roomid: meetings.roomid,
      attendees: meetings.attendees,
      host: meetings.host,
      hexColor: meetings.hexColor,
    }

    let choosendate = new Date(date).toDateString();
    let startDate = new Date(meetings.start).toDateString();
    let endDate = new Date(meetings.end).toDateString();
    let starttime = "00:00:00";
    let endTime = "23:59:59";
    if (startDate < choosendate && endDate > choosendate) {

      result.start = new Date(startDate + ' ' + starttime);
      result.end = new Date(endDate + ' ' + endTime);

      // } else if (startDate < choosendate && endDate === choosendate) {
    } else if ((new Date(startDate) < new Date(choosendate)) && endDate === choosendate) {
      result.start = new Date(choosendate + ' ' + starttime);
      result.end = new Date(meetings.end);
      // } else if (startDate === choosendate && endDate > choosendate) {
    } else if (startDate === choosendate && (new Date(endDate) > new Date(choosendate))) {
      result.start = new Date(meetings.start);
      result.end = new Date(choosendate + ' ' + endTime);
    }
    else if (startDate === choosendate && endDate === choosendate) {
      result.start = new Date(meetings.start);
      result.end = new Date(meetings.end);
    }
    else {
      result = [];
    }


    if (result) {
      let newArray = {
        start: moment(result.start).format("HH:mm"),
        end: moment(result.end).format("HH:mm")
      };
      setTimeList(oldArray => [...oldArray, newArray]);
      return result;
    }
  }

  const showDetailsHandle = (date, status) => {
    if (status === "ok") setStartDate(date);
    setcalendarToggle(false);
  };

  const ValidateTime = (e) => {
    const current = new Date();
    const current_date = current.toLocaleDateString('en-GB')
    var selected_date = startDate.toLocaleDateString('en-GB')
    var current_time = current.getHours() * 60 + current.getMinutes() * 1
    let start_time = e.start.toLocaleTimeString('en-GB', { timeStyle: "short" }).split(':');
    var time = start_time[0] * 60 + start_time[1] * 1
    if (current_date > selected_date) {
      return false
    } else if (current_date === selected_date && current_time > time) {
      return false
    } else if (e.end.toLocaleTimeString('en-GB', { timeStyle: "short" }) === '00:00') {
      return false
    } else {
      return true
    }
  }

  function onSlotselect(e) {
    console.log(e);
    let timeData = ValidateTime(e)
    if (timeData === true) {
      setpopupData({
        ...popupData,
        type: 1,
        errorstatus: 0,
        title: t("New meeting"),
        date: e.start,
        location: pageTitles.location,
        attendees: [],
        entryId: 0,
        buttonStatus: 1,
        roomid: updatesEntityId.toString(),
        subject: "Meeting by" + " " + localStorage.getItem('firstname'),
        start_time: e.start,
        end_time: e.end,
        button1: t("Cancel"),
        button2: t("Book"),
        timeList: timeList,
        usersList:[]
      });
      setpopupStatus(true);
    }
  }

  function onEventselect(e) {
    console.log(e);
    let get_id = localStorage.getItem('id')
    let timeData = ValidateTime(e)
    let subject = "Meeting by" + " " + localStorage.getItem('firstname')
    let attendees = []
    let usersList = []
    if (e.id !== 0) {
      let buttonStatus = 1
      if (e.title){subject = e.title}
      if (e.host.id.toString() === get_id && timeData === true) {
        if (e.attendees !== []) {
          let users = e.attendees
          Object.keys(users).map((value, i) =>{
            users[value].Email['id'] !== undefined  ?
                usersList.push(users[value].Email['id'])  :
                usersList.push(users[value].Email);
            attendees.push(users[value].Email)
          });
        }
        setpopupData({
          ...popupData,
          type: 2,
          errorstatus: 0,
          title: "Meeting",
          date: e.start,
          entryId: e.id,
          buttonStatus: buttonStatus,
          attendees: attendees,
          location: pageTitles.location,
          roomid: updatesEntityId.toString(),
          subject: subject,
          start_time: e.start,
          end_time: e.end,
          button1: t("Delete"),
          button2: t("Update"),
          timeList: timeList,
          usersList:usersList
        });
        setpopupStatus(true);
      }
    }
  }

  const CreateMeeting = (data, type) => {
    let title = t("Booking successful")
    let deletestatus = 0;
    if (type === 2) { title = t("Updated") } else if (type === 3) { title = t("Deleted"); deletestatus = 1 }
    APICALL.service(MeetingsList, "POST", data).then((result) => {
      console.log(result);
      try {
        if (result.status === 200) {
          pageUpdate()
          if (type === 3) {
            setpopupData({
              ...popupData,
              type: 0,
              errorstatus: 0,
              deletestatus: deletestatus,
              title: title
            })
          } else {
            setpopupData({
              ...popupData,
              type: 0,
              errorstatus: 0,
              deletestatus: deletestatus,
              title: title,
              subject: data.subject,
              location: pageTitles.location,
              start_time: new Date(data.start['dateTime']),
              end_time: new Date(data.end['dateTime']),
            });
          }
          setpopupStatus(true);
        }
        else if (result.status === 400) {
          pageUpdate()
          setpopupData({
            ...popupData,
            type: 0,
            errorstatus: 1,
            deletestatus: 1,
            title: result.message
          });
          setpopupStatus(true);
        }
        else {
          pageUpdate()
          setpopupData({
            ...popupData,
            type: 0,
            errorstatus: 1,
            deletestatus: 1,
            title: t('Error while creating meeting')
          });
          setpopupStatus(true);
        }
      } catch (e) {
        console.log(e)
        pageUpdate()
        setpopupData({
          ...popupData,
          type: 0,
          errorstatus: 1,
          deletestatus: 1,
          title: t('Something went wrong, please try again')
        });
        setpopupStatus(true);
      }
    })
  }

  function pageUpdate() {
    setstatusupdate(statusupdate ? false : true)
  }

  function dataSwitcher(id, type) {
    if (type === "next") {
      IdList.map((value, i) => {
        if (value === id) {
          return updateDataforID(IdList[i + 1]);
        }
      });
    } else if (type === "prev") {
      IdList.map((value, i) => {
        if (value === id) {
          return updateDataforID(IdList[i - 1]);
        }
      });
    }
  }

  // back button funtionality
  function historyBack() {
    let response = BackButton("/meetingrooms");
    if (response === false) {
      backButton(false);
      history.push("/meetingrooms");
    } else {
      history.push(response);
    }
  }


  return (
    <div className="container schedular-detail-page">
      <div className="my-4 room-details">
        <div className="row ">
          {localStorage.getItem("DeviceMode") !== "Desktop" ? (
            <>
              <div className="col-md-12 topsection-wrap  mt-0 ">
                {" "}
                <p className="browserback top_wrap" onClick={historyBack}>
                  back
                </p>
                {backbuttonUrl && (
                  <BackButtonComponent backButtonLink={backbuttonUrl} />
                )}
                <h1 className="pagetitle textcolor1 text-center">
                  {t("Meeting Room")}
                </h1>
              </div>
            </>
          ) : null}
        </div>

        <h5 className="text-center meeting-location">{pageTitles.location}</h5>
        <div className="row">
          <div className="col-1">
            {leftArrowState && arrowVisibility ? (
              <ArrowBackIosIcon
                className="float-left customCursor"
                onClick={() => dataSwitcher(updatesEntityId, "prev")}
              />
            ) : null}
          </div>
          <div className="col-10 mobile-responsive">
            <h6 className="text-center textcolor1 meeting-room">{pageTitles.room}</h6>
          </div>
          <div className="col-1">
            {rightArrowState && arrowVisibility ? (
              <ArrowForwardIosIcon
                className="float-right customCursor"
                onClick={() => dataSwitcher(updatesEntityId, "next")}
              />
            ) : null}
          </div>
        </div>
      </div>
      <MeetingRoomPopup
        show={popupStatus}
        type={popupData.type}
        errorstatus={popupData.errorstatus}
        pageUpdate={pageUpdate}
        onSuccess={(e, type) => CreateMeeting(e, type)}
        onHide={() => setpopupStatus(false)}
        title={popupData.title}
        location={popupData.location}
        date={startDate}
        buttonStatus={popupData.buttonStatus}
        deletestatus={popupData.deletestatus}
        attendees={popupData.attendees}
        roomid={popupData.roomid}
        entryId={popupData.entryId}
        start_time={popupData.start_time}
        end_time={popupData.end_time}
        subject={popupData.subject}
        button1={popupData.button1}
        button2={popupData.button2}
        timeList={popupData.timeList}
        usersList = {popupData.usersList}
      />

      <div className="row">
        <div className="col-6 textcolor1 meeting-date">
          <div className = "font-weight-bold">{startDate.toLocaleDateString('en-GB')}</div>
          {/* <br></br> */}
          <div>{startDate.toLocaleDateString('en-GB') === new Date().toLocaleDateString('en-GB')
            ? "Today"
            : weekday[startDate.getDay()]}</div>
        </div>
        <div className="col-6 justify-content-right">
          <button
            className="calender-button float-right bg-color mr-0"
            onClick={() => setcalendarToggle(calendarToggle ? false : true)}
          >
            <img
              className="footericons  mr-1 customCursor"
              src={FloatEvent}
              alt="meeting icon"
            />{" "}
            Change date{" "}
          </button>
        </div>
      </div>

      <div
        className={`${calendarToggle
          ? "card bg-white overlay-wrapper shadow"
          : "hide-class"
          }`}
      >
        {/* <WeekCalendar showDetailsHandle={showDetailsHandle} /> */}
        <MobileCalendar showDetailsHandle={showDetailsHandle} defaultDate={startDate} />
      </div>
      <div className="schedular-container">
        {schedulerData && (
          <BigScheduleCalendar
            date={startDate}
            onSlotselect={(e) => onSlotselect(e)}
            onEventselect={(e) => onEventselect(e)}
            schedulerData={schedulerData}
            format={"DD/MM/YYYY HH:mm"}
            longPressThreshold={1}
          />
        )}
      </div>
    </div>
  );
}

export default MeetingDetails;
