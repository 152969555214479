import React, { useState, useEffect } from "react";
import "./popupStyle.css";
import { Modal, Button } from "react-bootstrap";
import { t } from "../../Translation/Translation";
import "react-datepicker/dist/react-datepicker.css";
import { APICALL } from "../../Services/ApiServices";
import { Common } from "../../Services/ApiEndPoints";
import CloseIcon from "../../Static/images/CloseIcon.svg";
import '../../Pages/Footer/footer.css'
import Select from "react-select";

const FilterPopUp = (props) => {

  const [filterValues, setFilterValues] = useState({
    refresh : false
    , locationIds : props.locationIds
    , location : []
    , oldLocations : []
    , oldSelectedLocations : []
    , selectedLocation : []
    , locationList : []

    , countryIds : props.countryIds
    , country : []
    , oldCountry : []
    , oldSelectedCountry : []
    , selectedCountry : []
    , countryList : []

    , capacity: props.capacityValue
    , capacityValue : props.capacityValue
    , capacityError : ''
    , oldCapacityValue : []

    , countryLunchTipsIds : props.countryLunchTipsIds
    , countryLunchTips : []
    , oldcountryLunchTips : []
    , oldSelectedcountryLunchTips : []
    , selectedcountryLunchTips : []
    , countryLunchTipsList : []

    ,filterLocationList : []
    ,listFlag : false
  })

  useEffect(() => {
    let requestData = {
      user_id : localStorage.getItem("id"),
    }

    /** requesting data according to necessity */
    if(props.location){
      requestData['location'] = true;
    }
    if(props.country){
      requestData['country'] = true;
    }

    if(props.countryLunchtips){
      requestData['lunchtipsCountry'] = true;
    }
  
    APICALL.service(Common, "POST", requestData).then((result) => {
      if (result !== undefined && result !== "" && result !== null) {

        let locations = [];
        let selectedLocation = [];
        // if((props.stateLocation == '' || props.stateLocation == undefined) && props.location === true){
        if(props.location === true){
          Object.keys(result.location).map((value, i) =>{
              if ((props.locationIds).includes(value)) {
                selectedLocation.push({ value: value, label: result.location[value] })
              } else {
                locations.push({ value: value, label: result.location[value] })
              }
            }
          )
          if(props.stateLocation != '' || props.stateLocation != undefined){
            selectedLocation = locations.filter(item => item.label == props.stateLocation)
            locations = locations.filter(item => item.label !== props.stateLocation)
          }
      }else{
          selectedLocation.push({ value: result.location[props.stateLocation] , label: props.stateLocation })
        }

        let countries = [];
        let selectedCountry = [];

        if(props.country === true){
          Object.keys(result.country).map((value, i) =>{
              if ((props.countryIds).includes(value)) {
                selectedCountry.push({ value: value, label: result.country[value] })
              } else {
                countries.push({ value: value, label: result.country[value] })
              }
            }
          )
        }

        let countryLunchTips = [];
        let selectedcountryLunchTips = [];
        if(props.countryLunchtips === true){
          Object.keys(result.lunchtipsCountry).map((value, i) =>{
              if ((props.countryLunchTipsIds).includes(value)) {
                selectedcountryLunchTips.push({ value: value, label: result.lunchtipsCountry[value] })
              } else {
                countryLunchTips.push({ value: value, label: result.lunchtipsCountry[value] })
              }
            }
          )
        }
     
        //updating the state values according to necessity

        setFilterValues({
          ...filterValues, 
          location : sortArray(locations),
          selectedLocation: selectedLocation,
          oldLocations : sortArray(locations),
          oldSelectedLocations : [],
          locationList : sortArray(locations),

          country : sortArray(countries),
          selectedCountry: selectedCountry,
          oldCountry : sortArray(countries),
          oldSelectedCountry : [],
          countryList : sortArray(countries),

          countryLunchTips : sortArray(countryLunchTips),
          selectedcountryLunchTips: selectedcountryLunchTips,
          oldcountryLunchTips : sortArray(countryLunchTips),
          oldSelectedcountryLunchTips : [],
          countryLunchTipsList : sortArray(countryLunchTips)

        })
      }
    });
  }, []);

  useEffect(() => {
    if(props.clearFilterValues === true){
      //reset all the state values on clear filter
    setTimeout(() => {

      setFilterValues({
        ...filterValues, 
        country: JSON.parse(JSON.stringify((filterValues.countryList))),
        location: JSON.parse(JSON.stringify((filterValues.locationList))),
        countryLunchTips: JSON.parse(JSON.stringify((filterValues.countryLunchTipsList))),

        selectedLocation: [],
        selectedCountry: [],
        selectedcountryLunchTips: [],

        capacityValue:'',
        oldCapacityValue: '',

        oldSelectedLocations: [],
        oldSelectedCountry:[],
        oldSelectedcountryLunchTips: [],
        
        oldCountry: JSON.parse(JSON.stringify((filterValues.countryList))),
        oldLocations: JSON.parse(JSON.stringify((filterValues.locationList))),
        oldcountryLunchTips: JSON.parse(JSON.stringify((filterValues.countryLunchTipsList))),
      })
    }, 2000);
  }

  }, [props.clearFilterValues]);
 
/** function to sort the given array */
  const sortArray = (arrayData) =>{
    if(arrayData != []){
      arrayData.sort(function(a, b) {
        var valueA = a.label.toUpperCase(); // ignore upper and lowercase
        var valueB = b.label.toUpperCase(); // ignore upper and lowercase
        if (valueA < valueB) {
          return -1;
        }
        if (valueA > valueB) {
          return 1;
        }
        return 0;
      });
    }
    return arrayData;
  }

  /** function to validate whether the given input is number or not */
  const CheckValidations = (e) => {
    if(!(/(^[0-9]+$|^$)/.test(e.target.value))){
      setFilterValues({
        ...filterValues,
       capacityError :  "Please enter valid number",
       capacityValue :  e.target.value
      })
    }else{
      setFilterValues({
        ...filterValues,
       capacityError :  "",
       capacityValue :  e.target.value
      })
    }
  
  }

  /** on cancel update back the state values */
  const handleHide = () => {

   setFilterValues({
      ...filterValues, 
      location : JSON.parse(JSON.stringify((filterValues.oldLocations))),
      selectedLocation: JSON.parse(JSON.stringify((filterValues.oldSelectedLocations))),
      country : JSON.parse(JSON.stringify((filterValues.oldCountry))),
      selectedCountry: JSON.parse(JSON.stringify((filterValues.oldSelectedCountry))),
      countryLunchTips : JSON.parse(JSON.stringify((filterValues.oldcountryLunchTips))),
      selectedcountryLunchTips: JSON.parse(JSON.stringify((filterValues.oldSelectedcountryLunchTips))),
      capacityValue : filterValues.oldCapacityValue
    })    
    props.onHide();
  }

  const handleFilter = () => {
    let data = [];
    let flag = false;

    setFilterValues({
      ...filterValues, 
      oldSelectedLocations : JSON.parse(JSON.stringify((filterValues.selectedLocation))),
      oldLocations: JSON.parse(JSON.stringify((filterValues.location))),

      oldCapacityValue : filterValues.capacityValue,

      oldSelectedCountry : JSON.parse(JSON.stringify((filterValues.selectedCountry))),
      oldCountry: JSON.parse(JSON.stringify((filterValues.country))),

      oldSelectedcountryLunchTips : JSON.parse(JSON.stringify((filterValues.selectedcountryLunchTips))),
      oldcountryLunchTips: JSON.parse(JSON.stringify((filterValues.countryLunchTips)))
    })

    data['country'] = [];
    if((filterValues.selectedCountry).length > 0){
      flag = true;
      Object.values(filterValues.selectedCountry).map((value, i) =>
          data['country'].push(value.value)
        )
    }
    data['location'] = [];
    if((filterValues.selectedLocation).length > 0){
      flag = true;
      Object.values(filterValues.selectedLocation).map((value, i) =>
          data['location'].push(value.value)
        )
    }

    data['countryLunchTips'] = [];
    if((filterValues.selectedcountryLunchTips).length > 0){
      flag = true;
      Object.values(filterValues.selectedcountryLunchTips).map((value, i) =>
          data['countryLunchTips'].push(value.value)
        )
    }

    data['capacity'] = null;
    if(filterValues.capacityValue != null){
      flag = true;
      data['capacity'] = filterValues.capacityValue
    }
    flag === false ? props.handleFilter([]) : props.handleFilter(data);
  }

  useEffect(() => {
    if(filterValues.listFlag === true){ 
      let requestData = {user_id : localStorage.getItem("id") }
      if ((filterValues.filterLocationList).length > 0) {
        requestData.countryids = filterValues.filterLocationList
      }else{
        requestData.location = true
      }
      fetchData(requestData)
    }    
  }, [filterValues.refresh]);


  const handleAddItem = async(e, type, selectedType) => {
    let selectedItems = filterValues[selectedType]
    let locList = filterValues.filterLocationList;
    let flag = false;
    selectedItems.push(e);
    if (type == 'countryLunchTips' || type == 'country') {
      locList.push(e.value)
      flag = true;
    }

      let allType = (filterValues[type]).filter(function(el) { return el.value != e.value; });
      setFilterValues({
        ...filterValues,
        [selectedType]: selectedItems,
        [type]: allType,
        'filterLocationList':locList,
        'listFlag' : flag,
        refresh: !filterValues.refresh,
      })
  }

  const handleRemoveItem = (value, label, type, selectedType) => {
    let filtered = (filterValues[selectedType]).filter(function(el) { return el.value != value; }); 
    let allList = filterValues[type];
    allList.push({ value: value, label: label })
    allList = sortArray(allList);
    let locList = filterValues.filterLocationList;
    let flag = false;
    if (type == 'countryLunchTips' || type == 'country') {
      locList = locList.filter(item => item !== value)
      flag = true;
    }

    setFilterValues({
      ...filterValues,
      [selectedType] : filtered,
      [type]: allList,
      'filterLocationList':locList,
      'listFlag' : flag,
      refresh: !filterValues.refresh,
    })
  }

  const customStyles = {
    control: (styles, state) => ({
      ...styles,
      boxShadow: state.isFocused ? "0 0 0 1px #d20c14" : 0,
      borderColor: state.isFocused ? "#D0EAE2" : "#CED4DA",
      "&:hover": {
        borderColor: state.isFocused ? "#D0EAE2" : "#CED4DA"
      }
      }),
  };

  const fetchData = (requestData) => {
    APICALL.service(Common, "POST", requestData).then((result) => {
      if (result !== undefined && result !== "" && result !== null) {
        console.log(result);
        let locations = [];
        let selectedLocation = [];
        Object.keys(result.location).map((value, i) =>{
            if ((props.locationIds).includes(value)) {
              selectedLocation.push({ value: value, label: result.location[value] })
            } else {
              locations.push({ value: value, label: result.location[value] })
            }
          }
        )

        setFilterValues({
          ...filterValues,
          location : sortArray(locations),
          selectedLocation: selectedLocation
        })
      } 
    });
  }

  return (
    <Modal
      className="filter-popup"
      show={props.show}
      onHide={handleHide}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      dialogClassName={
        props.type !== 0
          ? localStorage.getItem("DeviceMode") === "Desktop"
            ? "popup-place1"
            : "popup-place-mob"
          : localStorage.getItem("DeviceMode") === "Desktop"
          ? "message-popup-place"
          : "message-popup-mob"
      }
    >
      <Modal.Header className="d-inline align-items-center border-0">
        <Modal.Title id="" className="textcolor1 text-center">
          {t("Filter")}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
          <form className="filter-popups">
            {props.country === true && (<>
              <div className="col-12 row m-0 my-1 form-group popup-input" >
                <label className="col-6 col-md-4 row m-0 pl-0 float-left">{t("Country")}:</label>
                <div
                  className="col-md-8 col-xs-12 float-right border-top-0 border-left-0 border-right-0"
                  id="filter-multiselect"
                >
                  <Select
                    defaultValue={{ value: "", label: "Select Country" }} 
                    value={""}
                    onChange={(e) => handleAddItem(e, 'country', 'selectedCountry')}
                    options={filterValues.country}
                    styles={customStyles}            
                  />
                </div>
              </div>
               {filterValues.selectedCountry.length !== 0 && 
                  <div className="addscroll col-md-4 col-sm-11 row m-0 mb-1 col-md-7 col-xs-10 float-right">
                    {filterValues.selectedCountry.map((input, index) => 
                      <span className="row border px-1 m-1 mb-1 user-selected selectedItem" key={index}>
                        {input.label} 
                        <img src={CloseIcon} onClick={() => handleRemoveItem(input.value, input.label, 'country', 'selectedCountry')} alt="close_icon" className="closeIcon-width ml-2 Fcloseicon"></img>
                      </span>
                    )}
                  </div>
                }
            </>)}

            {props.countryLunchtips === true && (<>
              <div className="col-12 row m-0 my-1 form-group popup-input" >
                <label className="col-6 col-md-4 row m-0 pl-0 float-left">{t("Country")}:</label>
                <div
                  className="col-md-8 col-xs-12 float-right border-top-0 border-left-0 border-right-0"
                  id="filter-multiselect"
                >
                  <Select
                    defaultValue={{ value: "", label: "Select Country" }} 
                    value={""}
                    onChange={(e) => handleAddItem(e, 'countryLunchTips', 'selectedcountryLunchTips')}
                    options={filterValues.countryLunchTips}
                    styles={customStyles}            
                  />
                </div>
              </div>
               {filterValues.selectedcountryLunchTips.length !== 0 && 
                  <div className="addscroll col-md-4 col-sm-11 row m-0 mb-1 col-md-7 col-xs-10 float-right">
                    {filterValues.selectedcountryLunchTips.map((input, index) => 
                      <span className="row border px-1 m-1 mb-1 user-selected selectedItem" key={index}>
                        {input.label} 
                        <img src={CloseIcon} onClick={() => handleRemoveItem(input.value, input.label, 'countryLunchTips', 'selectedcountryLunchTips')} alt="close_icon" className="closeIcon-width ml-2 Fcloseicon"></img>
                      </span>
                    )}
                  </div>
                }
            </>)}
            
            {props.location === true && (<>
              <div className="col-12 row m-0 my-1 form-group popup-input" >
                <label className="col-6 col-md-4 row m-0 pl-0 float-left">{t("Location")}:</label>
                <div
                  className="col-md-8 col-xs-12 float-right border-top-0 border-left-0 border-right-0"
                  id="filter-multiselect"
                >
                  <Select
                    defaultValue={{ value: "", label: "Select Location" }} 
                    value={""}
                    className={"testingDataMeetingRoom"}
                    onChange={(e) => handleAddItem(e, 'location', 'selectedLocation')}
                    options={filterValues.location} 
                    styles={customStyles}       
                  />
                </div>
              </div>
               {filterValues.selectedLocation.length !== 0 && 
                  <div className="addscroll col-md-4 col-sm-11 row m-0 mb-1 col-md-7 col-xs-10 float-right">
                    {filterValues.selectedLocation.map((input, index) => 
                      <span className="row border px-1 m-1 mb-1 user-selected selectedItem" key={index}>
                        {input.label} 
                        {/* {(props.stateLocation === '' || props.stateLocation === null || props.stateLocation === undefined) && ( */}
                        <img src={CloseIcon} onClick={() => handleRemoveItem(input.value, input.label, 'location', 'selectedLocation')} alt="close_icon" className="closeIcon-width ml-2 Fcloseicon"></img>
                        {/* )} */}
                      </span>
                    )}
                  </div>
                }
            </>)}

               {filterValues.capacityError.length > 0 &&
                  <small className="col-6 col-md-8 float-right text-danger text-left">{filterValues.capacityError}</small>
               }
               {props.capacity === true && ( 
                <div className="col-12 row m-0 my-1 form-group popup-input" >
                  <label className="col-6 col-md-4 row m-0 pl-0 float-left">{t("Capacity")}:</label>
                  <input type="text" value={filterValues.capacityValue }  onChange={(e) => CheckValidations(e)} className="capacity-value col-md-7 col-xs-12 float-left border-top-0 border-left-0 border-right-0 border-bottom range form-control capacity_margin" />
                </div>
               )}
          </form>
        </Modal.Body>
        <Modal.Footer className="border-0 justify-content-center">
          <Button className="bg-secondary border border-secondary rounded" onClick={handleHide}>
            {t("Cancel")}
          </Button>
          <Button className="bg-color popupborder rounded" onClick={handleFilter}>
            {t("Filter")}
          </Button>
        </Modal.Footer>
      
    </Modal>
  );
};

export default FilterPopUp;
