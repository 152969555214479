import React, { useState, useEffect } from "react";
import { Player, BigPlayButton, ControlBar } from "video-react";
import "../../..//node_modules/video-react/dist/video-react.css"; // import css
import PDFViewer from "../../Utilities/PDFViewer/PDFViewer";
import { APICALL } from "../../Services/ApiServices";

function LibraryDetailsView(data1) {
  let data = data1.data;
  const [fileType, setfileType] = useState("");

  useEffect(() => {
	  console.log(APICALL.proxy_url);
    var file_type = APICALL.get_url_extension(data);
    var videos = ["avi", "mpeg", "mp4", "mpg"];
    if (file_type === "pdf") {
      setfileType("pdf");
    } else if (videos.includes(file_type.toLowerCase())) {
      setfileType("video");
    } else {
      setfileType("image");
    }
  }, [data1]);

  const renderVideo = () => {
    return (
      <Player playsInline autoPlay src={data}>
        <BigPlayButton position="center" />
        <ControlBar autoHide={false} className="my-class" />
      </Player>
    );
  };
  
  return (
    <>
      {fileType === "pdf" && (
        <div className="col-12 col-md-12 pl-0">
          <PDFViewer pdf={APICALL.proxy_url + data} type={2}/>
          {/* <PDFViewer pdf={data.slice(prefix_url.length)} type={2} /> */}
        </div>
      )}
      {fileType === "image" && (
        <div className="col-12 p-0 pt-4">
          <img src={data} width="100%" height="auto" alt="libraryimage"/>
        </div>
      )}
      {fileType === "video" && (
        <div className="col-12 p-0 pt-4">{renderVideo()}</div>
      )}
    </>
  );
}
export default LibraryDetailsView;
