import React, { useState, useRef } from "react";
import ReactDOM from "react-dom";
import { Piece } from "avataaars";
import Avatar from "avataaars";
import map from "lodash/map";
// import FileSaver from "file-saver";
import options from "./options";
import {
  Button,
  DownloadRow,
  Tabs,
  Tabpanes,
  ColorContainer,
  Container,
  StyledAvatar,
  Pieces,
  Color,
  None,
  Tab,
  Tabpane,
  AppTabs,
  AppTab
} from "./style";
// import { DownloadIcon } from "./svg";
import { t } from '../../Translation/Translation';

import './avatar.css';

export default function Avataaar(props) {

  const canvasRef = useRef(null);
  const avatarRef = useRef(null);
  const [selectedTab, setSelectedTab] = useState("top");
  const imageresult = new FormData();
  const [avtaarsImages,setavtaarsImages] = useState(false)
  const pieceClicked = (attr, val) => {
    var newAttributes = {
      ...props.value,
      [attr]: val,
    };
    if (props.onChange) {
      props.onChange(newAttributes);
    }
  };
  const DesktopMode = (localStorage.getItem('DeviceMode')).includes('Desktop');

  // Converting image to imageblob url
  const triggerDownload = (imageBlob, fileName) => {
    var Bloburl = URL.createObjectURL(imageBlob);
    const get_id = localStorage.getItem("id");
      imageresult.append("id", get_id);
    imageresult.append("image", imageBlob, "filename.png");
    props.passurl(Bloburl, imageresult);
    props.imagedata(imageresult);
    props.updateprofilePage()
    setavtaarsImages(true);
    props.AvaatarStatus();
    // FileSaver.saveAs(imageBlob, fileName);
  };

  //user click submit buttton 
  const onDownloadPNG = () => {
    const svgNode = ReactDOM.findDOMNode(avatarRef.current);
    const canvas = canvasRef.current;
    const ctx = canvas.getContext("2d");
    ctx.clearRect(0, 0, canvas.width, canvas.height);

    const anyWindow = window;
    const DOMURL = anyWindow.URL || anyWindow.webkitURL || window;

    const data = svgNode.outerHTML;
    const img = new Image();
    const svg = new Blob([data], { type: "image/svg+xml" });
    const url = DOMURL.createObjectURL(svg);
    

    img.onload = () => {
      ctx.save();
      ctx.scale(2, 2);
      ctx.drawImage(img, 0, 0);
      ctx.restore();
      DOMURL.revokeObjectURL(url);
      canvasRef.current.toBlob((imageBlob) => {
        triggerDownload(imageBlob, "avatar.png");
      });
    };
    img.src = url;
  };
  //close the avataar page 
   function onCancelImage (){
    props.updateprofilePage()
    setavtaarsImages(true);
    props.AvaatarStatus()
   }

   const handleLeftClick = (key) => {
    setSelectedTab(options[Number(key)-1].type);
     
   }

   const handleRightClick = (key) => {
    setSelectedTab(options[Number(key)+1].type);
  }

   let optionsLength = Number(Object.keys(options).length);

  return (
    <div className ="container customContainer">
      <div className="col-md-12 topsection-wrap  mt-0 px-0 pt-4">
      <h1 className="pagetitle textcolor1 text-center">{t('Select Avatar')}</h1>
      </div>
      {avtaarsImages === false ? <Container>
      <StyledAvatar>
        <Avatar
          ref={avatarRef}
          style={{ width: "200px", height: "200px" }}
          {...props.value}
        />
      </StyledAvatar>
      {DesktopMode ?
      <Tabs>
        {map(options, (option) => {
          return (
            <Tab
              selectedTab={selectedTab}
              type={option.type}
              onClick={() => setSelectedTab(option.type)}
            >
              {option.label}
            </Tab>
          );
        })}
      </Tabs>
      :
          <div>
            {Object.keys(options).map((key) => {
              return (
                (selectedTab == options[key].type) && 
                <div className="col-md-12 row m-0 px-0">
                  <div className="btn-group selecton-header col-md-12">
                      { key > 0 && <div 
                        className="btn left-btn-avatar" 
                        style={{fontSize:"40px"}} 
                        onClick={() => handleLeftClick(key)} 
                        disabled={key <= 0 ? true : false}> 
                        &#x2039; 
                        </div>
                      }
                      <div
                        className = "m-2"
                        style={{alignSelf: "center", width:"100%"}}
                      >
                        {(selectedTab == options[key].type) && options[key].label}
                      </div>
                        {optionsLength-1 > Number(key) && <div 
                          className="btn right-btn-avatar" 
                          style={{fontSize:"40px", float:"right"}} 
                          onClick={() => handleRightClick(key)}
                          disabled={optionsLength-1 > Number(key)  ? true : false}> 
                          &#x203A; 
                        </div>
                      }
                  </div>
                </div>
                );
            })}
        </div>
      }
      <Tabpanes>
        {options.map((option) => {
          return (
            <Tabpane selectedTab={selectedTab} type={option.type}>
              {option.values.map((val) => {
                var attr = {};
                attr[option.attribute] = val;
                if (option.transform) {
                  attr.style = { transform: option.transform };
                }
                return (
                  <Pieces onClick={() => pieceClicked(option.attribute, val)}>
                    {option.type === "avatarStyle" ? (
                      <span style={{ margin: "5px" }}>{val}</span>
                    ) : (
                      <Piece pieceSize="50" pieceType={option.type} {...attr} />
                    )}
                    {(val === "Blank" || val === "NoHair") && (
                      <None>(none)</None>
                    )}
                  </Pieces>
                );
              })}
              <ColorContainer>
                {option.colors &&
                  (option.type !== "top" ||
                    option.hats.indexOf(props.value.topType) === -1) &&
                  props.value.topType !== "Eyepatch" &&
                  props.value.topType !== "LongHairShavedSides" &&
                  props.value.topType !== "LongHairFrida" &&
                  map(option.colors, (color, colorName) => {
                    return (
                      <Color
                        style={{
                          backgroundColor: color,
                          border:
                            color === "#FFFFFF"
                              ? "1px solid #ccc"
                              : "1px solid " + color,
                        }}
                        onClick={() =>
                          pieceClicked(option.colorAttribute, colorName)
                        }
                      ></Color>
                    );
                  })}

                {option.hatColors &&
                  option.hats.indexOf(props.value.topType) !== -1 &&
                  props.value.topType !== "Hat" &&
                  map(option.hatColors, (color, colorName) => {
                    return (
                      <Color
                        style={{
                          backgroundColor: color,
                          border:
                            color === "#FFFFFF"
                              ? "1px solid #ccc"
                              : "1px solid " + color,
                        }}
                        onClick={() => pieceClicked("hatColor", colorName)}
                      ></Color>
                    );
                  })}
              </ColorContainer>
            </Tabpane>
          );
        })}
      </Tabpanes>
      <DownloadRow>
        <Button className="cancel-btn-avatar avatar-btn" onClick={onCancelImage}>
          Cancel
        </Button>{" "}
        <Button className='avatar-btn avatar-save' onClick={onDownloadPNG}>
          Save
        </Button>{" "}
      </DownloadRow>
      <canvas
        style={{ display: "none" }}
        width="528"
        height="560"
        ref={canvasRef}
      />
    </Container>:null }
    
    </div>
  );
}
