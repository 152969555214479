import { Homes, ReactionsList} from "../../Services/ApiEndPoints";
import React, { useState, useEffect } from "react";
import { APICALL } from "../../Services/ApiServices";
// import LazyLoad from "react-lazyload";
import ItemDetails from "../Common/PageItemDetails";
import '../../Static/css/common.css'
import moment from 'moment';
import InfiniteScroll from "react-infinite-scroll-component";
import { t } from '../../Translation/Translation';
import  NewsDetails from  '../News/NewDetails';
import PollsDetails from '../Polls/PollsDetails';
import EventsDetails from '../Events/EventDetails';
import Slider from "../../Utilities/Slider/Slider"
import { Banner } from "../../Services/ApiEndPoints";
import { useHistory } from "react-router-dom";
import BackButtonComponent from '../../Utilities/BackButtonComponent'


const HomeList = () => {
  const [data, setdata] = useState("");
  const get_id = localStorage.getItem("id");
  const [offset, setoffset] = useState(0);
  const [hasMore,sethasMore] =useState(true);
  const [DeviceMode, setDeviceMode] = useState('false');
  const [defaultitem, setdefaultitem] =useState();
  const [listviewMode, setlistviewMode] =useState(false)
  const [sliderData, setsliderData] = useState("");
  const [bannerTime, setbannerTime] = useState(5);
  const history = useHistory();
  const [updateStatus,setupdatestatus] =useState();
  const [backbuttonUrl, setbackbuttonUrl] = useState();
  const [urlState, seturlState] = useState(false);
  const [reactionsList, setReactionsList] = useState([]);
  const [newReaction, setNewReaction] = useState([]);
  const [newReactionEntryId, setNewReactionEntryId] = useState();
  let user_id = localStorage.getItem("id");

  useEffect(() => {
    if (!JSON.parse(localStorage.getItem("id")) || data) return;
     //checking Device mode 
   if(localStorage.getItem('DeviceMode') === 'Desktop'){
    setDeviceMode(true);
   }
    let post = {
      'user_id': localStorage.getItem("id"), //"admin",
      'offset': offset
    };
    APICALL.service(Homes, "POST", post)
      .then((result) => {
        let dataList = []
        let resutingdata = []
        if (result) {
          Object.keys(result).map((value, i) => {
            resutingdata.push(result[value])
          });
           // sorting  array based on postdate its dispalying 
          let sortingOrder = resutingdata.sort((d1, d2) => {
            return (new Date(d1.post_date).getTime() - new Date(d2.post_date).getTime())
          })
           //change post_date format 
          sortingOrder.map((value, i) => (
            value['post_date'] = moment(new Date(value.post_date)).format("DD/MM/YYYY"),
            dataList.push(value)
          ),
          );
          //Always load the first item from List View by default in detail view page 
          const lastItem = dataList[dataList.length - 1]
          setdefaultitem({"id" : lastItem.id,"type" : lastItem.entry_type});
          setdata(dataList.reverse())
        }
      })
      .catch((error) => {
        console.error(error);
      });
  }, [data]);
  //store home page path name in session
  useEffect(() => {
    sessionStorage.removeItem('historydata')
    if (urlState === false){
      setbackbuttonUrl('/');
      seturlState(true);
      }
  }, [])

  /**
   * I would like to load just 10 searching results and if user scroll to the 
   * last search element than next 10 results load automatically.
  */
  function fetchMoreDat() {
    let count = offset + 1;
    let post = {
      'user_id': localStorage.getItem("id"), 
      'offset': count
    };
    setoffset(count);
    APICALL.service(Homes, "POST", post)
      .then((result) => {
        let dataList = []
        let resutingdata = []
        if(result.status === 404){
          sethasMore(false)
        }
        if (result) {
          Object.keys(result).map((value, i) => {
            resutingdata.push(result[value])
          });
          // sorting  array based on postdate its dispalying 
          let sortingOrder = resutingdata.sort((d1, d2) => {
            return (new Date(d1.post_date).getTime() - new Date(d2.post_date).getTime())
          })
          //change post_date format 
          sortingOrder.map((value, i) => (
            value['post_date'] = moment(new Date(value.post_date)).format("DD/MM/YYYY"),
            dataList.push(value)
          ),
          );
        }
        setdata(data.concat(dataList.reverse()))
      })
      .catch((error) => {
        console.error(error);
      });
  }
  //TO update detail page when user click on list item
    const updatesId = e =>{
      setdefaultitem({'id' : e.id, 'type' : e.type});
      if(localStorage.getItem('DeviceMode') === 'Desktop'){
        setlistviewMode(false);
       }else{
        setlistviewMode(true);
       }
  }

 // To update likes counts
 const updateslikes = e => {
  updatingNewlistView(e);
  //update vote for Votes
  //setupdatestatus();
  setupdatestatus(updateStatus ? false :true);
}
// updating newlistview setState values
function updatingNewlistView (e){
  setdata(
    data.map((item) =>
      item.id === e
        ? {
          ...item,
          user_response_status:
            item.user_response_status === true ? false : true,
          user_like_status:
            item.user_like_status ? false : true,
        }
        : item
    )
  );
}
function UpdateDetailsLikes  (e){
   updatingNewlistView(e);
}
const backButton =() =>{
  setlistviewMode(false)
}
  // {/* 
  //   * Redirect detailpages
  //   * display list view and detalview side by side
  //   */}

  function RedirectDetailspages () {
     switch (defaultitem.type) {
      case 'News':
        return (
        <NewsDetails 
        updatesEntityId = {defaultitem} 
        CommentsStatus = {false} 
        updateDetailslikes={UpdateDetailsLikes}
        UpdateStatus = {updateStatus}
        backButton={backButton}
        updateReactionsInList={updateReactionsInList}
        reaction={newReaction}
        reactionsList = {reactionsList}
        newReactionEntryId={newReactionEntryId}
        />
        );
      case "Surveys":
        return(
          <PollsDetails 
          updatesEntityId = {defaultitem}
          updateslikes = {updateslikes} 
          UpdateStatus = {updateStatus} 
          backButton={backButton}
           />  
        );
      case "Events":
        return(
          <EventsDetails updatesEntityId = {defaultitem} CommentsStatus = {false} backButton={backButton}/>
        );
      
     }
  }
  
  useEffect(() => {
    if (!JSON.parse(localStorage.getItem("id"))) return;
    let data = {
      'user_id'  : get_id,
    }
    APICALL.service(Banner, "POST", data)
      .then(result => {
        setbannerTime(result.banner_time);
        
        _constructSliderData(result.data);
      })
      .catch(error => {
        console.error("banner error : ", error);
      })

  }, []);
  const _constructSliderData = (data) => {
    const sliders = [];
    let link ;
    let deviceMode = localStorage.getItem("DeviceMode");
    data.forEach((val, i) => {
      //let link = (val.entry_type.toLowerCase() === "news") ? "news-details" : "event-details";
      if (val.entry_type.toLowerCase() === "news") {
        link = "news" ;
      } else if (val.entry_type.toLowerCase() === "events") {
        link = "events";
      } else{
        link = "polls";
      }
      sliders[i] = { 'original': (deviceMode === "Desktop") ? val.teaserImage : val.mobile_teaser_image, 'thumbnail': (deviceMode === "Desktop") ? val.teaserImage : val.mobile_teaser_image, 'link': "/" + link + "/" + val.id };
    })
    setsliderData(sliders);

  }

  // updating newlistview setState values
function updateReactionsInList (e, entryId, reaction){
  setdata(
    data.map((item) =>
      item.id === entryId
        ? {
          ...item,
          reactions:reaction
        }
        : item
    )
  );
  setNewReactionEntryId(entryId);
  setNewReaction(reaction);

}

useEffect(() =>{
  const res = {
    "user_id": user_id
  }
  // api call to fetch url from backend
  APICALL.service(ReactionsList, "POST", res)
    .then(result => {
      if (result != null)
      // set result in state
        if(result.STATUS === 201){
          setReactionsList(result.DATA);
        }else{
          setReactionsList([]);
        }
    });
}, [])

  const listItemMode = () => {
    return (
      <>
        <div className="container customContainer">
        {(sliderData.length > 0) && <Slider history={history} bannerTime={bannerTime} sliderData={sliderData} />}
          {backbuttonUrl && <BackButtonComponent backButtonLink = {backbuttonUrl}/>}
          <h4 className="textcolor1 mb-0">{t('Recently added')}</h4>
          <hr className="w-100 my-2"></hr>
          <div className="post">
            <InfiniteScroll
              dataLength={data.length}
              next={fetchMoreDat}
              hasMore={hasMore}
              loader={<h5 className="textcolor1 text-center">{t('Loading')}.</h5>}
              scrollableTarget="scrollableDiv"
            >
              {data !== undefined &&
                Object.keys(data)
                  // .reverse()
                  .map((value, i) => {
                    return (
                        <ItemDetails
                          key={i}
                          data={data[value]}
                          type={data[value].entry_type}
                          updateslikes={updateslikes}
                          updatesId = {updatesId}
                          updateReactionsInList={updateReactionsInList}
                          reactionsList = {reactionsList}
                          newReactionEntryId={newReactionEntryId}
                          pageType={"listview"}
                          activeItem={defaultitem}
                        />
                    );
                  })}
              {data !== undefined && data.length < 1 ? (
                <div className="col-md-12 col-12 text-center">
                </div>
              ) : null}
            </InfiniteScroll >
            {/* </div> */}
            {data !== undefined && data.status === '404' && <div> {t('No news') + ',' + t('surveys') + '&' + t('events available')} </div>}
          </div>
        </div>
      </>
  
    );
  }
   {/* 
    * check and split the page for desktop device by using DesktopMode variable
    * display list view and detalview side by side
    */}
  return (
    <div >
    { 
    DeviceMode === true ? <div className="Desktop-mode-list-and-detail-view layoutSpace ">    
      <div className ="Desktop-mode-list-view float-lg-left col-lg-5 pr-0">
      <div  className="customscroll" id="scrollableDiv" >
       {listItemMode()}
      </div>
      </div>
      <div className = "Desktop-mode-Detail-view float-lg-left col-lg-7 pr-4 pl-1">
      <div className="customscroll">{defaultitem && RedirectDetailspages() } </div>
      </div>
    </div>:(listviewMode === false ? listItemMode() : RedirectDetailspages())
  }
</div>
  )
};

export default HomeList;
