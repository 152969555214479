import React, { useState, useEffect } from 'react';
import { poll } from "../../Services/ApiEndPoints";
import { APICALL } from "../../Services/ApiServices";
import ProgressBar from 'react-bootstrap/ProgressBar';
import { useHistory, useParams } from 'react-router-dom';
import './polls.css';
import '../../Static/css/common.css';
import { t } from '../../Translation/Translation';
import previewFn  from '../../Common/Preview';

export default function Charts({updatesEntityId,backButtons}) {
  // const [data, setdata] = useState([]);
  const [chart, setchart] = useState([]);
  // eslint-disable-next-line no-unused-vars
  const history = useHistory();
  let params = useParams(); 

  useEffect(() => {
    const get_id = localStorage.getItem("id");
    let param = previewFn(params);
    const data = {
      'user_id': get_id,
      'entry_id': updatesEntityId,
      'type': 'result',
      'param': param
    }
    APICALL.service(poll, "POST", data)
      .then(result => {
        if (result !== undefined || result !== '' || result !== null) {
          try {
            setchart(result);
            // _constructbarchar(result); 
          } catch (e) {
            console.error(e);
          }
        }
      })
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updatesEntityId]);

  const goBackButton =() =>{
    backButtons(true);
  }

  return (
    <section className="container customContainer">
        <div className="col-md-12 pt-4">
          <div className="row">
              <p className="browserback" onClick={goBackButton}>back</p>
              <h1 className="pagetitle textcolor1 col-10 text-center">{t('Survey Report')}</h1>
          </div>
        </div>
        <div className="col-12 px-1 mt-5">
           <h4 className="card-title reportDetail">{chart.question ? chart.question : null}</h4>
        </div>
        <div className="col-12 px-1  desktop-width">
            {
            Array.isArray(chart.options) ? chart.options.map(key => (
              <div className="col-12 pl-0 mb-4">
                <div className='row mb-1'>
                  <div className ="col-10 surveyLabel">{key.option}</div>
                  <div className ="col-2 pr-0 text-right surveyPercentage">{key.average +"%"}</div>
                </div>
                <ProgressBar now={key.average}  variant = "danger" />
              </div>
            )) : null
          }
        </div>
        <div className="col-12 px-1">
        <h4 className="textcolor1">{t('Conclusion')}</h4>
        {chart.conclusion ? chart.conclusion : null}
      </div>
    </section>
  )
}
