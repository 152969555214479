// import firebase from "firebase"

import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/messaging';

// import '@firebase/messaging';
// Your web app's Firebase configuration
const config = {
    apiKey: "AIzaSyBW10QbtVT3o3PTBxXU3tf_uca9hlIqQw4",
    authDomain: "bcrew-f5185.firebaseapp.com",
    databaseURL: "https://bcrew-f5185.firebaseio.com",
    projectId: "bcrew-f5185",
    storageBucket: "bcrew-f5185.appspot.com",
    messagingSenderId: "472769073415",
    appId: "1:472769073415:web:b334f5056a2fdb510b35a2"
  };
// Initialize Firebase
firebase.initializeApp(config);

export default firebase;