/* eslint-disable no-unused-vars */
import React, { useState } from 'react';
import { useAppContext } from "../../Config/contextLib";
// import Slider from "../../Utilities/Slider/Slider"
import HomeList from "./HomeList"
// import { useHistory } from "react-router-dom";
import BDayPopup from './BDayPopup/BDayPopup';
// import { APICALL } from "../../Services/ApiServices"
// import { Banner } from "../../Services/ApiEndPoints";
import  './Home.css';




const Home = () => {
  const { isAuthenticated, userHasAuthenticated } = useAppContext();
  const [get_id,setget_id]=useState(JSON.parse(localStorage.getItem("id")))

  return (
    isAuthenticated &&
    <div>
      {/* {(sliderData.length > 0) && <Slider history={history} bannerTime={bannerTime} sliderData={sliderData} />} */}
      <HomeList />
      <BDayPopup />
    </div>


  );
}

export default Home




