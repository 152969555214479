import React, { useState, useEffect } from "react";
import { Embraces } from "../../Services/ApiEndPoints";
import { APICALL } from "../../Services/ApiServices";
import SinglePageLayout from "../Common/singlePageLayout";

function Embrace() {
    const [EmbraceData, setEmbraceData] = useState();

    useEffect(() => {
        let User_id = localStorage.getItem("id");
        const res = {
            user_id: User_id,
        };
        APICALL.service(Embraces, "POST", res).then((result) => {
            if (result != null) setEmbraceData(result.embrace);
        });
    }, []);

    return ( <
        SinglePageLayout header = { "Embrace" }
        layoutData = { EmbraceData }
        url = { "/embrace" }
        />
    );
}

export default Embrace;