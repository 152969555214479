import React, { useState, useEffect } from "react";
import { UserProfile } from "../../Services/ApiEndPoints";
import { APICALL } from "../../Services/ApiServices";
import { t } from '../../Translation/Translation';
import { useHistory } from 'react-router-dom';
import BackButtonComponent from '../../Utilities/BackButtonComponent'
import BackButton from "../../Utilities/BackButton";

export default function Language({ props, backButton, updateLanguagesPage }) {
  const history = useHistory();
  const [temp, setTemp] = useState(props);
  const [sitelanguage, setSitelanguage] = useState(temp);
  const [get_id] = useState(JSON.parse(localStorage.getItem("id")))
  const DesktopMode = (localStorage.getItem('DeviceMode')).includes('Desktop');
  const [backbuttonUrl, setbackbuttonUrl] = useState();
  const [urlState, seturlState] = useState(false);
  // const get_id = localStorage.getItem("id");
  useEffect(() => {
    // APICALL.service(ActiveLanguages, "GET")
    // .then(result => {
    //   if(result){
    //     console.log("ActiveLanguages : ", result);            
    //   }   
    // });
    APICALL.service(UserProfile + get_id, "GET")
      .then(result => {
        if (result) {
          setSitelanguage(result.active_language.value);
          setTemp(result.active_language.value);
        }
      })
  }, []);
  /***
  * Store url in session storage for back button functionality
  */
  useEffect(() => {
    if (urlState === false) {
      setbackbuttonUrl('/settings/1');
      seturlState(true);
    }
  }, [])

  const saveUpdate = (e) => {
    setSitelanguage(temp);
    setSitelanguage(e.target.value);
    localStorage.setItem('activeLanguage', e.target.value)
    let data = {
      'activeLanguage': e.target.value,
    }
    APICALL.service(UserProfile + get_id, "PUT", data)
      .then(result => {
        setSitelanguage(result.active_language.value);
      });
    setTemp(sitelanguage);
    if (DesktopMode === true)
      updateLanguagesPage(e.target.value)
    setSitelanguage(e.target.value);
  }

  //Remove the last item from array and push the url for back button 
  function historyBack() {
    let response = BackButton("/settings");
    if (response === false) {
      backButton(false, 1)
      history.push('/settings');
    }
    else {
      history.push(response);
    }
  }

  return (
    <div className="container customContainer pt-3 pb-3">
      {/* <div className="col-md-12 row mt-4">
            <div className="col-2"> <p className="browserback" onClick={history.goBack}>back</p></div>
            <h1 className="pagetitle textcolor1 col-10 text-center">{t("Languages")}</h1>
        </div> */}

      <div className="col-md-12 topsection-wrap  mt-0 px-0 pt-4">
        <p className={DesktopMode === true ? "hide-class" : "browserback"} onClick={historyBack}>back</p>
        {backbuttonUrl && <BackButtonComponent backButtonLink={backbuttonUrl} />}
        <h1 className="pagetitle textcolor1 text-center">{t("Languages")}</h1>
      </div>

      <div className="col-md-12 col-12 col-lg-12 mt-4">
        <div className="row">
          <div className="col-lg-12 col-12 my-3 px-1">
            <p className="float-left innerpagetitle">{t("English")}</p>
            <label className="radiowrap float-right">
              <input className="float-right" type="radio" value="en" name="language" onChange={saveUpdate} checked={sitelanguage === 'en'} />
              <span className="radiomark"></span>
            </label>
          </div>
          <div className="col-lg-12 col-12 my-3 px-1">
            <p className="float-left innerpagetitle">{t("French")}</p>
            <label className="radiowrap float-right">
              <input className="float-right" type="radio" value="fr" name="language" onChange={saveUpdate} checked={sitelanguage === 'fr'} />
              <span className="radiomark"></span>
            </label>
          </div>
          <div className="col-lg-12 col-12 my-3 px-1">
            <p className="float-left innerpagetitle">{t("Dutch")}</p>
            <label className="radiowrap float-right">
              <input className="float-right" type="radio" value="nl" name="language" onChange={saveUpdate} checked={sitelanguage === 'nl'} />
              <span className="radiomark"></span>
            </label>
          </div>
        </div>
      </div>
    </div>
  );
}

