   
    var calendarGenerators = {

        google: function(event) {
        let start = event.start.replace(/[`~!@#$%^&*()_|+\-=?;:'",.<>\{\}\[\]\\\/]/gi, "")
        let end = event.end.replace(/[`~!@#$%^&*()_|+\-=?;:'",.<>\{\}\[\]\\\/]/gi, "")
          var href = encodeURI([
            'https://www.google.com/calendar/render',
            '?action=TEMPLATE',
            '&text=' + event.title,
            '&dates=' +  start,
            '/' + end,
            '&details=' + 'BCrew Events',
            '&location=' + event.venue,
            '&sprop=&sprop=name:'
          ].join(''));
          return href 
        },
    

        ics: function(event) {
        let start = event.start.replace(/[`~!@#$%^&*()_|+\-=?;:'",.<>\{\}\[\]\\\/]/gi, "")
        let end = event.end.replace(/[`~!@#$%^&*()_|+\-=?;:'",.<>\{\}\[\]\\\/]/gi, "")

          var href = encodeURI(

            'data:text/calendar;charset=utf8,' + [
              'BEGIN:VCALENDAR',
              'VERSION:2.0',
              'BEGIN:VEVENT',
              'URL:' + (event.url || document.URL),
              'DTSTART:'+ ( start || ""),
              'DTEND:' + ( end || ""),
              'SUMMARY:' + (event.title || ""),
              'DESCRIPTION:' + ( event.description || 'BCrew Events'),
              'LOCATION:' + ( event.venue || ""),
              'END:VEVENT',
              'END:VCALENDAR'].join('\n'));
    
          return href 
        },
    
        ical: function(event) {
          return this.ics(event);
        },
    
        igoogle: function(event) {
          return this.google(event);
        }
      };
 export default calendarGenerators
