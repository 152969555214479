import React, { useState, useEffect } from 'react';
import ItemDetails from "../Common/PageItemDetails";
import { Locations } from "../../Services/ApiEndPoints";
import { APICALL } from "../../Services/ApiServices";
import SearchBoxWithSubmit from "../Common/SearchBoxWithSubmit";
import "./locationstyles.css";
import { useHistory } from 'react-router-dom';
import { t } from '../../Translation/Translation';
import LocationDetails from './LocationDetails';
import { useParams } from "react-router";
import BackButtonComponent from '../../Utilities/BackButtonComponent';
import BackButton from "../../Utilities/BackButton";
import previewFn from '../../Common/Preview';
import InfiniteScroll from "react-infinite-scroll-component";

export default function OurLocations() {
    const [locationsdata, setlocationsdata] = useState("")
    const [offset, setoffset] = useState(0);
    const [hasMore, sethasMore] = useState(true);
    const history = useHistory();
    const [DeviceMode, setDeviceMode] = useState(false);
    const [defaultitem, setdefaultitem] = useState();
    const [listviewMode, setlistviewMode] = useState(false);
    let params = useParams();
    const [backbuttonUrl, setbackbuttonUrl] = useState();
    const [urlState, seturlState] = useState(false);
    const [searchVal, setsearchVal] = useState("");
    const [searchState, setSearchState] = useState(false);

    const [highlightitem, setHighlightitem] = useState();

    useEffect(() => {
        if (urlState === false) {
            if (params.id === undefined) {
                setbackbuttonUrl('/ourlocations');
            } else {
                setbackbuttonUrl('/ourlocations/' + params.id);
            }
            seturlState(true);
        }
    }, [])

    function historyBack() {
        let response = BackButton();
        history.push(response);
    }

    useEffect(() => {
        //checking Device mode 
        if (localStorage.getItem('DeviceMode') === 'Desktop') {
            setDeviceMode(true);
        }
        const get_id = localStorage.getItem("id");
        let param = previewFn(params);
        let data = {
            'user_id': get_id,
            'type': 'view',
            'offset': offset,
            'param': param,
            'search': searchVal,
        }
        let locationsObject;
        APICALL.service(Locations, "POST", data)
            .then(result => {
                if (result) {
                    locationsObject = result;
                    if(locationsObject.status !== 401 && locationsObject.status !== 404){
                        if (!hasMore)sethasMore(true);
                        locationsObject = alphabetical_sort_object_of_objects(locationsObject, 'title');
                        const lastItem = locationsObject[0].id
                        if(params.id === undefined){
                            setHighlightitem({"id" : lastItem,"type" : "locations"});
                            setdefaultitem(lastItem);
                        }
                    }
                    else{
                        sethasMore(false);
                        if(params.id === undefined){
                            setdefaultitem();
                        }
                        setlocationsdata("");
                    }
                }
                //Always load the first item from List View by default in detail view page 
                setlocationsdata(locationsObject)
            });
        if (params.id !== undefined) {
            setHighlightitem({"id" : params.id, "type" : "locations"});
            setdefaultitem(params.id);
            //checking device mode
            if (localStorage.getItem('DeviceMode') === 'Desktop') {
                setlistviewMode(false);
            } else {
                setlistviewMode(true);
            }
        }
    }, [searchState]);

    //search parameter passing to the function
    const SearchElements = (e) => {
        setsearchVal(e.target.value);
        // setoffset(0);
        // filterData(e.target.value);
    }

    const searchSubmit = (e) => {
        e.preventDefault();
        setoffset(0);
        setSearchState(!searchState);
    }
    //backbotton form details page to listview
    const backButton = (e) => {
        setlistviewMode(e)
    }
    //TO update detail page when user click on list item
    const updatesId = e => {
        setHighlightitem({"id": e, "type": "locations"});
        setdefaultitem(e);
        if (localStorage.getItem('DeviceMode') === 'Desktop') {
            setlistviewMode(false);
        } else {
            setlistviewMode(true);
        }
    }


    function alphabetical_sort_object_of_objects(data, attr) {
        var arr = [];
        for (var prop in data) {
            if (data.hasOwnProperty(prop)) {
                var obj = {};
                obj[prop] = data[prop];
                obj.tempSortName = data[prop][attr].toLowerCase();
                arr.push(obj);
            }
        }
        arr.sort(function (a, b) {
            var at = a.tempSortName,
                bt = b.tempSortName;
            return at > bt ? 1 : (at < bt ? -1 : 0);
        });
        var res = [];
        for (var i = 0, l = arr.length; i < l; i++) {
            obj = arr[i];
            delete obj.tempSortName;
            for (prop in obj) {
                if (obj.hasOwnProperty(prop)) {
                    var id = prop;
                }
            }
            var item = obj[id];
            res.push(item);
        }
        return res;
    }

    /**
   * I would like to load just 10 searching results and if user scroll to the 
   * last search element than next 10 results load automatically.
  */
 function fetchMoreDat() {
    let count = offset+1;
    let post = {
      'user_id': localStorage.getItem("id"),
      'type':'view', 
      'offset': count,
      'search': searchVal,

    };
    setoffset(count);
    APICALL.service(Locations, "POST", post)
      .then((result) => {
        let resutingdata = []
        if(result.status === 404){
          sethasMore(false)
        }
        if (result.status === undefined) {
          Object.keys(result).map((value, i) => {
            resutingdata.push(result[value])
          });
          resutingdata = alphabetical_sort_object_of_objects(resutingdata, 'title');
          setlocationsdata(locationsdata.concat(resutingdata));
        }
        else{
            sethasMore(false);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  }
    /*
    ListView Compoment
    */
    const listMode = () => {
        return (
            <div className="container customContainer">
                <div className="post">
                    <div className="mx-0">
                        <div className="col-md-12 topsection-wrap  mt-0 px-0 pt-4">
                            {/* <p className={DeviceMode === true ? "hide-class" : "browserback"} onClick={history.goBack}>back</p> */}
                            <p className={DeviceMode === true ? "hide-class" : "browserback"} onClick={historyBack}>back</p>
                            {backbuttonUrl && <BackButtonComponent backButtonLink={backbuttonUrl} />}
                            <h1 className="pagetitle textcolor1 text-center">{t('Locations')}</h1>
                        </div>
                        <div className="col-md-12 col-12 p-0">
                            <SearchBoxWithSubmit onSearchValue={SearchElements} searchVal={searchVal} searchSubmit={searchSubmit}/>
                        </div>
                    </div>
                    {/* {locationsdata === undefined && <div className="col-12 text-center" ><h5 className="text-center textcolor1"> {t("Loading locations")}.</h5> </div>} */}
                    {locationsdata !== undefined && locationsdata.length >= 1 &&
                        <InfiniteScroll
                        dataLength={locationsdata.length}
                        next={fetchMoreDat}
                        hasMore={hasMore}
                        loader={<h5 className="textcolor1 text-center">{t('Loading')}.</h5>}
                        scrollableTarget="scrollableDiv"
                        >
                            {locationsdata.status === undefined && locationsdata.length >= 1 &&
                                Object.keys(locationsdata).map((value, i) => {
                                    return (
                                        <div key={i}>
                                            <ItemDetails
                                                data={locationsdata[value]}
                                                type='Locations'
                                                updatesId={updatesId}
                                                activeItem={highlightitem}
                                            />
                                         </div>
                                    );
                                })
                            }

                        </InfiniteScroll>
                    }
                    
                    {locationsdata !== "" && locationsdata.status !== undefined && <div className="col-md-12 col-12 text-center"><h4 className="textcolor1">{t('No locations found')}</h4></div>}
                    </div>

            </div>
        )
    }

    return (
        <div>
            {
                DeviceMode === true ? <div className="Desktop-mode-list-and-detail-view layoutSpace">
                    <div className="Desktop-mode-list-view float-lg-left col-lg-5 pr-0">
                    <div className="customscroll" id="scrollableDiv">{listMode()}</div>
                    </div>
                    <div className="Desktop-mode-Detail-view float-lg-left col-lg-7 pr-4 pl-1 location-desk">
                        <div className="customscroll">{defaultitem && <LocationDetails updatesEntityId={defaultitem} />}</div>
                    </div>
                </div> : (listviewMode === false && params.id === undefined ? listMode() : <LocationDetails updatesEntityId={defaultitem} backButton={backButton} />)
            }
        </div>
    );
}

