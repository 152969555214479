import React, { useState } from "react";
import Image from 'react-bootstrap/Image'
import { Link } from "react-router-dom";
import '../News/News.css';
import moment from 'moment';
import { useHistory } from "react-router-dom";
import '../../Static/css/common.css';
import QuestionPopup from '../../Pages/Polls/QuestionPopup';
import { t } from "../../Translation/Translation";
import Popup from "../../Utilities/Popup/modelPopup";
// import { likes } from "../../Services/ApiEndPoints";
// import { APICALL } from "../../Services/ApiServices";
// import { HighlightOff } from "@material-ui/icons";
import Reactions from "../Reactions/Reactions";
import MeetingIcon from "../../Static/images/MeetingRoomTeaserImage.png";


const NewsItemDetails = ({ data, type, updateslikes, updatesId, updateReactionsInList, reaction, reactionsList, newReactionEntryId, pageType, activeItem = {}}) => {
  // const [isSelected, setisSelected] =useState(false)
  const history = useHistory();
  const [Question, setQuestion] = useState(false);
  const [message, setMessage] = useState("");

  const renderActionIcons = (val) => {

    switch (val) {
      case 'News':
        return <p className="desIcon imgSprite"></p>;
      case "Events":
        return <p className="eventIcon imgSprite"></p>;
      case "Surveys":
        return <p className="surveygrey imgSprite"></p>;
      case "Locations":
        return <p className="locationIcon imgSprite"></p>;
      case "Tips":
        if (data.rating !== null) {
          var rate = data.rating.includes('.') ? data.rating : data.rating + ".0"
          return <p className="textcolor1 mb-0  rateAlign"><span className="star"></span>{rate}</p>;
        }
        break;
      default:
        return "";
    }
  }

// like action code not required now
  // const incrementMe = (val) => {
    
  //   let data = {
  //     'user_id': localStorage.getItem("id"),
  //     'entry_id': val.id
  //   }
  //   APICALL.service(likes, "POST", data)
  //     .then(result => {
  //       if (result.data === "success") {
  //         updateslikes(val.id)
  //       }
  //     })
  // }

  const renderDetailsUrl = (val, type) => {
    switch (type) {
      case "Library":
        var url = '/document-reader/';
        return (<Link to={url} className="textcolor1 font-weight-bold float-right">{t('Document link')}</Link>)
      default:
        return ("");
    }
  }
  // Updating user clicked on listview item 
  const UpdatingEntryIds = e =>{
    updatesId(e)
  }

 
  
  //Redirect detailpages 
  const RedirectDetailspages = (val, type) => {
    switch (type) {
      case 'News':
        UpdatingEntryIds({"id":val.id,"type" : type})
        break;
      case "Events":
        UpdatingEntryIds({"id":val.id,"type" : type})
        break;
      case "Locations":
        // var locurl = "/location-details/" + val.id;
        // history.push({
        //   pathname: locurl,
        // })
        UpdatingEntryIds(val.id)
        break;
      case "Surveys":
        UpdatingEntryIds({"id":val.id,"type" : type})
        break;
      case "Tips":
        UpdatingEntryIds(val.id)
        break;
        case "Meeting":
        UpdatingEntryIds(val.id)
        break;
      default:
        return ("");
    }
  }
  const isPollActive = (startDate, endDate) => {
    let pollStartdate = new Date(startDate);
    let pollenddate = new Date(endDate);
    let currentDate = new Date();
    if (currentDate >= pollStartdate && currentDate <= pollenddate) {
      return true
    }
    return false;
  }

  function convertUTCDateToLocalDate(date) {
    let d = new Date(date);
    return d;
  }
  const handleQuestions = () => {
    setQuestion(true);
  }
  function QuestionState() {
    setQuestion(false);
  }
  const updateField = (e) => {
    updateslikes(e);
  }
  const renderCalltoAction = (val, type) => {
    
    switch (type) {
      case 'News':
        return (
          // likes action code not required now
          // <span className="">
          //   <span
          //     className="textcolor1  float-right"
          //     onClick={() => incrementMe(val)}
          //   >
          //     {val.likes_status === "enabled" ? (val.user_like_status ? t("Unlike") : (val.call_to_action_name !== null ? val.call_to_action_name : t("Like"))) : null}
          //   </span>
          // </span>
          // if reaction status is enable allow users to react
          val.likes_status === "enabled" ?
          <Reactions 
              reactions = {val.reactions}          
              entryId ={val.id}
              updateReactionsInList={updateReactionsInList}
              reactionsList = {reactionsList}
              newReactionEntryId ={newReactionEntryId}
              /> 
              :null

        );
      case "Events":
          return (
          <span className="eventLink float-right">
            {((val.call_to_action_name !== null) && (val.call_to_action_name !== "") && (val.call_to_action_name !== undefined)) 
            && ((val.call_to_action_url !== null) && (val.call_to_action_url !== "") && (val.call_to_action_url !== undefined)) 
            ? <a target="_blank" href={val.call_to_action_url} className="textcolor1 px-2 py-1">{val.call_to_action_name}</a> : null}
            </span>);
      case "Surveys":
        return (
          <span className={(data.user_response_status === false && (isPollActive(data.start_date, data.end_date) === true))
            ? "textcolor1  pollsLink float-right"
            : "disableLink  pollsLink float-right"} onClick={handleQuestions}>
            {val.call_to_action_name ? val.call_to_action_name : t('Vote')}
          </span>
        );
      default:
        return ("");


    }
  }

  const renderDateformat = () => {
    switch (type) {
      case 'News':
        return <span className="small">{data.post_date ? data.post_date : null}</span>;
      case "Events":
        var date = <span className="col-md-12 col-12 p-0 ">
          <span className="Edate small mr-1">{data.start_date ? moment(convertUTCDateToLocalDate(data.start_date)).format('DD/MM/YYYY') : null}</span>
          <span className="Edate small  pl-1 pr-1">{data.end_date === null? null: '-' }</span> 
          <span className="Edate small mr-1"> {data.end_date ? moment(convertUTCDateToLocalDate(data.end_date)).format('DD/MM/YYYY') : null}</span>
        </span>
        return date;
      case "Surveys":
        var dates = <span className="pollswrap">
          <span className="mr-1 small pollsDate">{data.start_date ? moment(convertUTCDateToLocalDate(data.start_date)).format('DD/MM/YYYY') : null}</span>
          <span className="Edate small  pl-1 pr-1">{data.end_date === null? null: '-' }</span> 
          <span className="mr-1 small pollsDate">{data.end_date ? moment(convertUTCDateToLocalDate(data.end_date)).format('DD/MM/YYYY') : null}</span>
        </span>
        return dates;
      default:
        return ("");
    }
  }

  function renderLocationNavigation(data, type) {
    switch (type) {
      case 'Locations':
        return (<p className={data.map_latitude !== null ? "textcolor1 mb-0 listRate customCursor" : "disableLink mb-0 listRate"} onClick={getLocation}>{t('Go!')}</p>);
      case 'Tips':
        return (<p className={data.map_latitude !== null ? "textcolor1 mb-0 goButton customCursor" : "disableLink mb-0 goButton"} onClick={getLocation}>{t('Go!')} </p>);
        case 'Meeting':
        return (<p className="textcolor1 mb-0 meeting-agenda customCursor font-weight-bold" onClick={() => UpdatingEntryIds(data.id)}>{t('Agenda')} </p>);
      default:
        return ("");

    }
  }

  function titleCompress(data, type) {
    if (type === "Locations" || type === "Tips") {
      return ((data.title !== undefined && data.title !== null) ? (data.title.length > 30 ? 
        data.title.replace(regex, '').substring(0,20)+'...': data.title) : null)
    }
    else {
      return ((data.title !== undefined && data.title !== null) ? (data.title.length > 40 ? 
        data.title.replace(regex, '').substring(0,40)+'...': data.title) : null)
    }
  }

  function locationaddress(data, type) {
    if (type === "Locations") {
      var fulladdress = <div className="fontSize">
        <p className="mb-0">{data.compelete_address.address2}</p>
    <p className="mb-0">{data.compelete_address.pin_and_city}{(data.compelete_address.pin_and_city && data.compelete_address.compelete_address_country) ? ", " : ""} {data.compelete_address.compelete_address_country}</p>
      </div>
      return fulladdress;
    }
   else if (type === "Meeting") {
      var meetingData = <>
      <div className="fontSize">
        <p className="mb-0 font-weight-bold">{data.location_name}</p>
        <div className="mb-0">
          <span className="mb-0">Capacity:</span>
          <span className="mb-0 col-6 float-lg-right font-weight-bold">{(data.capacity != null) ? data.capacity : '-'}</span>
        </div>
        <div className="mb-0">
          <span className="mb-0">Information:</span>
          <span className="mb-0 col-6 float-lg-right font-weight-bold">{(data.wheelChairAccess != null) ? data.wheelChairAccess : '-'}</span>
        </div>
      </div>
      </>
      return meetingData;
    }
    else if (type === "Tips") {
      if (data.type !== []) {
        var types = data.type.slice(0, data.type.length).join(', ');
      }

      if (data.location.length > 0) {
        const dataList = [];
        Object.keys(data.location).map((value, i) => ( 
          dataList.push(data.location[value].loc)
        ))
        var location = dataList.slice(0, dataList.length).join(', ');
      }
      var tips = <div className="fontSize">
        <p className="mb-0  d-flex align-items-center"><span className="foodType listlunchtoipicon imgSprite"></span><span>{types ? types.replace(regex, '').substring(0, 20) + '...' : null}</span></p>
        <p className="mb-0  clear d-flex align-items-center"><span className="locationIcon listlunchtoipicon tipicon imgSprite"></span><span> {location ? location.replace(regex, '').substring(0, 15) + '...' : data.compelete_address ? data.compelete_address.replace(regex, '').substring(0, 18) + '..': null}</span></p>

      </div>
      return tips;
    }
  }

  function errorGetcoordinates() {
     if (
       navigator.platform.indexOf("iPhone") !== -1 ||
       navigator.platform.indexOf("iPod") !== -1 ||
       navigator.platform.indexOf("iPad") !== -1
     ) {
       setMessage(
         t("Provide access in") + ":"+ t("Settings") + ">" + t("Privacy")+ ">" + t("Location Services")+">" +t("Safari")
       );
     } else {
       setMessage(t("Error getting the user location")+","+t("please check device permission")+".");
     }
  }

  function getLocation() {
          navigator.geolocation.getCurrentPosition(
            getcoordinates,
            errorGetcoordinates
          );
  }


  function getcoordinates(position) {

    if ((navigator.platform.indexOf("iPhone") !== -1)
      || (navigator.platform.indexOf("iPod") !== -1)
      || (navigator.platform.indexOf("iPad") !== -1)) {
      var url = 'https://www.google.com/maps/dir/' + position.coords.latitude + ',' + position.coords.longitude + '/' + data.map_latitude + ',' + data.map_longitude;
        window.location.assign(url, "_blank");
    } 
    else {
      var urls = 'https://www.google.com/maps/dir/' + position.coords.latitude + ',' + position.coords.longitude + '/' + data.map_latitude + ',' + data.map_longitude;
      window.open(urls);
    }

  }

    
  const closeAlertPopup = () => {
    setMessage("");
  };
  const DeviceMode = localStorage.getItem("DeviceMode");
  const regex = /(<([^>]+)>)/ig;
  const imgsrc = type === ("Meeting") ? 
            (data.teaser_image === "" ? MeetingIcon : data.teaser_image)  : 
            (data.mobile_teaser_image !== "" ? data.mobile_teaser_image : data.teaser_image)
  return (
    <div>
      <Popup
        show={message !== "" ? true : false}
        onHide={closeAlertPopup}
        size={"lg"}
        title={"Alert"}
        body={message}
      />
      <div className={"card recent-detail py-2" + ( (DeviceMode === "Desktop") && (activeItem.id == data.id) ? " activeItem" : '')}>
        <div className="row no-gutters">
          <div className={type === ("Locations") ? "col-4  col-lg-3 locationimgs" : ( type === ("Meeting") ? "col-4  col-lg-3 meetingimgs" : "col-4 col-lg-3")} >
          { DeviceMode == "Desktop" ?
              <div className="post-img location-thum" onClick={() => RedirectDetailspages(data, type)}>
                <Image src={type === ("Meeting") ? (data.teaser_image === "" ? MeetingIcon : data.teaser_image)  : data.teaser_image} rounded fluid /*style={{width: '116px', height: '65px'}}*/ />
              </div>
            :
              <div className="post-img location-thum" onClick={() => RedirectDetailspages(data, type)}>
                <Image src={imgsrc != undefined || imgsrc != null ? imgsrc : ''} rounded fluid /*style={{width: '116px', height: '65px'}}*/ />
              </div>
            }
          </div>

          <div
            className={
              type === "Locations"
                ? "col-7 col-lg-8 padding-se loctionWrap"
                : type === "Tips"
                ? "col-7 col-lg-8 padding-se tipsWrap"
                : type === "News"
                ? "col-7 col-lg-8 padding-se newsWrap"
                : type === "Events"
                ? "col-7 col-lg-8 padding-se eventsWrap"
                : type === "Polls"
                ? "col-7 col-lg-8 padding-se pollsWrap"
                : type === "Surveys"
                ? "col-7 col-lg-8 padding-se Surveyswrap"
                : type === "Meeting"
                ? "col-6 col-lg-7 padding-se meetingsWrap"
                : "col-7 col-lg-8 padding-se"
            }
          >
            <div className="card-body p-0">
              <h5
                className="card-title width-adjust-class"
                onClick={() => RedirectDetailspages(data, type)}
              >
                {titleCompress(data, type)}
              </h5>
              <div className="card-text">{locationaddress(data, type)}</div>

              <div className="card-text">
                <div className="text-muted datewrap">
                  {renderDateformat(data, type)}
                  {/* <span>{renderCalltoAction(data, type)}</span> */}
                  <span className="actionButtons">
                    {renderCalltoAction(data, type)}
                  </span>
                </div>
                {renderDetailsUrl(data, type)}
              </div>
            </div>
          </div>
          <div
            className={
              type === "Locations"
                ? "col-1 col-lg-1 locationAction text-right desktopiconalign"
                : type === "Tips"
                ? "col-1 col-lg-1 tipsRatings desktopiconalign"
                : type === "Meeting"
                ? "col-2 col-lg-2 desktopiconalign meetingAction"
                : "col-1 col-lg-1 desktopiconalign"
            }
          >
            {renderActionIcons(type)}
            {renderLocationNavigation(data, type)}
          </div>
        </div>
      </div>
      {Question ? (
        <QuestionPopup
          data={data}
          show={true}
          entry_id={data.id}
          updateField={updateField}
          type={"list"}
          startDate={data.start_date}
          endDate={data.end_date}
          QuestionState={QuestionState}
        ></QuestionPopup>
      ) : null}
    </div>
  );
}

export default NewsItemDetails
