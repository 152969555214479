import React, { useState, useEffect } from 'react';

function MiniMap({ lats, long, mapaddress, type }) {

  const [address, setaddress] = useState(null); 

  useEffect(() => {
    if (type !== "addlocation") {
      const loc = mapaddress.address.replace(/\s/g, '+')
      const address = loc+'+' +mapaddress.street_number+'+' +mapaddress.postal_code+ '+' +mapaddress.city+ '+' +mapaddress.country
      setaddress(address)
    }else{
      const address = lats + ',' + long
      setaddress(address)
    }

}, [] );

  return (
    <div className="minimap">
      <div className="google-map">
      <iframe title = "map" className = "googlemapping" src={"https://www.google.com/maps/embed/v1/place?q="+address+"&zoom=10 &key= AIzaSyD9bXub7vSiflRrjDR_LoyneZrLrZx9PgU"}></iframe>
      </div>
    </div>
  )
}


export default MiniMap