import React, { useState } from 'react';
import { poll } from "../../Services/ApiEndPoints";
import { APICALL } from "../../Services/ApiServices";
import { t } from '../../Translation/Translation';
import { Modal } from "react-bootstrap";
import Alert from 'react-bootstrap/Alert';
import '../../Static/css/common.css';

export default function QuestionPopup({ data, show, entry_id, updateField, type, startDate, endDate, QuestionState }) {
    const [options, setoptions] = useState("");
    const [close, setclose] = useState(show);
    const get_id = localStorage.getItem("id");
    const [message, setmessage] = useState(false);

    const handleChange = (e) => {
        setoptions(e.target.value)
        if (e.target.value !== "") {
            setmessage(false)
        }
    }
    //saving data user for selecting the resoponese option 
    const saveUpdate = () => {
        if (options === '') {
            setmessage(true)
        } else {
            let data = {
                'user_id': get_id,
                'entry_id': entry_id,
                "type": "answer",
                "option_id": options,

            }
            APICALL.service(poll, "POST", data)
                .then(result => {
                    updateField(entry_id)
                });
            handleClose();
        }
    }
    //checking pollin status is active or not ?
    const isPollActive = () => {
        let pollStartdate = new Date(startDate);
        let pollenddate = new Date(endDate);
        let currentDate = new Date();
        if (currentDate >= pollStartdate && currentDate <= pollenddate) {
            return true
        }
        return false;
    }
    const handleClose = () => { 
        setclose(false) 
        QuestionState()
    };
    const closePopup = () => {
        setclose(false)
        QuestionState()
    }
    
    //showing popup for listview page 
    const listviewPolls = (data) => {
        return (
            <div>
                <div> {data.question}</div>
                {
                    Array.isArray(data.options) ? data.options.map((values, index) => (
                        <div className="col-12 p-0" key={index}>
                            <fieldset className="question-popup">
                                <label className="radiowrap">
                                    <input
                                        type="radio"
                                        name={values.option}
                                        value={values.option_id}
                                        onChange={handleChange}
                                        checked={parseInt(options) === values.option_id}
                                    />
                                    {values.option}
                                    <span className="radiomark"></span>
                                </label>

                            </fieldset>
                        </div>
                    )) : null
                }
            </div>
        )

    }
    return (
        <div>
            <Modal dialogClassName="modal-dialog-centered" show={close} onHide={handleClose}>
                <Modal.Body className="textcolor1 sectionTitle popups-title pt-4">
                    <div className="col-md-12 col-12 col-lg-12 ">
                        <div className="row">
                            <div className="col-lg-12 col-12">
                                {isPollActive() === true ? (type === "details" ?
                                    Object.keys(data).map((key) => (
                                        <div key={key}>
                                            <div className=""> {data[key].question}</div>
                                            {
                                                Array.isArray(data[key].options) ? data[key].options.map((values, index) => (
                                                    <div className="col-12 p-0" key={index}>
                                                        <fieldset className="question-popup">
                                                            <label className="radiowrap">
                                                                <input
                                                                    type="radio"
                                                                    name={values.option}
                                                                    value={values.option_id}
                                                                    onChange={handleChange}
                                                                    checked={parseInt(options) === values.option_id}
                                                                />
                                                                {values.option}
                                                                <span className="radiomark"></span>
                                                            </label>

                                                        </fieldset>
                                                    </div>
                                                )) : null}
                                        </div>
                                    )) : listviewPolls(data)) : <p>{t('Surveys time is completed')}</p>
                                }
                            </div>
                        </div>
                    </div>
                </Modal.Body>
                {
                    message === true ?
                        <Alert variant="danger" onClose={() => setmessage()} dismissible>
                            <p className='text-center'>{t('Please select one option .')} </p>
                        </Alert> : null
                }
                <Modal.Footer className="text-left justify-content-start votePopup py-4">
                    <button className="btn  closeButton m-0" variant="secondary" onClick={closePopup}>
                        {t('Close')}
                    </button>
                    <button className="bg-color btn  m-0" variant="primary" onClick={saveUpdate}>
                        {t('Submit')}
                    </button>
                </Modal.Footer>
            </Modal>
        </div>
    );
}

