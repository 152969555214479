import React, { useState, useEffect } from 'react';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import moment from 'moment';
import { EventUrl } from "../../Services/ApiEndPoints";
import { APICALL } from "../../Services/ApiServices";
import '../../Static/css/common.css';
import './calendar.css';
import { t } from '../../Translation/Translation'

export default function Calendar({updateField}) {
    const [startDate, setStartDate] = useState(new Date());
    const [Dates, setDate] = useState(new Date());
    
    useEffect(() => {
      const get_id = localStorage.getItem("id");
      let data = {
        'user_id': get_id,
      }
      APICALL.service(EventUrl, "POST", data)
        .then(result => {
          let resuting_date =[];
           if(result){
            Object.keys(result).map((key) => {
              if(result[key].start_date) {
                getDates(result[key].start_date, result[key].end_date, resuting_date);
              }
            })
            setDate(resuting_date);
           }
        })
        .catch(error => {
          console.error("Eventlist error : ", error);
        })    
    }, []);  

    //Calculate the difference between dates,
    function getDates(startDate, stopDate, resuting_date) {
      startDate = new Date(startDate);
      if(stopDate === null || stopDate === '') {
        resuting_date.push(startDate)
        return;
      }
      var currentDate = moment(startDate);
      var stopDates = moment(stopDate);
      while (currentDate <= stopDates) {
        resuting_date.push(new Date(moment(currentDate).format('YYYY-MM-DD') ))
          currentDate = moment(currentDate).add(1, 'days');
      }
      return;
      
  }
    function dateChanged(d){
     let date = moment(d).format("YYYY-MM-DD");
     updateField(date);
     setStartDate(d);
    }
    return (
      <div>
        <div className="container text-center calender p-0 mt-4">
        
        <DatePicker
        selected={startDate}
        todayButton={t("Today")}
        onChange={dateChanged}
        highlightDates={Dates} 
        //disabledKeyboardNavigation
        inline
        dateFormat = "dd-MM-yyyy"
        adjustDateOnChange
      />
      </div>
      {Object.keys(Dates).length >0 &&  
      <h5 className ="textcolor1 text-left mb-2 mt-3">{t('Events on the selected date')}</h5> }
   
      </div>
    );
}
