import React, { useState, useEffect } from "react";
import "./profile.css";
import PersonalDetails from "./PersonalDetails";
import Contact from './Contact'
import { useHistory } from "react-router-dom";
import { UserProfile } from "../../Services/ApiEndPoints";
import { APICALL } from "../../Services/ApiServices";
import { APICALL as AXIOS } from "../../Services/AxiosServices";
import { useAppContext } from "../../Config/contextLib";
import '../../Static/css/common.css';
import { t } from '../../Translation/Translation';
import Popup from "../../Utilities/Popup/modelPopup";
import BackButtonComponent from '../../Utilities/BackButtonComponent';
import BackButton from "../../Utilities/BackButton";

export default function Profile({ setProfileScreen, backButton }) {

  const [currentTab, setCurrentTab] = useState(1);
  const [selectedHobbies, setSelectedHobbies] = useState([]);
  const [profilepicdata, setprofilepicdata] = useState({});
  const [profilepicstate, setprofilepicstate] = useState(false);
  const [selectedKnownLanguages, setselectedKnownLanguages] = useState([])
  const [selectedMothertongue, setselectedMothertongue] = useState([])
  const [reportingornot, setreportingornot] = useState(false);
  const [message, setMessage] = useState("");
  const [birthDateinForm, setbirthDateinForm] = useState("");
  const [form, setState] = useState({})
  const history = useHistory();
  const { isAuthenticated, userHasAuthenticated } = useAppContext();
  const text = (isAuthenticated) ? "Update your profile" : "Complete your profile";
  let location = "";
  const [submitState, setsubmitState] = useState(false);
  const [backbuttonUrl, setbackbuttonUrl] = useState();
  const [urlState, seturlState] = useState(false);
  useEffect(() => {
    if (urlState === false && localStorage.getItem("id") !== undefined) {
      setbackbuttonUrl('/myprofile/' + localStorage.getItem("id"));
      seturlState(true);
    }
  }, []);

  const updateField = e => {
    //Updating the details
    setState({
      ...form,
      [e.target.name]: e.target.value
    });
  };

  //To fetch data from API
  useEffect(() => {
    const get_id = localStorage.getItem("id");
    APICALL.service(UserProfile + get_id, "GET",)
      .then(result => {
        if (result !== undefined || result !== '' || result !== null) {
          try {
            setState(result);
            if (result.birthDate !== undefined) {
              setbirthDateinForm(result.birthDate);
            }
            if (reportingornot === true) {
              setreportingornot(false);
            }
            if (currentTab === 2) {
              setCurrentTab(1);
            }
          } catch (e) {
            console.error(e);
          }
        }
      });
  }, [submitState]);


  if (form.location !== undefined) {
    location = Object.values(form.location)[0];
  }

  //Form submission handling function
  async function handleSubmit(event) {
    event.preventDefault();
    if (currentTab === 1) {
      setCurrentTab(2);
    } else {
      let data = {
        "funFact": form.funFact,
        "motherTongue": selectedMothertongue,
        "languages": selectedKnownLanguages,
        "userHobbies": selectedHobbies,
        "report_data": form.report,
        "birthdate": form.birthDate,
        "personalPhoneNumber": form.personalPhoneNumber,
        "personalEmail": form.personalEmail,
        "terms_and_conditions": true
      }
      const get_id = localStorage.getItem("id");
      // let responseresult;
      if (profilepicstate === true) {
        await AXIOS.service(UserProfile, "POST", profilepicdata)
          .then(response => {
           // eslint-disable-next-line no-unused-vars
           let responseresult = response.status
          })
          .catch(error => {
            console.error(error)
          })
      }
      APICALL.service(UserProfile + get_id, "PUT", data)
        .then(result => {
          if (parseInt(result.id) === parseInt(get_id)) {
            if (isAuthenticated) {
              if (localStorage.getItem('DeviceMode') !== "Desktop") {
                setProfileScreen();
              }
              else {
                setsubmitState(submitState ? false : true)
                setProfileScreen();
              }
            } else {
              userHasAuthenticated(true);
              history.replace("/");
            }
          }
        })
        .catch(error => {
          setMessage(
            t("Error in updating profile")
          );
        })
    }
  }

  //To update changes in hobbies selection
  function updateHobbies(hobbies) {
    setSelectedHobbies(hobbies);
  }

  //To update changes in other languages selection
  function updateOtherLanguages(otherLanguages) {
    setselectedKnownLanguages(otherLanguages);
  }

  //To update change in mother tongue
  function updateMotherTongue(mothertongue) {
    setselectedMothertongue(mothertongue);
  }

  const closeAlertPopup = () => {
    setMessage("");
  }

  //To move to previous tab
  function previousTab() {
    if (currentTab === 2) {
      setCurrentTab(1);
    }
  }

  //To update profile picture
  function changeavatar(imagedata) {
    setprofilepicdata(imagedata);
    setprofilepicstate(true);
  }

  function updatereportdatastatus(e) {
    setreportingornot(reportingornot ? false : true);
  }

  //when user changes birthday date
  let birthDateInput;
  function handleBirthdate(date) {
    if (date !== null) {
      birthDateInput = date.toISOString()
    }
    else {
      birthDateInput = birthDateinForm
    }
    setState({
      ...form,
      "birthDate": birthDateInput
    });
  }

  function updatepersonalnumber(e) {
    setState({
      ...form,
      "personalPhoneNumber": e
    });
  }
  
  // back button funtionality
  function historyBack() {
    let response = BackButton("/myprofile");
    if (localStorage.getItem('isAuthenticated') === 'false') {
      history.push('/login')
    }
    else if (response === false) {
      backButton(false);
      history.push('/myprofile');
    }
    else {
      history.push(response);
    }
  }

  const [hideAndSHow, sethideAndSHow] = useState(true);

  function hideFunction() {
    sethideAndSHow(hideAndSHow ? false : true);
  }

  return (

    <section className="container customContainer">
      {hideAndSHow ? <div className="col-md-12 topsection-wrap  mt-0 px-0 pt-4 mb-4 pb-2">
        {localStorage.getItem('DeviceMode') !== "Desktop" ? <>
          <p className="browserback" onClick={historyBack}> back </p>
          {backbuttonUrl && <BackButtonComponent backButtonLink={backbuttonUrl} />}
        </> : null}
        <h1 className="pagetitle textcolor1 text-center">{t(text)}</h1>
      </div> : null}
      {hideAndSHow ? <ul className="nav nav-tabs profileTab mt-4">
        <li className="nav-item col-6 p-0">
          <div
            className={
              currentTab === 1
                ? "nav-link innerpagetitle active text-center switchtab tab-anchor"
                : "nav-link innerpagetitle text-center switchtab tab-anchor"
            }
            data-toggle="tab"
          >
            1. {t("Personal details")}
          </div>
        </li>
        <li className="nav-item col-6 p-0">
          <div
            className={
              currentTab === 2
                ? "nav-link innerpagetitle active switchtab text-center tab-anchor"
                : "nav-link innerpagetitle text-center switchtab tab-anchor"
            }
            data-toggle="tab"
          >
            2. {t("Contact")} & {t("about")}
          </div>
        </li>
      </ul> : null}
      <Popup
        show={message !== "" ? true : false}
        onHide={closeAlertPopup}
        size={"lg"}
        title={"Alert"}
        body={message}
      />
      {currentTab === 1 && (
        <PersonalDetails
          onSubmit={handleSubmit}
          updateField={updateField}
          form={form}
          location={location}
          reportingornot={reportingornot}
          updatereportdatastatus={updatereportdatastatus}
        />
      )}
      {currentTab === 2 && (
        <Contact
          onSubmit={handleSubmit}
          updateField={updateField}
          form={form}
          updateHobbies={updateHobbies}
          previousTab={previousTab}
          changeavatar={changeavatar}
          selectedHobbies={selectedHobbies}
          updateOtherLanguages={updateOtherLanguages}
          selectedKnownLanguages={selectedKnownLanguages}
          updateMotherTongue={updateMotherTongue}
          selectedMothertongue={selectedMothertongue}
          handleBirthdate={handleBirthdate}
          updatepersonalnumber={updatepersonalnumber}
          hideFunction={hideFunction}
        />
      )}
    </section>
  );
}
