import React, { useState, useEffect } from 'react';
import './Login.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { useHistory } from "react-router-dom";
import { LoginUrl } from "../../Services/ApiEndPoints"
import { APICALL } from "../../Services/ApiServices"
import Alert from 'react-bootstrap/Alert'
// import ModelLR from './images/modelLR.jpg';

import '../../Static/css/common.css'

const ForgotPassword = props => {
  // export default function OtpVerify() {
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');

  const history = useHistory();
  let DesktopMode;
  // check device is mobile or desktop
  if(localStorage.getItem('DeviceMode')==='Desktop'){
    DesktopMode=true;
  }
  else{
    DesktopMode=false;
  }

  useEffect(() => {
    console.log(props);
    // console.log(props.location.state.number)
    // Auth.currentCredentials();

  }, []);


  //send email to reset password function
  function sendEmail(event) {
    event.preventDefault();

    let data = {
      "actions": "forgot-password",
      "email": email
    }

    APICALL.service(LoginUrl, "POST", data)
      .then(result => {
        // console.log(result)
        // console.log("Axios Response  : ", result[0].lastLoginDate);
        if (result.status !== 200) {
          setMessage(result.message)
        } else {
          //send email function
          setMessage("A password reset link has been sent to your email")
        }
      })
      .catch(error => {
        console.log(error)
      })
  }

const ForgetPasswordPage=()=>{
  return(
    <section className="container customContainer pt-5">
      <div className="col-md-12 pl-2">
        <div onClick={() => history.goBack()}><p className="browserback">back</p></div>
      </div>

      {message.length > 0 &&
        <Alert variant="danger" onClose={() => setMessage("")} dismissible>
          <p>{message}</p>
        </Alert>
      }
      <form onSubmit={sendEmail} className="loginForm col-md-12 col-lg-6 mt-5 forgotform TabletLayout">
        <div className="text-left col-md-12 p-0">
          <p className="textcolor11 mt-4 mb-2 pagetitle">Forgot password</p>
        </div>
        <div className="form-group">
          <label className="textcolor1 emailLabel">Email</label>
          <input type="text" name="email" className="form-control" value={email} id="email" placeholder="Email" onChange={(e) => setEmail(e.target.value)} />
          <p className="mt-3">Please enter your registered email address.</p>
          <p>A password reset link will be sent to your email.</p>
        </div>

        {<div className="text-center  button-bottom">
          <button className="btn btn-block mybtn bg-color tx-tfm">Reset Password</button>
        </div>}
      </form>
    </section>
  )
}
  return (
    <>
    {/*
      * Check desktop mode and split div for desktop 
     */}
      {
     DesktopMode === true ? <div className="col-md-12 layoutSpace">
            
     <div className ="col-md-5 float-lg-left login-bg">
    
     </div>
     <div className = "col-md-7 float-lg-left tablet-full-width">
      {ForgetPasswordPage()}
     </div>
   </div>:
    ForgetPasswordPage()
   }
    </>
  );
}

export default ForgotPassword;