import React, { useState, useEffect } from "react";
import { Eshop } from "../../Services/ApiEndPoints";
import { APICALL } from "../../Services/ApiServices";
import SinglePageLayout from "../Common/singlePageLayout";

function EShop() {
  const [EshopData, setEshopData] = useState();

  useEffect(() => {
    let User_id = localStorage.getItem("id");
    const res = {
      user_id: User_id,
    };
    APICALL.service(Eshop, "POST", res).then((result) => {
      if (result != null) setEshopData(result.eShop);
    });
  }, []);

  return (
    <SinglePageLayout header={"E-Shop"} layoutData={EshopData} url={"/eshop"} />
  );
}

export default EShop;
