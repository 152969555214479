import React, { useState,useEffect } from 'react';
import { TermsConditions, UserProfile } from "../../Services/ApiEndPoints";
import { APICALL } from "../../Services/ApiServices";
import {t} from '../../Translation/Translation';
import { Modal } from "react-bootstrap";
import './Settings.css';
import '../../Static/css/common.css';
import LoadingIcon from '../../Utilities/LoadingIcon';
import Alert from "react-bootstrap/Alert";

export default function TermsConditionModal({isAuthenticated, get_id}) {
    const [show, setShow] = useState(true);
    const handleClose = () => setShow(false);
    const [termsCondions,setTermsConditions]=useState(".");
    const [message, setMessage] = useState('');
    const [form, setState] = useState({
        "termsStatus": false
      });
    //  const [termsCondition,settermsCondition]=useState(JSON.parse(localStorage.getItem("termsCondition")))
      // const [isAuthenticated, userHasAuthenticated] = useState(
      //   JSON.parse(localStorage.getItem("isAuthenticated"))
      // );
    // const handleShow = () => setShow(true);
    let User_id=localStorage.getItem("id");
    useEffect(() => {
    const res={
        "user_id" : get_id
      }
      APICALL.service(TermsConditions, "POST", res)
      .then(result => {
        if(result!=null)
        setTermsConditions(termsCondions =>result.terms_and_conditions);
      }).catch(error => {
            console.error("profile error : ", error);
        })
    },[]);
    const updateTerms= async e=>{
        e.preventDefault();
        setMessage("");
        if (!JSON.parse(form.termsStatus)) {
          setMessage("Please accept terms & conditions.");
          return;
        }
        else{
          // updateAPI();
          //var val = e.target.value;
          const termsData={
            "terms_and_conditions": true
          }
          await APICALL.service(UserProfile + User_id, "PUT", termsData).then((result) => {
            if (result != null) {
              setTermsConditions(result.terms_and_conditions);
              localStorage.setItem("termsCondition",true);
              handleClose();
            }
          });
      
        }
      }      
/**
 * converting date format dd-m-yyyy hr-m-s to yyyy-mm-dd format
 */
    // }         
    const updateField = e => {
        //Updating the details
        if (e.target.name === "termsStatus") (e.target.value = !JSON.parse(e.target.value));
        setState({
          ...form,
          [e.target.name]: e.target.value
        });   
      };
      function createMarkup() {
        if(termsCondions){
          let data = termsCondions.replaceAll("<p></p>", "<br />");
          return {__html: data};
        }
        return {__html: termsCondions};
      } 
  return (
    isAuthenticated && (
      <div>
        {/* checking conditions to show popup */}
        {
          <Modal show={show} className="text-center">
            <Modal.Header className="mb-0 pb-0">
              <div className="col">
                <h1 className="pagetitle textcolor1 text-center">
                  {t("Terms and conditions")}
                </h1>
              </div>
            </Modal.Header>
            <Modal.Body className="mt-0 pt-0">
              {termsCondions === "." ? (
                <p className="text-center">{t("Loading")}.....</p>
              ) : null}
              <div className="col-md-12">
                {message.length > 0 && (
                  <Alert
                    variant="danger"
                    onClose={() => setMessage("")}
                    dismissible
                  >
                    {/* <Alert.Heading>Oh snap! You got an error!</Alert.Heading> */}
                    <p>{message}</p>
                  </Alert>
                )}
              </div>
              <div className="col-md-12 my-4 scroll-y">
                <div
                  className="Richtexterditor text-left"
                  dangerouslySetInnerHTML={createMarkup()}
                />
              </div>
              <div className="col-md-12 col-12 ">
                <label className="form-check-label checkboxWrap">
                  <input
                    className="form-check-input"
                    name="termsStatus"
                    value={form.termsStatus}
                    type="checkbox"
                    onChange={updateField}
                  />
                  <span className="text-muted">
                    {t("I accept the Terms")} &{" "}
                    {t("Conditions mentioned above")}.
                  </span>
                  <span className="checkmark"></span>
                </label>
                <div className="col-md-12 text-center mt-5 p-0 mb-5">
                  <button
                    type="submit"
                    className="btn mybtn btn-light tx-tfm bg-color w-100"
                    onClick={updateTerms}
                  >
                    {t("Submit")}
                  </button>
                </div>
              </div>
            </Modal.Body>
            <LoadingIcon />
          </Modal>
        }
      </div>
    )
  );
}