import React from "react";
import { Calendar, momentLocalizer } from "react-big-calendar";
import moment from "moment";
// import myEventsList from "./myEventsList";
import "react-big-calendar/lib/css/react-big-calendar.css";

const localizer = momentLocalizer(moment);

function BigScheduleCalendar(props) {
  function eventStyleGetter(event, start, end, isSelected) {
    // console.log(event);
    var backgroundColor = '#' + event.hexColor;
    var style = {
        backgroundColor: backgroundColor,
        // borderRadius: '0px',
        // opacity: 0.8,
        // color: 'black',
        // border: '0px',
        // display: 'block',
        cursor: backgroundColor === "#e4a6aa" ? "default" : "pointer",
    };
    var title_style = 'title_sytle'
    return {
        style: style,
        className: title_style
    };
 }

  let formats = {
    timeGutterFormat: 'HH:mm',
    eventTimeRangeFormat: (e) => {
      // console.log("asd", e)
      return ""
    }
    // timeGutterFormat: 'HH:mm',
    // eventTimeRangeFormat: range =>
    //   `${localizer.format(range.start, 'HH:mm')} – ${localizer.format(range.end, 'HH:mm')}`,
  }

  const CustomEvent = (event) => {
    let e = event.event;
    // console.log(event)
    return (
      <>
          {e.host.fullname ? <small>{" ", e.host.fullname.charAt(0).toUpperCase() + e.host.fullname.slice(1)}{" :  "}</small> : ""}
          <small>{" "}{e.title}{" "}</small>
        <small>{localizer.format(e.start, 'HH:mm')} – {localizer.format(e.end, 'HH:mm')}</small>
      </>
      
    )
  }

  function titlechange(e) {
    const Title = <>
    {e.host.fullname ? <small>{" ", e.host.fullname.charAt(0).toUpperCase() + e.host.fullname.slice(1)}{" :  "}</small> : ""}
    <small>{" "}{e.title}{" "}</small>
    </>
    return Title
  }

  return (
    <React.Fragment>
        <Calendar
          localizer={localizer}
          formats={formats}
          // events={myEventsList}
          events={props.schedulerData}
          components={{
            event: CustomEvent
          }}
          // step={60}
          // view={"day"}
          defaultView={"day"}
          startAccessor="start"
          endAccessor="end"
          // defaultDate={new Date()}
          date={props.date}
          defaultDate={props.date}
          toolbar={false}
          onNavigate={true}
          // titleAccessor={(e) => titlechange(e)}
          // views={allViews}
          onSelectEvent={(e) => props.onEventselect(e)}
          // onDrillDown={console.log("event selcted")}
          style={{ height: 500 }}
          onSelectSlot={(e) => props.onSlotselect(e)}
          selectable={true}
          eventPropGetter={eventStyleGetter}
          // onNavigate={}
          scrollToTime={new Date()}
        />
        </React.Fragment>
  );
}

export default BigScheduleCalendar;
