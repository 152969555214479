import React, { useState, useEffect } from 'react';
import './Event.css';
import { EventUrl } from "../../Services/ApiEndPoints";
import { APICALL } from "../../Services/ApiServices";
import Calendar from './Calendar';
import moment from 'moment';
import ItemDetails from "../Common/PageItemDetails";
import { t } from '../../Translation/Translation';
import { useHistory } from 'react-router-dom';
import '../../Static/css/common.css';
import Eventdetail from './EventDetails';
import { useParams } from "react-router";
import BackButtonComponent from '../../Utilities/BackButtonComponent';
import previewFn from '../../Common/Preview';
import BackButton from "../../Utilities/BackButton";

function Events() {
  const [Eventlist, setEventlist] = useState()
  const [userSelectedEvents, setuserSelectedEvents] = useState({})
  const [futureEvents, setFutureEvents] = useState({})
  const history = useHistory();
  const [DeviceMode, setDeviceMode] = useState(false);
  const [defaultitem, setdefaultitem] = useState();
  const [listviewMode, setlistviewMode] = useState(false)
  const [DetailsViewStatus, setDetailsViewStatus] = useState(true)
  let params = useParams();
  const [backbuttonUrl, setbackbuttonUrl] = useState();
  const [urlState, seturlState] = useState(false);

  useEffect(() => {
    if (urlState === false) {
      if (params.id === undefined) {
        setbackbuttonUrl('/events');
      } else {
        setbackbuttonUrl('/events/' + params.id);
      }
      seturlState(true);
    }
  }, [])

  //when user click back button and goto pervious page 
  function historyBack() {
    let response = BackButton();
    history.push(response);
  }

  useEffect(() => {
    //checking Decvice mode
    if (localStorage.getItem('DeviceMode') === 'Desktop') {
      setDeviceMode(true);
    }
    const get_id = localStorage.getItem("id");
    let param = previewFn(params);
    let data = {
      'user_id': get_id,
      'event_date': moment(Date.now()).format("YYYY-MM-DD"),
      'param': param,
    }
    getApiResult(data)
  }, []);


  const getApiResult = e => {
    APICALL.service(EventUrl, "POST", e)
      .then(result => {
        if (result) {
          let startDate;
          let futureEvents = {}
          let userSelectedEvents = {};
          let defaultkey = {}
          let currentDate = new Date(e.event_date).setHours(0, 0, 0, 0);
          let dateTime;
          Object.keys(result).map((value, i) => {
            startDate = new Date(result[value].start_date);
            dateTime = startDate.getTime() + value;
            //user select date and currrent date match storing userselectedEvent state
            if (startDate.setHours(0, 0, 0, 0) <= currentDate) {
              userSelectedEvents[dateTime] = result[value]
              //anthore future Events
            } else {
              futureEvents[dateTime] = result[value]
            }
            // dataList.push(result[value])
            // setdataList(dataList)   
          })
          //user select date storing the records for userSelectedEvents state
          if (Object.keys(userSelectedEvents).length > 0) {
            setDetailsViewStatus(true);
            ////Always load the first item from List View by default in detail view page
            defaultkey = Object.keys(userSelectedEvents).sort()[0]
            setdefaultitem({ "id": userSelectedEvents[defaultkey].id, "type": "Events" });
          } else {
            setdefaultitem();
          }
          if (params.id !== undefined) {
            setdefaultitem({ "id": params.id, "type": "Events" });
            setlistviewMode(true);
          }
          setuserSelectedEvents(userSelectedEvents)
          setFutureEvents(futureEvents)
          setEventlist(result)
        }

      })
      .catch(error => {
        console.error("Eventlist error : ", error);
      })
  }
  //TO update detail page when user click on list item
  const updatesId = e => {
    setdefaultitem(e);
    if (localStorage.getItem('DeviceMode') === 'Desktop') {
      setlistviewMode(false);
    } else {
      setlistviewMode(true);
    }
  }
  //user sels=ect different date updating Eventlist state
  const updateField = e => {
    //setSelectDate(e)
    const get_id = localStorage.getItem("id");
    let param = previewFn(params);
    let data = {
      'user_id': get_id,
      'event_date': e,
      'param': param,
    }
    getApiResult(data)
  }
  //Comparing  starting date & time and End date & time. is vote button visible or not ?
  const comapredateformat = (startDate, endDate) => {
    let comparedates = (endDate == null) ? startDate : endDate
    let Endates = new Date(comparedates);
    let currentDate = new Date();
    currentDate = currentDate.setHours(0, 0, 0, 0);
    if (Endates < currentDate) {
      return true;
    } else {
      return false;
    }
  }
  //backbotton form details page to listview
  const backButton = (e) => {
    setlistviewMode(e)
  }


  const ListItemMode = () => {
    return (
      <div className="container customContainer">
        <div className="col-md-12 topsection-wrap  mt-0 px-0 pt-4">
          <p className={DeviceMode === true ? "hide-class" : "browserback"} onClick={historyBack}>back</p>
          {backbuttonUrl && <BackButtonComponent backButtonLink={backbuttonUrl} />}
          <h1 className="pagetitle textcolor1 text-center">{t("Events")}</h1>
        </div>

        {/* <SearchBox onSearchValue={SearchElements} searchVal={searchVal}/> */}
        <Calendar updateField={updateField} />
        <div className="post">
          <div
            className={
              Object.keys(userSelectedEvents).length > 0 &&
                Object.keys(futureEvents).length > 0
                ? "current-post"
                : ""
            }
          >
            {Object.keys(userSelectedEvents).length > 0 ? (
              Eventlist !== undefined &&
              Eventlist.status !== "404" &&
              Object.keys(userSelectedEvents)
                .sort()
                .map((value, i) => {
                  return (
                    <div
                      className={
                        comapredateformat(
                          userSelectedEvents[value].start_date,
                          userSelectedEvents[value].end_date
                        ) === true
                          ? "disableLink mt-4"
                          : "undisabed mt-2"
                      }
                      key={i}
                    >
                      <ItemDetails
                        data={userSelectedEvents[value]}
                        type="Events"
                        updatesId={updatesId}
                        activeItem={defaultitem}
                      />
                    </div>
                  );
                })
            ) : (
              <div
                className={
                  Object.keys(futureEvents).length > 0 &&
                    Eventlist !== undefined &&
                    Eventlist.status !== "404"
                    ? "text-center current-post"
                    : "text-center"
                }
              >
                {Eventlist !== undefined && (
                  <div>
                    <hr className="w-100" />
                    <h6 className="noevents text-center my-3">
                      {t("There are no events scheduled on this day")}{" "}
                    </h6>
                  </div>
                )}
              </div>
            )}
          </div>
          {Eventlist !== undefined &&
            Eventlist.status != "404" &&
            Object.keys(futureEvents)
              .sort()
              .map((value, i) => {
                return (
                  <div
                    className={
                      comapredateformat(
                        futureEvents[value].start_date,
                        futureEvents[value].end_date
                      ) === true
                        ? "disableLink mt-4"
                        : "undisabed mt-2"
                    }
                    key={i}
                  >
                    {i === 0 ? (
                      <h5 className="textcolor1 text-left my-3 ">
                        {t("Upcoming events")}
                      </h5>
                    ) : null}
                    <ItemDetails
                      data={futureEvents[value]}
                      type="Events"
                      updatesId={updatesId}
                    />
                  </div>
                );
              })}

          {/* {Eventlist != undefined && Eventlist.status == '404'  && <div className = 'text-center'> <hr className="w-100" />There are no events scheduled on this day  <hr className="w-100" /></div>} */}
        </div>
      </div>
    );
  }
  /* 
    * check and split the page for desktop device by using DesktopMode variable
    * display list view and detalview side by side
    */
  return (
    <div >
      {
        DeviceMode === true ? <div className="Desktop-mode-list-and-detail-view layoutSpace ">
          <div className="Desktop-mode-list-view float-lg-left col-lg-5 pr-0">
            <div className="customscroll">{ListItemMode()}</div>
          </div>
          <div className="Desktop-mode-Detail-view float-lg-left col-lg-7 pr-4 pl-1">
            <div className="customscroll">  {(Object.keys(userSelectedEvents).length !== 0 && defaultitem) ? defaultitem && < Eventdetail updatesEntityId={defaultitem} /> : defaultitem ? < Eventdetail updatesEntityId={defaultitem} /> : null} </div>
          </div>
        </div> : (listviewMode === false ? ListItemMode() : <Eventdetail updatesEntityId={defaultitem} backButton={backButton} />)
      }

    </div>
  );
}
export default Events