import React, { useEffect, useState } from "react";

const InstallPWA = () => {
  const [supportsPWA, setSupportsPWA] = useState(false);
  const [promptInstall, setPromptInstall] = useState(null);
  const [appType, setappType] = useState("");

  useEffect(() => {
    window.addEventListener("DOMContentLoaded", () => {
      let displayMode = "browser";
      if (navigator.standalone) {
        displayMode = "standalone-ios";
      } else if (window.matchMedia("(display-mode: standalone)").matches) {
        displayMode = "standalone";
      } else if (checkIos()){
        displayMode = "browser-ios";
      }
      // Log launch display mode to analytics
      console.log("InstallPWA : DISPLAY_MODE_LAUNCH:", displayMode);
      localStorage.setItem("appType", displayMode); 
      setappType(displayMode);
    });

    return () => window.removeEventListener("transitionend", handler);
  });
  console.log("InstallPWA");
  const handler = (e) => {
    e.preventDefault();
    console.log("InstallPWA : we are being triggered :D");
    setSupportsPWA(true);
    setPromptInstall(e);
  };
  window.addEventListener("beforeinstallprompt", handler);
  window.addEventListener("appinstalled", (e) => {
    console.log("success app install!");
    setSupportsPWA(false);
  });

 // }, []);
  
    const checkIos = () => {
    // Detects if device is on iOS
    const isIos = () => {
      const userAgent = window.navigator.userAgent.toLowerCase();
      return /iphone|ipad|ipod/.test(userAgent);
    };
    // Detects if device is in standalone mode
    const isInStandaloneMode = () =>
      "standalone" in window.navigator && window.navigator.standalone;

    
    // Checks if should display install popup notification:
    if (isIos() && !isInStandaloneMode()) {
     // this.setState({ showInstallMessage: true });
     // console.log("checking the code install");
      return true;
    }
  }

  const onClick = (evt) => {
    evt.preventDefault();
    if (!promptInstall) {
      return;
    }
    promptInstall.prompt();
  };
    if (!supportsPWA) {
      console.log("InstallPWA : !supportsPWA");
      if (checkIos()) {
        return (
          <p className="float-right">
            To install this web app on you phone: open from safari the share
            menu then select add to home screen
          </p>
        );
      } else if (appType.startsWith("standalone")) {
        return <p className="float-right">App installed</p>;
      } else if (appType.startsWith("browser")) {
        return <p className="float-right">Can't isntall app</p>;
      } else {
        return <p className="float-right">App installed</p>;
      }
  }
  return (
    <button
      className="link-button bg-color float-right"
      id="setup_button"
      aria-label="Install app"
      title="Install app"
      onClick={onClick}
    >
      Install
    </button>
  );
};

export default InstallPWA;
