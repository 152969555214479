import React, { useState, useEffect } from "react";
import "./Myprofile.css";
import 'antd/dist/antd.css';
import { Avatar } from 'antd';
import { UserOutlined } from '@ant-design/icons';
import { UserProfile } from "../../Services/ApiEndPoints";
import { APICALL } from "../../Services/ApiServices";
import moment from 'moment';
import { t } from '../../Translation/Translation';
import PhoneInput from 'react-phone-input-2';
import { useHistory, Link } from 'react-router-dom';
import Profile from './UpdateProfile';
import { useAppContext } from "../../Config/contextLib";
// import modelLR from "../../Static/images/modelLR.jpg";
import { useParams } from "react-router";
import BackButtonComponent from '../../Utilities/BackButtonComponent'
import BackButton from "../../Utilities/BackButton";

function Myprofile() {

    const [profiledata, setprofiledata] = useState({})
    const [profilehobbies, setprofilehobbies] = useState([])
    const [location, setLocation] = useState('');
    const [locationID, setLocationID] = useState();
    const history = useHistory();
    const [DeviceMode, setDeviceMode] = useState(false);
    const [listviewMode, setlistviewMode] = useState(false);
    // eslint-disable-next-line no-unused-vars
    const { isAuthenticated, userHasAuthenticated } = useAppContext();
    let params = useParams();
    const [backbuttonUrl, setbackbuttonUrl] = useState();
    const [urlState, seturlState] = useState(false);

    useEffect(() => {
        if (urlState === false) {
            if (params.id === undefined) {
                setbackbuttonUrl('/myprofile');
            } else {
                setbackbuttonUrl('/ourlocations/' + params.id);
            }
            seturlState(true);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    function historyBack() {
        let response = BackButton();
        history.push(response);
    }

    useEffect(() => {
        //checking Device mode 
        if (localStorage.getItem('DeviceMode') === 'Desktop') {
            setDeviceMode(true);
        }
        var pro_id = localStorage.getItem("id");
        APICALL.service(UserProfile + pro_id, "GET")
            .then(result => {
                let resulting_data = [];
                setprofiledata(result);
                Object.keys(result.userHobbies).map((key, i) => (
                    resulting_data[i] = result.userHobbies[key]))
                setprofilehobbies(resulting_data);//storing hobbies as an array in profilehobbies
                Object.keys(result.location).map((key) => {
                    return (
                        setLocation((result.location)[key]),
                        setLocationID(key))
                });
            });
    }, [listviewMode]);

    var hobbieslist = "";
    if (profilehobbies.length > 0) {
        hobbieslist = profilehobbies.map((m, i) =>
            <span key={m}>{m}{profilehobbies[i + 1] ? ', ' : ''}</span>
        );
    }

    function setProfileScreen() {
        setlistviewMode(listviewMode ? false : true);
    }

    //backbotton form details page to listview
    const backButton = (e) => {
        setlistviewMode(e)
    }

    const listMode = () => {
        return (
            <div className='container mb-5 customContainer'>
                <div className="col-md-12  px-0 col-12 pt-4">
                    {localStorage.getItem('DeviceMode') !== "Desktop" ? <>
                        <div className="row">
                            <div className="col-2"> <p className="browserback" onClick={historyBack}>back</p></div>
                            {backbuttonUrl && <BackButtonComponent backButtonLink={backbuttonUrl} />}
                            <h1 className="col-8 textcolor1 pagetitle text-center">{t('My Profile')}</h1>
                            <span className="textcolor1 col-2 edit-button">
                                <h6 className="textcolor1" onClick={() => setlistviewMode(true)}>{t('Edit')}</h6>
                            </span>
                        </div>
                    </> :
                        <div className="row">
                            <h1 className="col-12 textcolor1 pagetitle text-center">{t('My Profile')}</h1>
                        </div>}
                </div>
                <div className="col-md-12 col-12 px-0 text-center pt-3 py-2">
                    <Avatar size={85} icon={<UserOutlined />} src={profiledata.profile_photo} />
                </div>
                <div className="col-12 col-md-12 px-0 text-center">
                    <h4 className="textcolor1 text-center mt-2">{profiledata.firstName} {profiledata.lastName}</h4>
                    <h6>{profiledata.userJobFunction}</h6>

                </div>
                <div className="col-md-12 col-12 px-0 my-4">
                    <h5 className={((profiledata.department === '') || (profiledata.department === null))
                        ? "hide-class" : "textcolor1 font-weight-normal"}>{t('Department')}</h5>
                    <h5 className="font-weight-normal">{profiledata.department}</h5>
                </div>
                <div className="col-md-12 col-12 px-0 my-4">
                    <h5 className={((location === "") || (location === null))
                        ? "hide-class" : "textcolor1 font-weight-normal"}>{t('Location')}</h5>
                    <h5>{((location !== "") || (location != null) || (location !== undefined))
                        ? <Link to={('/ourlocations/' + locationID)}><h5 className="font-weight-normal"><span>{location}</span></h5></Link> : null}</h5>
                </div>
                <div className="col-md-12 col-12 px-0 my-4">
                    <h5 className={((profiledata.workEmail === '') || (profiledata.workEmail === null)) ? "hide-class" : "textcolor1 font-weight-normal"}>{t('Work email address')}</h5>
                    <h5 className="font-weight-normal">{profiledata.workEmail}</h5>
                </div>
                <div className={((profiledata.workPhoneNumber === '') || (profiledata.workPhoneNumber === null)) ? "hide-class" : "col-md-12 col-12 px-0 my-4 flag-phone"}>
                    <h5 className={((profiledata.workPhoneNumber === '') || (profiledata.workPhoneNumber === null)) ? "hide-class" : "textcolor1 font-weight-normal"}>{t('Work phone number')}</h5>
                    {profiledata.workPhoneNumber ? <h5 className="font-weight-normal"><PhoneInput
                        // country={'us'}
                        value={profiledata.workPhoneNumber}
                        updateField
                        disabled
                    /></h5> : null}
                </div>
                <div className="col-md-12 col-12 px-0 my-4">
                    <h5 className={profilehobbies.length > 0
                        ? "textcolor1 font-weight-normal" : "hide-class"}>{t('Hobbies')}</h5>
                    <h5 className="font-weight-normal">{hobbieslist}</h5>
                </div>
                <div className={((profiledata.funFact === '') || (profiledata.funFact === null))
                    ? "hide-class" : "col-md-12 col-12 px-0 my-4"}>
                    <h5 className="textcolor1 font-weight-normal">{t('Fun facts')}</h5>
                    <h5 className="font-weight-normal">{profiledata.funFact}</h5>
                </div>
                <div className="col-md-12 col-12 px-0 my-4">
                    <h5 className={((profiledata.personalEmail === '') || (profiledata.personalEmail === null))
                        ? "hide-class" : "textcolor1 font-weight-normal"}>{t('Private email address')}</h5>
                    <h5 className="font-weight-normal">{profiledata.personalEmail}</h5>
                </div>
                <div className={((profiledata.personalPhoneNumber === '') || (profiledata.personalPhoneNumber === null))
                    ? "hide-class" : "col-md-12 col-12 px-0 my-4 flag-phone"}>
                    <h5 className="textcolor1 font-weight-normal">{t('Private phone number')}</h5>
                    {profiledata.personalPhoneNumber ? <h5 className="font-weight-normal"><PhoneInput
                        // country={'us'}
                        value={profiledata.personalPhoneNumber}
                        updateField
                        disabled
                    /></h5> : null}
                </div>
                <div className={((profiledata.birthDate === '') || (profiledata.birthDate === null))
                    ? "hide-class" : "col-md-12 col-12 px-0 my-4"}>
                    <h5 className="textcolor1 font-weight-normal">{t('Birthdate')}</h5>
                    {profiledata.birthDate && <h5 className="font-weight-normal bday">{moment(profiledata.birthDate).format('DD/MM/YYYY')}</h5>}
                </div>
            </div>

        )
    }

    const newUserMode = () => {
        return (
            <div class="login-bg"></div>
        )
    }

    return (
        <div>
            {
                DeviceMode === true ? <div className={isAuthenticated ? "Desktop-mode-list-and-detail-view layoutSpace" : "first-time-login-class"}>
                    <div className={isAuthenticated ? "Desktop-mode-list-view float-lg-left col-lg-5 pr-0" : "col-md-5 float-lg-left pl-0"}>
                        <div className={isAuthenticated ? "customscroll" : "first-time-login-class"}> {isAuthenticated ? listMode() : newUserMode()}</div>
                    </div>
                    <div className={isAuthenticated ? " Desktop-mode-Detail-view float-lg-left col-lg-7 pr-4 pl-1" : "Desktop-mode-Detail-view float-lg-left col-lg-7 pr-0 pl-1"}>
                        <div className="customscroll">   {<Profile setProfileScreen={setProfileScreen} />}</div>
                    </div>
                </div> : isAuthenticated ? (listviewMode === false && params.id === undefined ? listMode() : <Profile setProfileScreen={setProfileScreen} backButton={backButton} />) : (<Profile setProfileScreen={setProfileScreen} />)
            }
        </div>
    );
}

export default Myprofile