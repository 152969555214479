import React, { useState, useEffect } from 'react';
import './News.css';
import { NewsUrl } from "../../Services/ApiEndPoints";
import { likes } from "../../Services/ApiEndPoints";
import { APICALL } from "../../Services/ApiServices";
import Image from 'react-bootstrap/Image';
import CommentAdd from '../Common/comments/CommentAdd';
import moment from 'moment';
import { useHistory } from 'react-router-dom';
import Slider from "../../Utilities/Slider/Slider"
import { t } from '../../Translation/Translation'
import '../../Static/css/common.css';
import { useParams } from "react-router";
import FullScreenImage from "../../Utilities/FullScreenImage/FullScreenImage";
import CommentDetailsPages from '../Common/comments/CommentDetails';
import BackButtonComponent from '../../Utilities/BackButtonComponent';
import BackButton from "../../Utilities/BackButton";
import previewFn from '../../Common/Preview';
import Reactions from "../Reactions/Reactions";

function NewDetails({ updatesEntityId, CommentsStatus, UpdateStatus, updateDetailslikes, backButton, updateReactionsInList, reaction, reactionsList, newReactionEntryId }) {
  const [newslist, setnewlist] = useState({})
  const [sliderData, setsliderData] = useState("");
  const history = useHistory();
  const get_id = localStorage.getItem("id");
  const [BannerTime, setBannerTime] = useState();
  const [fullscreenState, setfullscreenState] = useState(false);
  const [imageSrc, setimageSrc] = useState("");
  const [commentDetails, setcommentDetails] = useState(false);
  const [backbuttonUrl, setbackbuttonUrl] = useState();
  const [urlState, seturlState] = useState(false);
  const [previewBack, setpreviewBack] = useState(false);
  let params = useParams();

  useEffect(() => {
    if (urlState === false && updatesEntityId !== undefined) {
      setbackbuttonUrl('/news/' + updatesEntityId.id);
      seturlState(true);
    }
  }, [updatesEntityId])

  useEffect(() => {
    var list = { ...newslist }
    Object.keys(newslist).map((key) => {
      list[key].reactions = reaction
    });
      setnewlist(list)
  },[reaction]);

  useEffect(() => {
    let currentpath = window.location.href;
    if (currentpath.indexOf("preview") > 0){
      setpreviewBack(true);
    }
  }, [])
  
  useEffect(() => {
    //  check updatesEntityId undifined or not
    if (updatesEntityId) {
      //updating commentsDetails page status
      setcommentDetails(CommentsStatus);
      let param = previewFn(params);
      let data = {
        'user_id': get_id,
        'entry_id': updatesEntityId.id,
        'param': param
      }
      APICALL.service(NewsUrl, "POST", data)
        .then(result => {
          if (result) {
            setBannerTime(result[updatesEntityId.id].banners_time ? result[updatesEntityId.id].banners_time : null);
            let deviceMode = localStorage.getItem("DeviceMode");
            if(deviceMode === "Desktop"){
              _constructSliderData(result[updatesEntityId.id].banner_images ? result[updatesEntityId.id].banner_images : null);
            }else{
              _constructSliderData(result[updatesEntityId.id].mobile_banner_images ? result[updatesEntityId.id].mobile_banner_images : null);
            }
            setnewlist(result)
          }
        })
        .catch(error => {
          console.error("Eventlist error : ", error);
        })
    }
  }, [UpdateStatus, updatesEntityId])

  const _constructSliderData = (data) => {
    ;
    const sliders = [];
    data.length > 0 && data.forEach((val, i) => {
      sliders[i] = { 'original': val.image, 'thumbnail': val.image };
    })
    setsliderData(sliders);

  }
  // like action code not required now
  // const incrementMe = () => {
  //   let data = {
  //     'user_id': get_id,
  //     'entry_id': updatesEntityId.id
  //   }
  //   APICALL.service(likes, "POST", data)
  //     .then(result => {
  //       if (result.data === "success") {
  //         updateDetailslikes(updatesEntityId.id);
  //         updatelikesss()

  //       }
  //     })
  // }

  // back button funtionality
  function historyBack() {
    let response = BackButton("/news");
    if (response === false) {
      backButton(false);
      history.push('/news');
    }
    else {
      backButton(false);
      history.push(response);
    }
  }
  // like action code not required now
  // function updatelikesss (){
  //   Object.keys(newslist).map((key) => {
  //     var list = { ...newslist }
  //     list[key].user_like_status = newslist[key].user_like_status === true ? false : true
  //     list[key].likes_count = newslist[key].user_like_status === true ? parseInt(newslist[key].likes_count) + 1 : parseInt(newslist[key].likes_count) - 1
  //     setnewlist(list)
  //   })
  // }
  function createMarkup(data) {
    if(data){
      data = data.replaceAll("<p></p>", "<br />");
    }
    return { __html: data };
  }
  //converting  UTC to local time ?
  function convertUTCDateToLocalDate(date) {
    let d = new Date(date.toString());
    return d.toString();
  }
  //Viewing Images in Full Screen
  function EnableFullScreenImage(ImageData) {
    setimageSrc(ImageData);
    setfullscreenState(true);
  }
  //Disable Images in Full Screen
  function DisableFullScreenImage() {
    setimageSrc("");
    setfullscreenState(false);
  }
  function CommentStatus(e) {
    setcommentDetails(e);
  }
  const backButtons = () => {
    setcommentDetails(false);
  }

  return (
    fullscreenState ? (
      <FullScreenImage
        imageSrc={imageSrc}
        DisableFullScreenImage={DisableFullScreenImage}
      />
    ) : (
      <div className="container mb-5 customContainer">
        {commentDetails ? <CommentDetailsPages updatesEntityId={updatesEntityId.id} backButtons={backButtons} /> :

          newslist !== null && newslist.status !== '404' && newslist !== undefined &&
          Object.keys(newslist).map((key) => (
            <div className="news-mind" key={newslist[key].id}>
              {
                localStorage.getItem('DeviceMode') !== "Desktop" ?
                  <div className="col-md-12 topsection-wrap  mt-0 px-0 pt-4">
                  {previewBack ? null : <p className="browserback" onClick={historyBack}>back</p>}
                  {backbuttonUrl && <BackButtonComponent backButtonLink = {backbuttonUrl}/>}
                  <h1 className="pagetitle textcolor1 text-center">{t('News')}</h1>
                </div> :null
                }
                {(sliderData.length > 0) &&
                  <Slider
                    history={history}
                    bannerTime={BannerTime ? BannerTime : '5'}
                    sliderData={sliderData}
                    EnableFullScreenImage={EnableFullScreenImage}
                  />
                }
                <div className="col-12 px-1 mt-4">
                  <div className="row">
                    <div className="col-10 detailInnertitle">
                      <h2 className="">{newslist[key].title ? newslist[key].title : null}
                      </h2>
                    </div>
                    <div className="col-2 text-right">
                      <p className="desIcon imgSprite mb-0 detailIcon"></p>
                    </div>
                    <div className='row col-12 desktop-space mob-right-space-div'>
                    <div className="col-9 mt-3 likesection">
                      {newslist[key].post_date ?
                        moment(convertUTCDateToLocalDate(newslist[key].post_date)).format('DD/MM/YYYY') : null}
                    </div>
                    <div className="mt-3 col-3 actionButton-loclikesection textcol text-right desktop-space">

                      <div>
                        {newslist[key].likes_status === "enabled" ?
                          <Reactions
                            reactions={newslist[key].reactions}
                            entryId={newslist[key].id}
                            updateReactionsInList={updateReactionsInList}
                            reactionsList={reactionsList}
                            newReactionEntryId={newReactionEntryId}
                          />
                          : null}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <hr className="w-100" />
              {newslist[key].content !== undefined &&
                newslist[key].content.map((desc, index) => (
                  <div key={index}>
                    <div className="Richtexterditor" dangerouslySetInnerHTML={createMarkup(desc.description)} />
                    <div>{
                      Array.isArray(desc.images) ? desc.images.map((images, i) => (
                        <div className="col-6 p-2 d-inline-flex text-center" key={i} >
                          <Image className="mb-3" src={images} fluid onClick={() => EnableFullScreenImage(images)} />
                        </div>
                      )) : null}
                    </div>
                    <div>
                      {desc.video ?
                        <div id="frames" className="col-12 p-0 ">
                          <iframe width="100%"
                            // title="videos"
                            title = "newsvideo"
                            src={desc.video}
                            allowFullScreen="allowfullscreen">
                          </iframe>
                        </div>
                        : null}
                    </div>
                  </div>
                ))}
              {/* <CommentAdd entryId={props.match.params.id} ></CommentAdd> */}
              { newslist[key].comment_status !== "disabled" ?
                <CommentAdd
                  entryId={updatesEntityId.id}
                  CommentStatus={CommentStatus}
                ></CommentAdd>
                : null
              }

            </div>
          ))}
        {newslist !== null && newslist.length === '0' && newslist.status === '404' &&
          <p>{t('No Description found')}</p>}
        {/* ending looping section  */}
      </div>
    )
  );
}
export default NewDetails;

