import React, { useState } from "react";
import "./profile.css";
import moment from 'moment';
import { t } from '../../Translation/Translation';
import  ScrollToTop from '../../Utilities/ScrollToTop';


const PersonalDetails = ({ onSubmit, updateField, form, location, reportingornot, updatereportdatastatus }) => {
  const [reportStatus, setReportStatus] = useState(reportingornot);

  const displayReportTextArea = (e) => {
    setReportStatus(!reportStatus);
    updatereportdatastatus(e);
    if (!reportStatus) {
      form.report = "";
    }
  }

 
  return (
    form &&
    <div className="container" id="first">
      <ScrollToTop />
      <div id="contact"></div>
      <div className="col-md-12 text-center px-0 pt-3 my-3">
          <label className="textcolor1 font-weight-normal">{t('The information on this page comes from the official Bleckmann records. Please check your personal data. You may report incorrect entries by using the button below')} </label>
          
      </div>
      <div className="mobilediv">
          <form onSubmit={onSubmit} className="mt-4" id="personaldetail">
            <div className="form-group">
              <label className="textcolor1 sectionTitle ">{t('First name')}</label>
              <h5 className="innerpagetitle">{form.firstName}</h5>
            </div>
            <div className="form-group">
              <label className="textcolor1 sectionTitle ">{t('Last name')}</label>
              <h5 className="innerpagetitle">{form.lastName}</h5>

            </div>

            <div className="form-group">
              <label className="textcolor1 sectionTitle ">{t('Department')}</label>
              <h5 className="innerpagetitle">{form.department}</h5>
            </div>

            <div className="form-group">
              <label className="textcolor1 sectionTitle ">{t('Job function')}</label>
              <h5 className="innerpagetitle">{form.userJobFunction}</h5>
            </div>

            <div className="form-group">
              <label className="textcolor1 sectionTitle ">{t('Location')}</label>
              <h5 className="innerpagetitle">{location}</h5>
            </div>

            <div className="form-group">
              <label className="textcolor1 sectionTitle ">{t('Work phone number')}</label>
              <h5 className="innerpagetitle">{form.workPhoneNumber}</h5>
            </div>

            <div className="form-group">
              <label className="textcolor1 sectionTitle ">{t('Work email address')}</label>
              <h5 className="innerpagetitle">{form.email}</h5>
            </div>


            {/* <div className="form-group">
              <label className="textcolor1 sectionTitle ">{t('Birthday')}**</label>
              {form.birthDate && <h5 className="innerpagetitle">{form.birthDate !== "" ? moment(form.birthDate).format('DD/MM/YYYY') : null}</h5>}
            </div> */}

            <div className="form-group">
              <label className="textcolor1 sectionTitle ">{t('Working at Bleckmann since')}*</label>
              {form.enrollmentDate && <h5 className="innerpagetitle">{form.enrollmentDate !== "" ? moment(form.enrollmentDate).format('DD/MM/YYYY') : null}</h5>}
            </div>
            <div className="row">
              <div className="col-md-8 col-8">
                <label className="textcolor1 sectionTitle ">{t('Report incorrect data')}</label>
              </div>
              <div className="col-md-4 col-4 pr-0 text-right d-flex align-items-center">
                <span className="switch-label innerpagetitle">{reportStatus ? t('Yes') : t('No')}</span>
                <label className="switch">
                  <input type="checkbox" name="reportCheckbox" className="form-control" checked={reportStatus} onChange={displayReportTextArea} value={reportStatus} />
                  <span className="slider round"></span>
                </label>
              </div>
              {reportStatus && <div className="form-group col-md-12 col-12 my-3">
                <textarea name="report" className="form-control" placeholder={t('Add message to admin to update your details')} onChange={updateField} value={form.report} />
              </div>}
            </div>
            <div className="col-md-12 text-left px-0 pt-3 my-3">
              <label className="textcolor1 small font-weight-normal">*{t('This information will not be visible to other users')} </label>
            </div>
            <div className="col-md-12 text-center mt-2 p-0 mb-5 pb-5">
              <button type="submit" className="btn mybtn btn-light tx-tfm bg-color w-100" >{t("Save")} & {t("Continue")}</button>
            </div>
          </form>
      </div>

      <div className="desktopdiv mt-5">
          
          <form onSubmit={onSubmit} className="mt-4" id="personaldetail">
          <div className="row">
            <div className="form-group col-lg-6 mb-4">
              <label className="textcolor1 sectionTitle ">{t('First name')}</label>
              <h5 className="innerpagetitle">{form.firstName}</h5>
            </div>
            <div className="form-group col-lg-6 mb-4">
              <label className="textcolor1 sectionTitle ">{t('Last name')}</label>
              <h5 className="innerpagetitle">{form.lastName}</h5>

            </div>

            <div className="form-group col-lg-6 mb-4">
              <label className="textcolor1 sectionTitle ">{t('Department')}</label>
              <h5 className="innerpagetitle">{form.department}</h5>
            </div>

            <div className="form-group col-lg-6 mb-4">
              <label className="textcolor1 sectionTitle ">{t('Job function')}</label>
              <h5 className="innerpagetitle">{form.userJobFunction}</h5>
            </div>

            <div className="form-group col-lg-6 mb-4">
              <label className="textcolor1 sectionTitle ">{t('Location')}</label>
              <h5 className="innerpagetitle">{location}</h5>
            </div>

            <div className="form-group col-lg-6 mb-4">
              <label className="textcolor1 sectionTitle ">{t('Work phone number')}</label>
              <h5 className="innerpagetitle">{form.workPhoneNumber}</h5>
            </div>

            <div className="form-group col-lg-6 mb-4">
              <label className="textcolor1 sectionTitle ">{t('Work email address')}</label>
              <h5 className="innerpagetitle">{form.email}</h5>
            </div>

        {/* <div className="form-group">
          <label className="textcolor1 sectionTitle ">{t('Birthday')}**</label>
          {form.birthDate && <h5 className="innerpagetitle">{form.birthDate !== "" ? moment(form.birthDate).format('DD/MM/YYYY') : null}</h5>}
        </div> */}

        <div className="form-group col-lg-6 mb-4">
          <label className="textcolor1 sectionTitle ">{t('Working at Bleckmann since')}*</label>
          {form.enrollmentDate && <h5 className="innerpagetitle">{form.enrollmentDate !== "" ? moment(form.enrollmentDate).format('DD/MM/YYYY') : null}</h5>}
        </div>
        <div className="row pl-3">
          <div className="col-md-8 col-8">
            <label className="textcolor1 sectionTitle ">{t('Report incorrect data')}</label>
          </div>
          <div className="col-md-4 col-4 pr-0 text-right d-flex align-items-center">
            <span className="switch-label innerpagetitle">{reportStatus ? t('Yes') : t('No')}</span>
            <label className="switch">
              <input type="checkbox" name="reportCheckbox" className="form-control" checked={reportStatus} onChange={displayReportTextArea} value={reportStatus} />
              <span className="slider round"></span>
            </label>
          </div>
          {reportStatus && <div className="form-group col-md-12 col-12 my-3">
            <textarea name="report" className="form-control" placeholder={t('Add message to admin to update your details')} onChange={updateField} value={form.report} />
          </div>}
        </div>
        <div className="col-md-12 text-left px-0 pt-3 my-3">
          <label className="textcolor1 small font-weight-normal">*{t('This information will not be visible to other users')} </label>
        </div>
        <div className="col-md-12 desktop-save text-center mt-2 p-0 mb-5 pb-5">
          <button type="submit" className="btn btn-light tx-tfm bg-color w-100 saveButton" >{t("Save")} & {t("Continue")}</button>
        </div>
        </div>
      </form>
        
      </div>

    </div>
  )
}

export default PersonalDetails
