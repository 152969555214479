import React from 'react';
import CommentList from '../comments/CommentList';
import CommentItem from '../comments/CommentitemDetails';
import { Comments } from "../../../Services/ApiEndPoints";
import { APICALL } from "../../../Services/ApiServices";
import './Comments.css';
import Popup from "../../../Utilities/Popup/modelPopup";
import { t } from '../../../Translation/Translation'


class CommentAdd extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      comments: [],
      message : '',
      popupsate :false
    };
  }
  componentDidMount() {
    const get_id = localStorage.getItem("id");
    let data = {
      'user_id': get_id,
      "type": "view",
      "entry_id": this.props.entryId
    }
    APICALL.service(Comments, "POST", data)
      .then(result => {
        var dataList = {}
        if (parseInt(result.status) !== 404) {
          Object.keys(result).map((value, i) => (
            dataList[value] = result[value]
          ))
          this.setState({
            comments: dataList
          })
        }

      })
      .catch(error => {
        console.error("Comments error : ", error);
      })
  }
  closeAlertPopup = () =>{
    this.setState({
      popupsate : false
    })

}

RidrectCommentPage =() =>{
  this.props.CommentStatus(true);
}


  render() {
   // const comments = this._getComments();
    return (
      <div className="comment-box">
        <h5 className="textcolor1 my-3">{t('Comments')}</h5>
        {this.state.popupsate ? <Popup
          show= {true}
          onHide={this.closeAlertPopup}
          size={"lg"}
          title={"Alert"}
          body={t("Error posting comments please try again")}
        /> :
        null}
        {this._getComments()}
        <div className="col-12 col-md-12 mb-3 text-center">
        {/*comment list morethan 3 then only display more option button */}
        {Object.keys(this.state.comments).length > 3 && <p className="textcolor1 font-weight-bold" onClick ={this.RidrectCommentPage.bind()}> {t('Show more')}</p>}
        </div>
        <CommentList addComment={this._addComment.bind(this)}  types ={'list'}/>
      </div>
    );
  } // end render

  //user adding comments and to send in the API for backend
  _addComment(value) {

    const get_id = localStorage.getItem("id");
    const data = {
      'user_id': get_id,
      "type": "create",
      "entry_id": this.props.entryId,
      "comment": value.comment,
      "tagged_user" : value.tagged_id ? value.tagged_id : null,
      "comment_date" : new Date(new Date().getTime() - (new Date().getTimezoneOffset() * 60000)).toISOString()

    }
    APICALL.service(Comments, "POST",data)
      .then(result => {
        if (result.data === "success") {
          let data = {
            'user_id': get_id,
            "type": "view",
            "entry_id": this.props.entryId
          }
          //Feteching comments list 
          APICALL.service(Comments, "POST", data)
            .then(result => {
              var dataList = {}
              if (result) {
                Object.keys(result).map((value, i) => (
                  dataList[value] = result[value]
                ))
                this.setState({
                  comments: dataList
                })
              }
      
            })
            .catch(error => {
              console.error("Eventlist error : ", error);
            })
          // *new array references help React stay fast, so concat works better than push here    
        }
      })
      .catch(error => {
        this.setState ({
          popupsate : true
        })
        console.error("Eventlist error : ", error);
      })
  }
  //TO display first three comments list
  _getComments() {
  let data = this.state.comments;
  return Object.keys(data).reverse().slice(0,3).map((comment) => {
    return (
      <CommentItem  comment ={data[comment]} key ={data[comment].id}></CommentItem>
    );
  });
}
} // end CommentBox component
export default CommentAdd;