import React, { useState, useEffect } from "react";
import { Vacancy } from "../../Services/ApiEndPoints";
import { APICALL } from "../../Services/ApiServices";
import SinglePageLayout from "../Common/singlePageLayout";

function Vacancies() {
  const [Vacancydata, setVacancydata] = useState();

  useEffect(() => {
    let User_id = localStorage.getItem("id");
    const res = {
      user_id: User_id,
    };
    APICALL.service(Vacancy, "POST", res).then((result) => {
      if (result != null) setVacancydata(result.vacancy);
    });
  }, []);

  return (
    <SinglePageLayout
      header={"Vacancy"}
      layoutData={Vacancydata}
      url={"/vacancy"}
    />
  );
}

export default Vacancies;
