import React from "react";
import SignatureNewMail from "./SignatureNewMail";
// import SignatureReplyMail from "./SignatureReplyMail";

function SignatureGenerator(props) {
  return (
    <>
      {props.currentTab === 1 && (
        <SignatureNewMail
          signatureForm={props.signatureForm}
          bleckmann_entity={props.bleckmann_entity}
          bleckmann_address={props.bleckmann_address}
          UpdateNumbers={props.UpdateNumbers}
          updateField={props.updateField}
          SelectionChange={props.SelectionChange}
          backButton={props.backButton}
        />
      )}
      {/* {props.currentTab === 2 && (
        <SignatureReplyMail
          signatureForm={props.signatureForm}
          bleckmann_entity={props.bleckmann_entity}
          bleckmann_address={props.bleckmann_address}
          UpdateNumbers={props.UpdateNumbers}
          updateField={props.updateField}
          backButton={props.backButton}
        />
      )} */}
    </>
  );
}

export default SignatureGenerator;
