import React from "react";
import DatePicker from "react-mobile-datepicker";
import "./mobilecalendar.css";

function MobileCalendar(props) {
  const monthMap = {
    1: "Jan",
    2: "Feb",
    3: "Mar",
    4: "Apr",
    5: "May",
    6: "Jun",
    7: "Jul",
    8: "Aug",
    9: "Sep",
    10: "Oct",
    11: "Nov",
    12: "Dec",
  };

  const dateConfig = {
    date: {
      format: "DD",
      caption: "Day",
      step: 1,
    },
    month: {
      format: (value) => monthMap[value.getMonth() + 1],
      caption: "Mon",
      step: 1,
    },
    year: {
      format: "YYYY",
      caption: "Year",
      step: 1,
    },
  };

  const headerElement = (
    <>
      <div className="row m-0">
        <div className="col-4 p-0">Day</div>
        <div className="col-4 p-0">Month</div>
        <div className="col-4 p-0">Year</div>
      </div>
    </>
  );

  return (
    <DatePicker
      dateConfig={dateConfig}
      isOpen={true}
      onSelect={(e) => props.showDetailsHandle(e, "ok")}
      confirmText="Ok"
      cancelText="Cancel"
      onCancel={(e) => props.showDetailsHandle(e, "cancel")}
      value={props.defaultDate}
      min={new Date(new Date().setHours(0, 0, 0, 0))}
      showHeader={true}
      isPopup={false}
      theme={"android"}
      customHeader={headerElement}
    />
  );
}

export default MobileCalendar;
