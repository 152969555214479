import React, { useEffect } from 'react';


function BackButtonComponent({backButtonLink}) {
    var historyItemJson = sessionStorage.getItem('historydata');
    var historyItem = historyItemJson ? JSON.parse(historyItemJson) : [];
    var linkname = backButtonLink;
    
useEffect(() => {
    if((backButtonLink !== undefined) && (backButtonLink !== null)) {
        var datafromSession = JSON.parse(sessionStorage.getItem('historydata'))
        var lastItem;
        if (datafromSession !== null) {
            lastItem = datafromSession[datafromSession.length - 1] 
        }
        if (lastItem !== linkname){
            historyItem.push(linkname);
            sessionStorage.setItem('historydata', JSON.stringify(historyItem));
        }
    }
// eslint-disable-next-line react-hooks/exhaustive-deps
}, [])
           
    return (
        <div>
            
        </div>
    )
}

export default BackButtonComponent
