import React from "react";

function NoMatchPage() {
  return (
    <div className="access-denied mt-3 ml-5">
      <h3 className="ml-5">You don't have access rights to view this page</h3>
      <h4 className="ml-5">Please contact your administrator.</h4>

    </div>
  );
}

export default NoMatchPage;
