import React, { useState, useEffect } from "react";
import { t } from "../../Translation/Translation";
import { useHistory } from "react-router-dom";
import BackButtonComponent from "../../Utilities/BackButtonComponent";
import BackButton from "../../Utilities/BackButton";

function createMarkup(data) {
  if(data){
    data = data.replaceAll("<p></p>", "<br />");
  }
  return { __html: data };
}

function SinglePageLayout({ header, layoutData, url }) {
  const [backbuttonUrl, setbackbuttonUrl] = useState();
  const [urlState, seturlState] = useState(false);
  const history = useHistory();
  const [DeviceMode, setDeviceMode] = useState(false);

  /***
   * Store url in session storage for back button functionality
   */
  useEffect(() => {
    if (urlState === false) {
      setbackbuttonUrl(url);
      seturlState(true);
    }
    if (localStorage.getItem("DeviceMode") === "Desktop") {
      setDeviceMode(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  //Remove the last item from array and push the url for back button
  function historyBack() {
    let response = BackButton();
    history.push(response);
  }

  return (
    <div
      className={`${
        DeviceMode
          ? "Desktop-mode-list-and-detail-view layoutSpace "
          : "customContainer py-3 space-layout-single"
      }`}
    >
      <div className={`${DeviceMode ? "container" : ""}`}>
        <div className="customscroll">
          <div className="col-md-12 topsection-wrap mob-button-wrap  mt-0 px-0 pt-4">
            <p className="browserback" onClick={historyBack}>
              back
            </p>
            {backbuttonUrl && (
              <BackButtonComponent backButtonLink={backbuttonUrl} />
            )}
            <h1 className="pagetitle textcolor1 text-center">{t(header)}</h1>
          </div>
          <div className="col-md-12 my-4 px-1">
            <div dangerouslySetInnerHTML={createMarkup(layoutData)} />
          </div>
        </div>
      </div>
    </div>
  );
}

export default SinglePageLayout;
