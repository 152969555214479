export default function BackButton(params) {
  var Entry = sessionStorage.getItem("historydata");
  var Entryvalue = Entry ? JSON.parse(Entry) : [];
  // var FinalEntry = Entryvalue.pop();
  Entryvalue.pop();
  sessionStorage.setItem("historydata", JSON.stringify(Entryvalue));

  if (params === undefined) {
    if (Entryvalue.length === 0) {
      return "/";
    } else {
      return Entryvalue[Entryvalue.length - 1];
    }
  } else if (params !== undefined) {
    if (Entryvalue.length === 0) {
      return "/";
    } else {
      // redirect from detail view to list view if url is same
      if (Entryvalue[Entryvalue.length - 1] === params) {
        return false;
      } else {
        return Entryvalue[Entryvalue.length - 1];
      }
    }
  }
}
