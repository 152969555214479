import React, { useState } from "react";
import AvatarEditor from "react-avatar-editor";
import Slider from "@material-ui/core/Slider";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import { withStyles } from "@material-ui/core/styles";
import { styles } from "./styles";
import "antd/dist/antd.css";
import { Modal } from "antd";
import "../Static/css/common.css";
import { t } from './../Translation/Translation';


const ImageAvatar = ({ classes, handleImageChange, imagedata }) => {

  const [imageSrc, setImageSrc] = React.useState(null);
  const [rotation, setRotation] = useState(0);
  const [editor, seteditor] = useState("");
  const [zoom, setZoom] = useState(1);
  const [visible, setvisible] = useState(false);
  const imageresult = new FormData();



  const showModal = () => {
    setvisible(true);
  };

  const handleOk = (e) => {
    setvisible(false);
    try {
      const img = editor.getImageScaledToCanvas().toDataURL();
      var blobBin = atob(img.split(",")[1]);
      var array = [];
      for (var i = 0; i < blobBin.length; i++) {
        array.push(blobBin.charCodeAt(i));
      }
      var file = new Blob([new Uint8Array(array)], { type: "image/png" });
      var Bloburl = URL.createObjectURL(file);
      // setCroppedImage(Bloburl);
      imageresult.append("image", file, "filename.png");
      const get_id = localStorage.getItem("id");
      imageresult.append("id", get_id);
      handleImageChange(Bloburl);
      imagedata(imageresult);
      setvisible(false);
    } catch (e) {
      console.error(e);
    }


  };

  const handleCancel = (e) => {
    setvisible(false);
    //setCroppedImage(null);
  };


  const createImage = (url) =>
    new Promise((resolve, reject) => {
      const image = new Image();
      image.addEventListener("load", () => resolve(image));
      image.addEventListener("error", (error) => reject(error));
      image.setAttribute("crossOrigin", "anonymous"); // needed to avoid cross-origin issues on CodeSandbox
      image.src = url;
    });

  const onFileChange = async (e) => {
    if (e.target.files && e.target.files.length > 0) {
      let file = e.target.files[0];
      let imageDataUrl = await readFile(file);
      setImageSrc(imageDataUrl);
      showModal();
    }
  };

  const setEditorRef = (ed) => {
    if (ed) seteditor(ed);
  };


  return (
    <div>
      <Modal
        centered
        title={t("Change profile picture")}
        visible={visible}
        style={{ top: 20 }}
        onCancel={handleCancel}
        footer={null}
      >
        <React.Fragment>
          <AvatarEditor
            ref={setEditorRef}
            image={imageSrc}
            width={250}
            height={250}
            scale={zoom}
            rotate={rotation}
          />
          <div className={classes.controls}>
            <div className={classes.sliderContainer}>
              <Typography
                variant="overline"
                classes={{ root: classes.sliderLabel }}
              >
                {" "}
                {t("Zoom")}{" "}
              </Typography>
              <Slider
                value={zoom}
                min={1}
                max={3}
                step={0.1}
                aria-labelledby="Zoom"
                classes={{ container: classes.slider }}
                onChange={(e, zoom) => setZoom(zoom)}
              />
            </div>
            <div className={classes.sliderContainer}>
              <Button
                className="bg-color  action-btn mx-2"
                type="primary"
                onClick={() => setRotation(rotation + 90)}
              >
                {" "}
                {t("Rotate")}{" "}
              </Button>
              <Button
                className="bg-color  action-btn mx-2"
                type="primary"
                onClick={handleOk}
              >
                {" "}
                {t("OK")}{" "}
              </Button>
            </div>
          </div>
        </React.Fragment>
      </Modal>
      <label className="btn bg-color mt-4 mb-1 ml-3">
        <span>{t("Change")}</span>
        <input
          type="file"
          accept="image/*"
          value={""}
          onChange={onFileChange}
          style={{ display: "none" }}
        />
      </label>
    </div>
  );
};

function readFile(file) {
  return new Promise((resolve) => {
    const reader = new FileReader();
    reader.addEventListener("load", () => resolve(reader.result), false);
    reader.readAsDataURL(file);
  });
}

const StyledImgCrop = withStyles(styles)(ImageAvatar);

export default StyledImgCrop;
