import React from 'react'
import '../../Static/css/common.css'
import { t } from '../../Translation/Translation'

const SearchBoxWithSubmit = ({ onSearchValue, searchVal, searchSubmit}) => {
    return (
        <form onSubmit={searchSubmit}>
        <div className="input-group col-12 my-4 searchsectionwithSubmit p-0">
            <div className="col-md-12 p-0 m-0">
                <input className="form-control py-2 border-right-0 float-right" type="search" onChange={onSearchValue} placeholder={t("Search")} value={searchVal} id="example-search-input" autoComplete="off"/>
            </div>
            {/* <input className="input-group-append btn search-submit-arrow" type="submit" value="&#8594;" /> */}
            <input style={{ display: "none"}} type="submit" />
        </div>
        </form>
    )
}

export default SearchBoxWithSubmit;
