import React, { useState, useEffect } from "react";
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import { UserProfile } from "../../Services/ApiEndPoints";
import { APICALL } from "../../Services/ApiServices";
import './Settings.css';
import { t } from '../../Translation/Translation';
import '../../Static/css/common.css';
import { useHistory } from 'react-router-dom';
import InstallPWA from '../../Utilities/InstallPWA/InstallPWA';
import TermsCondition from './TermsCondition';
import Language from './Language';
import Disclaimer from './Disclaimer';
import About from './About';
import BackButtonComponent from '../../Utilities/BackButtonComponent'
import { useParams } from "react-router";
import BackButton from "../../Utilities/BackButton";
// import InstallApp from './InstallApp'
import NewsletterSubscription from './NewsletterSubscription';

const InstallAPP = localStorage.getItem("InstallApp");

export default function Setting() {
  const [get_id] = useState(JSON.parse(localStorage.getItem("id")))
  const [checkButton, setCheckButton] = useState(false);
  // const [temp,setTemp]=useState('');
  const [sliderState, setSliderState] = useState(true);
  // const [moduleMsg, setModuleMsg] = useState(window.navigator.userAgent);
  const [pushSliderState, setPushSliderState] = useState(true);
  const [newsletterSliderState, setNewsletterSliderState] = useState(true);
  const history = useHistory();
  let data;
  // const [DesktopMode,setDesktopMode]=useState();
  const DesktopMode = (localStorage.getItem('DeviceMode')).includes('Desktop');
  const [listviewMode, setlistviewMode] = useState(false)
  const [defaultPage, setDefaultPage] = useState();
  const [language, setLanguage] = useState();
  const [backbuttonUrl, setbackbuttonUrl] = useState();
  const [urlState, seturlState] = useState(false);
  let params = useParams();
  // let defaultPage;

  if (sliderState === undefined || sliderState === null) {
    setCheckButton(true);
    setSliderState(true);
  }

  // if(localStorage.getItem('DeviceMode') === 'Desktop'){
  //   setDesktopMode(true);
  //  }
  useEffect(() => {
    APICALL.service(UserProfile + get_id, "GET")
      .then(result => {
        if (result) {
          try {
            if (result.sensitiveInfo === true) {
              setSliderState(true);
            }
            else {
              setSliderState(false);
            }
            console.log(result.newsletter)
            if (result.newsletter === true) {
              setNewsletterSliderState(true);
            }
            else {
              setNewsletterSliderState(false);
            }
            if (result.notifications !== null) {
              setPushSliderState(result.notifications)
            }
            else {
              setPushSliderState(true);
            }
            // Setting default details page for desktop mode
            if (DesktopMode === true && params.id === undefined) {
              setDefaultPage(1);
            }
            else if (params.id !== undefined) {
              setDefaultPage(params.id);
              setlistviewMode(true);
            }
          } catch (e) {
            console.error(e);
          }
        }
      })
  }, []);

  /**
   * sensitive info message
   */
  const SensitiveInfo = () => {
    if (sliderState === true) {
      data = {
        "sensitiveInfo": false,
      }
      setSliderState(false);
    } else {
      data = {
        "sensitiveInfo": true,
      }
      setSliderState(true);
    }
    updateToApi(data);
  }

  // const NewsletterSubscription = () => {
  //   if (newsletterSliderState === true) {
  //     data = {
  //       "newsletter": false,
  //     }
  //     setNewsletterSliderState(false);
  //   } else {
  //     data = {
  //       "newsletter": true,
  //     }
  //     setNewsletterSliderState(true);
  //   }
  //   updateToApi(data);
  // }

  /***
   * Store url in session storage for back button functionality
   */
  useEffect(() => {
    if (urlState === false) {
      setbackbuttonUrl('/settings');
      seturlState(true);
    }
  }, [])
  /**
   * push notification message for popup
   */
  const PushNotification = () => {
    if (pushSliderState === true) {
      data = {
        "notifications": false,
      }
      setPushSliderState(false);
    } else {
      data = {
        "notifications": true,
      }
      setPushSliderState(true);
    }
    updateToApi(data);
  }
  const updateToApi = (data) => {
    APICALL.service(UserProfile + get_id, "PUT", data)
      .then(result => {
        try {
          // console.log("Response put value of edit: ", result.id); 
        } catch (e) {
          console.error(e);
        }
      });
  }
  // Details page based on on click function for desktop
  const ActivePage = (e) => {
    setDefaultPage(e)
    // mobile devices showing list view first on click displaying details page.
    if (DesktopMode === true) {
      setlistviewMode(false);
    }
    else {
      setlistviewMode(true);
    }
  }
  // back button functionality
  const backButton = (e, id) => {
    setlistviewMode(e)
    setDefaultPage(id);
  }
  const updateLanguagesPage = (e) => {
    setLanguage(e);
  }
  
  //Remove the last item from array and push the url for back button 
  function historyBack() {
    let response = BackButton();
    history.push(response);
  }
  const SettingsPage = () => {
    return (
      <div className="container customContainer pt-3 pb-3">
        <div className="col-md-12 topsection-wrap  mt-0 px-0 pt-4">
          <p className={DesktopMode ? "hide-class" : "browserback"} onClick={historyBack}>back</p>
          {backbuttonUrl && <BackButtonComponent backButtonLink={backbuttonUrl} />}
          <h1 className="pagetitle textcolor1 text-center">{t("Settings & Info")}</h1>
        </div>
        <div className="col-md-12 col-12 col-lg-12 mt-4 settingarrow">
          <div className="row">
            <div className="col-lg-12 col-12 my-2 px-2">
              <p className="innerpagetitle float-left">{t("Language")}</p>
              <a onClick={() => ActivePage(1)}>
                <ArrowForwardIosIcon className="float-right" />
              </a>
            </div>
            <div className="col-lg-12 col-12 my-2 px-2">
              <p className="float-left innerpagetitle">{t("About BCrew App")}</p>
              <a onClick={() => ActivePage(2)}>
                <ArrowForwardIosIcon className="float-right" />
              </a>
            </div>
            <div className="col-lg-12 col-12 my-2 px-2">
              <p className="float-left innerpagetitle">{t("Disclaimer")}</p>
              <a onClick={() => ActivePage(3)}>
                <ArrowForwardIosIcon className="float-right" />
              </a>
            </div>
            <div className="col-lg-12 ol-12 my-2 px-2">
              <p className="float-left innerpagetitle">
                {t("Terms and conditions")}
              </p>
              <a onClick={() => ActivePage(4)}>
                <ArrowForwardIosIcon className="float-right" />
              </a>
            </div>



            <div className=" col-md-12 pr-0 my-2 px-2">
              <div className="row">
                <div className="col-md-8 col-8">
                  <label className="innerpagetitle ">{t("Show personal info")}</label>
                </div>
                <div className="col-md-4 col-4  text-right d-flex align-items-center justify-content-end">
                  <span className="switch-label innerpagetitle">
                    {sliderState === true ? "Yes" : "No"}
                  </span>
                  <label className="switch">
                    <input
                      type="checkbox"
                      name="sensitiveInfo"
                      className="float-right"
                      checked={sliderState}
                      onChange={SensitiveInfo}
                    />

                    <span className="slider round"></span>
                  </label>
                </div>
              </div>
            </div>

            <div className=" col-md-12 pl-0 my-2 px-4">
              <div className="row">
                <div className="col-md-8 col-8 pl-0">
                  <label className="innerpagetitle ">
                    {t("Allow push notifications")}
                  </label>
                </div>
                <div className="col-md-4 col-4 pr-0 text-right d-flex align-items-center justify-content-end">
                  <span className="switch-label innerpagetitle">
                    {pushSliderState === true ? "Yes" : "No"}
                  </span>
                  <label className="switch">
                    <input
                      type="checkbox"
                      name="pushNotification"
                      className="float-right"
                      checked={pushSliderState}
                      onChange={PushNotification}
                    />
                    <span className="slider round"></span>
                  </label>
                </div>
                <div>
                  {/* <Footer /> */}
                </div>
              </div>
            </div>
            {/* <div className=" col-md-12 pr-0 my-2 px-2">
                <label className="innerpagetitle float-left">{t("Newsletter Subscription")}</label>
                  <a onClick={() => ActivePage(5)}>
                    <ArrowForwardIosIcon className="float-right" />
                  </a>
            </div> */}
              {/* <div className="row">
                <div className="col-md-8 col-8">
                  <label className="innerpagetitle float-left">{t("Newsletter Subscription")}</label>
                  <a onClick={() => ActivePage(1)}>
                    <ArrowForwardIosIcon className="float-right" />
                  </a>
                </div> */}
                {/* <div className="col-md-4 col-4  text-right d-flex align-items-center justify-content-end">
                  <span className="switch-label innerpagetitle">
                    {newsletterSliderState === true ? "Yes" : "No"}
                  </span>
                  <label className="switch">
                    <input
                      type="checkbox"
                      name="newsletterSubscription"
                      className="float-right"
                      checked={newsletterSliderState}
                      onChange={NewsletterSubscription}
                    />

                    <span className="slider round"></span>
                  </label>
                </div> */}
              {/* </div>
            </div> */}
            <div
              className={
                InstallAPP === "true" ? "hide-class" : "col-lg-12 col-12 my-3 px-2"
              }
            >
              <p className="float-left innerpagetitle">
                {t("Install BCrew App")}
              </p>
              {/* <button className="app-install-button float-right bg-color">
              {t("install")}
            </button> */}
              <InstallPWA />
            </div>
          </div>


        </div>
      </div>

    )
  }
  return (
    <>
      {
        // Check Desktop mode or not
        // For desktop mode show listview and detail view side by side
        DesktopMode === true ? <div className="Desktop-mode-list-and-detail-view layoutSpace">
          <div className="Desktop-mode-list-view float-lg-left col-lg-5 pr-0">
            <div className="customscroll">{SettingsPage()}</div>
          </div>
          <div className="Desktop-mode-Detail-view float-lg-left col-lg-7 pr-4 pl-1">
            <div className="customscroll"> {(defaultPage === 1 || defaultPage === '1') && <Language updateLanguagesPage={updateLanguagesPage} />}
              {(defaultPage === 2 || defaultPage === '2') && <About />}
              {(defaultPage === 3 || defaultPage === '3') && <Disclaimer />}
              {(defaultPage === 4 || defaultPage === '4') && <TermsCondition />}
              {/* {(defaultPage === 5 || defaultPage === '5') && <NewsletterSubscription />} */}
            </div>
          </div>
        </div> : (listviewMode === false && params.id === undefined ? SettingsPage() :
          <>
            {(defaultPage === 1 || defaultPage === '1') && <Language backButton={backButton} />}
            {(defaultPage === 2 || defaultPage === '2') && <About backButton={backButton} />}
            {(defaultPage === 3 || defaultPage === '3') && <Disclaimer backButton={backButton} />}
            {(defaultPage === 4 || defaultPage === '4') && <TermsCondition backButton={backButton} />}
            {/* {(defaultPage === 5 || defaultPage === '5') && <NewsletterSubscription backButton={backButton} />} */}
          </>
        )
      }
    </>

  );
}
