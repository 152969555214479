import React, { PureComponent } from "react";
import throttle from "lodash/throttle";
import PDFReader from "./PDFReader";

export default class PDFViewer extends PureComponent {
  constructor(props) {
    super(props);
    this.DesktopMode = localStorage.getItem("DeviceMode").includes("Desktop");
    this.state = { width: null };
    this.throttledSetDivSize = throttle(this.setDivSize, 500);
  }

  componentDidMount() {
    this.setDivSize();
    window.addEventListener("resize", this.throttledSetDivSize);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.throttledSetDivSize);
  }

  setDivSize = () => {
    this.setState({
      width: this.DesktopMode
        ? 700
        : this.pdfWrapper.getBoundingClientRect().width > 900
        ? 900
        : this.pdfWrapper.getBoundingClientRect().width,
    });
  };

  render() {
    return this.DesktopMode === true ? (
      <div
        id="row"
        style={{
          height: "100vh",
          width: "72vw",
          display: "flex",
          overflow: "hidden",
          alignContent: "flex-start",
          justifyContent: "center",
        }}
      >
        {/* style={{ width: "10vw", height: "100vh" }} */}
        <div id="placeholderWrapper" />
        <div
          id="pdfWrapper"
          style={{ width: "70vw" }}
          ref={(ref) => (this.pdfWrapper = ref)}
        >
          <PDFReader
            pdf={this.props.pdf}
            wrapperDivSize={this.state.width}
            type={this.props.type}
          />
        </div>
      </div>
    ) : (
      <div
        id="row"
        style={{
          height: "100vh",
          // width: "92vw",
          display: "flex",
          overflow: "hidden",
          alignContent: "flex-start",
          justifyContent: "center",
        }}
      >
        {/* style={{ width: "10vw", height: "100vh" }} */}
        <div id="placeholderWrapper" />
        <div
          id="pdfWrapper"
          style={{ width: "90vw" }}
          ref={(ref) => (this.pdfWrapper = ref)}
        >
          <PDFReader
            pdf={this.props.pdf}
            wrapperDivSize={this.state.width}
            type={this.props.type}
          />
        </div>
      </div>
    );
  }
}
