import React, { useState, useEffect } from 'react';
import { TermsConditions } from "../../Services/ApiEndPoints";
import { APICALL } from "../../Services/ApiServices";
import { t } from '../../Translation/Translation';
import { useHistory } from 'react-router-dom';
import Alert from 'react-bootstrap/Alert'
import '../../Static/css/common.css';
import BackButtonComponent from '../../Utilities/BackButtonComponent';
import BackButton from "../../Utilities/BackButton";


export default function TermsCondition({ backButton }) {
  const [termsCondions, setTermsConditions] = useState(".");
  const history = useHistory();
  const [message, setMessage] = useState('');
  const [form, setState] = useState({
    "termsStatus": false
  });
  const [termsCondition, settermsCondition] = useState(JSON.parse(localStorage.getItem("termsCondition")))
  const [firstlogin] = useState(JSON.parse(localStorage.getItem("firstlogin")))
  let User_id = localStorage.getItem("id");
  const DesktopMode = (localStorage.getItem('DeviceMode')).includes('Desktop');
  const [backbuttonUrl, setbackbuttonUrl] = useState();
  const [urlState, seturlState] = useState(false);

  useEffect(() => {
    const res = {
      "user_id": User_id
    }
    APICALL.service(TermsConditions, "POST", res)
      .then(result => {
        if (result != null)
          setTermsConditions(result.terms_and_conditions);
      });
  }, []);
  function createMarkup() {
    if(termsCondions){
      let data = termsCondions.replaceAll("<p></p>", "<br />");
      return { __html: data };
    }
    return { __html: termsCondions };
  }

  // const updateAPI=()=>{
  //   let termsData={
  //     "terms_and_conditions": true
  //   }
  //   APICALL.service(UserProfile + User_id, "PUT", termsData, 0, 0)
  //   .then(result => {
  //     console.log(result);
  //   })
  // }
  const updateTerms = async e => {
    e.preventDefault();
    setMessage("");
    if (!JSON.parse(form.termsStatus)) {
      setMessage("Please accept terms & conditions.");
      return;
    }
    else {
      // updateAPI();
      var val = e.target.value;
      if (val === "submit") {
        localStorage.setItem("termsCondition", true);
        history.replace("/");
      } else {
        settermsCondition(true);
        localStorage.setItem("termsCondition", true);
        localStorage.setItem("firstlogin", false);
        // history.replace("/update-profile");
        history.replace("/myprofile");
      }
      // const termsData={
      //   "terms_and_conditions": true
      // }
      // await APICALL.service(UserProfile + User_id, "PUT", termsData).then((result) => {
      //   if (result != null) {
      //     settermsCondition(result.terms_and_conditions);
      //     if (val === "submit") {
      //       localStorage.setItem("termsCondition", true);
      //       history.replace("/");
      //     } else {
      //       settermsCondition(true);
      //       localStorage.setItem("termsCondition", true);
      //       localStorage.setItem("firstlogin", false);
      //       history.replace("/update-profile");
      //     }
      //   }
      // });

    }
  }
  const updateField = e => {
    //Updating the details
    if (e.target.name === "termsStatus") (e.target.value = !JSON.parse(e.target.value));
    setState({
      ...form,
      [e.target.name]: e.target.value
    });
  };

  /***
  * Store url in session storage for back button functionality
  */
  useEffect(() => {
    if (urlState === false) {
      setbackbuttonUrl('/settings/4');
      seturlState(true);
    }
  }, [])

  //Remove the last item from array and push the url for back button 
  function historyBack() {
    let response = BackButton("/settings");
    if (response === false) {
      backButton(false, 4)
      history.push('/settings');
    }
    else {
      history.push(response);
    }
  }

  return (
    <div className="container customContainer  termsandcondition py-3">
      {/* <div className="col-md-12  mt-4">
         <div className="row">
         <div className={((termsCondition===false)||(termsCondition===null))?"hide-class":"col-2"}> <p className="browserback" onClick={history.goBack}>back</p></div>
          <h1 className={((termsCondition===false)||(termsCondition===null))?"pagetitle textcolor1 col-10 pr-0 text-center":"pagetitle textcolor1 col-10 pl-0 text-center"}>{t("Terms and conditions")}</h1>
         </div>
        </div> */}
      <div className="col-md-12 topsection-wrap  mt-0 px-0 pt-4">
        <p className={((termsCondition === false) || (termsCondition === null) || (firstlogin === true) || (DesktopMode === true)) ? "hide-class" : "browserback"} onClick={historyBack}>back</p>
        {backbuttonUrl && <BackButtonComponent backButtonLink={backbuttonUrl} />}
        <h1 className="pagetitle textcolor1 text-center">{t("Terms and conditions")}</h1>
      </div>
      {message.length > 0 && (
        <Alert variant="danger" onClose={() => setMessage("")} dismissible>
          <p>{message}</p>
        </Alert>
      )}

      <div className={((termsCondition === false) || (termsCondition === null) || localStorage.getItem('isAuthenticated') === 'false') ? "col-md-12 my-4 scroll-y" : "col-md-12 my-4"}>
        <div dangerouslySetInnerHTML={createMarkup()} />
      </div>
      {((termsCondition === false) || (firstlogin === true)) ?
        <div className="col-md-12 col-12 ">
          <label className="form-check-label checkboxWrap">
            <input
              className="form-check-input"
              name="termsStatus"
              value={form.termsStatus}
              type="checkbox"
              onChange={updateField}
            />
            <span className="text-muted">
              {t('I accept the Terms')} & {('Conditions mentioned above')}.
                  </span>
            <span className="checkmark"></span>
          </label>
          <div className="col-md-12 text-center mt-5 p-0 mb-5 pb-5">
            {((localStorage.getItem('firstlogin') === 'true')) ?
              <button type="submit" className="btn mybtn btn-light tx-tfm bg-color w-100 desktop-button" onClick={updateTerms} >{t(' Save & Continue ')}</button>
              : <button type="submit" className="btn mybtn btn-light tx-tfm bg-color w-100" value="submit" onClick={updateTerms}>{t(' Submit ')}</button>}
          </div>
        </div> : null}

    </div>
  );
}

