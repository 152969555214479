import React, { useState, useEffect } from "react";
import ItemDetails from "../Common/PageItemDetails";
import { t } from "../../Translation/Translation";
import MeetingDetails from "./MeetingDetails";
import { useParams } from "react-router";
import { APICALL } from "../../Services/ApiServices";
import { MeetingsList } from "../../Services/ApiEndPoints";
import SearchBoxWithSubmit from "../Common/SearchBoxWithSubmit";
import BackButtonComponent from "../../Utilities/BackButtonComponent";
import BackButton from "../../Utilities/BackButton";
import { useHistory } from "react-router-dom";
import InfiniteScroll from "react-infinite-scroll-component";
import FilterPopUp from "../../Utilities/Popup/FilterPopup";
import "../Footer/footer.css"

function MeetingRooms(props) {
  const [offset, setoffset] = useState(0);
  const [hasMore, sethasMore] = useState(true);
  const [DeviceMode, setDeviceMode] = useState(false);
  const [defaultitem, setdefaultitem] = useState();
  const [listviewMode, setlistviewMode] = useState(false);
  const [meetingData, setmeetingData] = React.useState();
  const [messageResponse, setmessageResponse] = useState("");
  const [dataLength, setdataLength] = useState();
  const [IdList, setIdList] = useState();
  const [backbuttonUrl, setbackbuttonUrl] = useState();
  const [urlState, seturlState] = useState(false);
  const [searchVal, setsearchVal] = useState("");
  const [searchState, setSearchState] = useState(false);
  const [filterPopup, setFilterPopup] = useState(false);
  const history = useHistory();
  const [locationIds, setLocationIds] = useState([]);
  const [countryIds, setCountryIds] = useState([]);
  const [capacity, setCapacityValue] = useState("");
  const [highlightitem, setHighlightitem] = useState();
  const [clearFilterValues,setClearFilterValues] = useState(false);

  let params = useParams();

  let locationID = "";

  if (props.location.state) {
    locationID = props.location.state.location;
    localStorage.setItem("MeetingroomLocation", props.location.state.location);
  }

  useEffect(() => {
    if (urlState === false) {
      if (params.id === undefined) {
        setbackbuttonUrl("/meetingrooms");
      } else {
        setbackbuttonUrl("/meetingrooms/" + params.id);
      }
      seturlState(true);
    }
  }, []);

  useEffect(() => {
    if (localStorage.getItem("MeetingroomLocation") && !locationID) {
      localStorage.setItem("MeetingroomLocation", "");
    }
    const get_id = localStorage.getItem("id");
    if (locationID && clearFilterValues === true){
      locationID = '';
    }
    let data = {
      flow: "room",
      locationid: locationIds,
        // ? locationID
        // : localStorage.getItem("MeetingroomLocation"),
      offset: offset,
      search: locationID
        ? searchVal || locationID
        : searchVal,
      // search: searchVal,
      countryid: countryIds,
      capacity: capacity
    };

    data["locationid"] = data["locationid"] == null ? "" : data["locationid"];
    data["locationid"] = ((data["locationid"] == null || data["locationid"] == "") && (locationIds != [] || locationIds != undefined)) ? locationIds : data["locationid"];
    let meetingRoomsList;

    let roomsId = [];
    APICALL.service(MeetingsList + "/" + get_id, "PUT", data).then((result) => {
      try {
        if (result) {
          if (result.status === 200) {
            setmessageResponse("");
            if (!hasMore) sethasMore(true);
            meetingRoomsList = result.schedules;
            const firstItem = (meetingRoomsList[0].id !== undefined) ? meetingRoomsList[0].id : meetingRoomsList.id;
            setdataLength(meetingRoomsList.length);
            if (params.id === undefined) {
              setHighlightitem({"id":firstItem, "type": "meetingrooms"});
              setdefaultitem(firstItem);
            }
            Object.keys(meetingRoomsList).map((value, i) =>
              roomsId.push(meetingRoomsList[value].id)
            );
            setIdList(roomsId);
            setmeetingData(meetingRoomsList);
          } else if (result.status === 201) {
            sethasMore(false);
            setmeetingData();
            setIdList();
            setdefaultitem();
            setdataLength();
            setmessageResponse(result.schedules);
          } else if (result.status === 401) {
            sethasMore(false);
            setmeetingData();
            setIdList();
            setdefaultitem();
            setdataLength();
            setmessageResponse(result.data);
          }
        }
      } catch (e) {
        console.error(e);
      }
    });
    if (params.id !== undefined) {
      setHighlightitem({"id":params.id, "type": "meetingrooms"});
      setdefaultitem(params.id);
      //checking device mode
      if (localStorage.getItem("DeviceMode") === "Desktop") {
        setlistviewMode(false);
      } else {
        setlistviewMode(true);
      }
    }
    setClearFilterValues(false);
  }, [searchState]);

  React.useEffect(() => {
    if (localStorage.getItem("DeviceMode") === "Desktop") {
      setDeviceMode(true);
      setlistviewMode(true);
    }
  }, []);

  //backbotton form details page to listview
  const backButton = (e) => {
    setlistviewMode(e);
  };
  //TO update detail page when user click on list item
  const updatesId = (e) => {
    setHighlightitem({"id": e, "type": "meetingrooms"});
    setdefaultitem(e);
    if (localStorage.getItem("DeviceMode") === "Desktop") {
      setlistviewMode(false);
    } else {
      setlistviewMode(true);
    }
  };

  function historyBack() {
    let response = BackButton();
    history.push(response);
  }

  //search parameter passing to the function
  const SearchElements = (e) => {
    setsearchVal(e.target.value);
    // filterData(e.target.value);
  };
  const searchSubmit = (e) => {
    e.preventDefault();
    setoffset(0);
    setLocationIds([])
    setCountryIds([]);
    setCapacityValue("");
    setSearchState(!searchState);
  };

  /**
   * I would like to load just 10 searching results and if user scroll to the
   * last search element than next 10 results load automatically.
   */
  function fetchMoreData() {
    let count = offset + 1;
    let data = {
      flow: "room",
      locationid: locationIds,
      offset: count,
      countryid: countryIds,
      capacity: capacity,
      search: locationID
        ? searchVal || locationID
        : searchVal,
    };
    setoffset(count);
    data["locationid"] = data["locationid"] == null ? "" : data["locationid"];
    data["locationid"] = ((data["locationid"] == null || data["locationid"] == "") && locationIds != []) ? locationIds : data["locationid"];
    let roomsId = [];
    const get_id = localStorage.getItem("id");
    APICALL.service(MeetingsList + "/" + get_id, "PUT", data).then((result) => {
      try {
        if (result) {
          if (result.status === 404) {
            sethasMore(false);
          }
          if (result.status === 200) {
            setmessageResponse("");
            if (!hasMore) sethasMore(true);
            let meetingRoomsList = result.schedules;
            setdataLength(dataLength + meetingRoomsList.length);
            Object.keys(meetingRoomsList).map((value, i) =>
              roomsId.push(meetingRoomsList[value].id)
            );
            setIdList(IdList.concat(roomsId));
            setmeetingData(meetingData.concat(meetingRoomsList));
          } else if (result.status === 201) {
            sethasMore(false);
          } else if (result.status === 401) {
            sethasMore(false);
          }
        }
      } catch (e) {
        console.error(e);
      }
    });
  }

  const listMode = () => {
    return (
      <div className="container customContainer">
        <div className="mb-5">
          <div className="justify-content-md-center rounded mb-5">
            <div className="post">
              <div className="mx-0">
                <div className="col-md-12 topsection-wrap  mt-0 px-0 pt-4">
                  <p
                    className={
                      DeviceMode === true ? "hide-class" : "browserback"
                    }
                    onClick={historyBack}
                  >
                    back
                  </p>
                  {backbuttonUrl && (
                    <BackButtonComponent backButtonLink={backbuttonUrl} />
                  )}
                  <h1 className="pagetitle textcolor1 text-center meeting">
                    {t("Meeting rooms")}
                  </h1>
                </div>
                <div className="col-md-12 col-12 p-0">
                  <SearchBoxWithSubmit
                    onSearchValue={SearchElements}
                    searchVal={searchVal}
                    searchSubmit={searchSubmit}
                  />
                </div>
                <div className="col-md-12 col-12 p-0">
                  <div className="input-group col-12 my-2 p-0 filter-parent">
                  <button
                    className="bg-color filter-meetingRoom"
                    onClick={clearFilter}
                  >
                    {t("Clear Filter")}
                  </button>
                  <button
                    className="bg-color filter-meetingRoom ml-3"
                    // onClick={setFilterPopup(true)}
                    onClick={() => setFilterPopup(true)}
                  >
                    {t("Filter")}
                  </button>
                  </div>
                </div>
              </div>
              {meetingData === undefined && messageResponse.length === 0 && (
                <div className="col-12 text-center">
                  <h5 className="text-center textcolor1">
                    {" "}
                    {t("Loading meetings")}...
                  </h5>{" "}
                </div>
              )}

              {meetingData && meetingData.length >= 1 && (
                <InfiniteScroll
                  dataLength={meetingData.length}
                  next={fetchMoreData}
                  hasMore={hasMore}
                  loader={
                    <h5 className="textcolor1 text-center">{t("Loading")}.</h5>
                  }
                  scrollableTarget="scrollableDiv"
                >
                  {meetingData.length >= 1 &&
                    Object.keys(meetingData).map((value, i) => {
                      return (
                        <div key={i}>
                          <ItemDetails
                            data={meetingData[value]}
                            type="Meeting"
                            updatesId={updatesId}
                            activeItem={highlightitem}
                          />
                        </div>
                      );
                    })}
                </InfiniteScroll>
              )}

              {messageResponse.length > 0 ? (
                <div className="col-md-12 col-12 text-center">
                  <h4 className="textcolor1">{t(messageResponse)}</h4>
                </div>
              ) : null}
            </div>
          </div>
        </div>
      </div>
    );
  };

  const handleFilter = (data) =>{

    if(data['capacity'] && data['capacity'] != []){
      setCapacityValue(data['capacity'])
    }else{
      setCapacityValue('')
    }

    if(data['location'] && data['location'] != []){
      setLocationIds(data['location'])
    }else{
      setLocationIds([])
    }

    if(data['country'] && data['country'] != []){
      setCountryIds(data['country'])
    }else{
      setCountryIds([])
    }
    // setOldData(oldData)
    setsearchVal('');
    setoffset(0)
    setFilterPopup(false)
    setSearchState(!searchState);
  }

  const clearFilter = () =>{
    setClearFilterValues(true);
    locationID ='';
    setCapacityValue('')
    setLocationIds([])
    setCountryIds([])
    setsearchVal('')
    setoffset(0)
    setSearchState(!searchState);
  }

  return (
    <div>
      {DeviceMode === true ? (
        <div className="Desktop-mode-list-and-detail-view layoutSpace">
          <div className="Desktop-mode-list-view float-lg-left col-lg-5 pr-0">
            <div className="customscroll" id="scrollableDiv">
              {listMode()}
            </div>
          </div>
          <div className="Desktop-mode-Detail-view float-lg-left col-lg-7 pr-4 pl-1">
            <div className="customscroll">
              {defaultitem && (
                <MeetingDetails
                  updatesEntityId={defaultitem}
                  dataLength={dataLength}
                  IdList={IdList}
                  updateDataforID={updatesId}
                />
              )}
            </div>
          </div>
        </div>
      ) : listviewMode === false && params.id === undefined ? (
        listMode()
      ) : (
        defaultitem && (
          <MeetingDetails
            updatesEntityId={defaultitem}
            dataLength={dataLength}
            IdList={IdList}
            updateDataforID={updatesId}
            backButton={backButton}
          />
        )
      )}
      <FilterPopUp
        show={filterPopup}
        stateLocation = {localStorage.getItem("MeetingroomLocation")}
        type={"meeting_room"}
        onHide={() => setFilterPopup(false)}
        title={"Filter Meetings"}
        location={true}
        country={true}
        capacity={true}
        locationIds={locationIds}
        countryIds={countryIds}
        capacityValue={capacity}
        handleFilter={handleFilter}
        clearFilterValues={clearFilterValues}
      />
    </div>
  );
}

export default MeetingRooms;
