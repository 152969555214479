import React, {useState, useEffect} from 'react'
import SearchBox from "../Common/SearchBox";
import LazyLoad from "react-lazyload";
import folderIcon from "./Icons/Folder.png";
import pdfIcon from "./Icons/pdf.png";
import videoIcon from "./Icons/play-button.png";
import { t } from '../../Translation/Translation';
import {APICALL} from '../../Services/ApiServices';
import "./Library.css";


const LibraryHome = (props) => {

      const [Librarylist, setLibrarylist] = useState();
      const [fixeddataList, setFixedDataList] = useState([]);
      const [searchVal, setsearchVal] = useState("");
      const excludeColumns = ["id", "data"]; 
    
  useEffect(() => {
    if(searchVal === "")
      setLibrarylist(props.Librarylist);
      setFixedDataList(props.dataList);
    }, [props])

  //search elements entered
  const SearchElements = (e) => {
    setsearchVal(e.target.value);
    filterData(e.target.value);
  };

  //filter records by search text
  const filterData = (value) => {
    const lowercasedValue = value.toLowerCase().trim();
    if (lowercasedValue === "") setLibrarylist(fixeddataList);
    else {
      const filteredData = (fixeddataList ? Object.values(fixeddataList).filter((item, i) => {
        return Object.keys(item).some(key =>
          item[key] != null ? excludeColumns.includes(key) ? false : item[key].toString().toLowerCase().includes(lowercasedValue) : null
        );
      }) : [null])
      setLibrarylist(filteredData)
    }
  }

    
    
  const getLibraryIcon = (value) => {
    var file_type = APICALL.get_url_extension(value.data[0]);
    if (value.data.length === 1) {
      var videos = ["avi", "mpeg", "mp4", "mpg"];
      if (file_type === "pdf") {
        return (
          <img
            src={pdfIcon}
            alt="Lights"
            width="100%"
            className="libicons libinnerdetail"
          />
        );
      } else if (
        videos.includes(file_type.toLowerCase())
      ) {
        return (
          <img
            src={videoIcon}
            alt="Lights"
            width="100%"
            className="libicons libinnerdetail"
          />
        );
      } else {
        return (
          <img
            src={value.data[0]}
            alt="Lights"
            width="100%"
            className="image libicons libinnerdetail"
          />
        );
      }
    } else {
      return  <img
      src={folderIcon}
      alt="Lights"
      width="100%"
      className="libicons libinnerdetail"
    />;
    }
  };

  return (
    <div>
      <SearchBox onSearchValue={SearchElements} searchVal={searchVal} />
      <div className="container px-0">
      <div className="postclistlibrary col-md-12 px-0 ">

        {Librarylist === undefined && (
          <div className="col text-center">
            <h5 className="textcolor1">{t('Loading libraries')}. </h5>{" "}
          </div>
        )}
        {Librarylist &&
          Object.keys(Librarylist).map((value, i) => {
            return (
              <div
                key={value}
                className="itemsWrap"
                onClick={() => props.previewContent(Librarylist[value]["id"])}
              >
                <LazyLoad
                  key={Librarylist[value]}
                  height={100}
                  offset={[-100, 100]}
                >
                  <div className="contentWrap">
                    <div className={Librarylist[value]["id"] ===  props.highlightitem ? "contentoverlay highlight-activeItem" : "contentoverlay"}></div>
                    {/* <img
                      src={getLibraryIcon(Librarylist[value])}
                      alt="Lights"
                      width="100%"
                      className="libicons"
                    /> */}
                    {getLibraryIcon(Librarylist[value])}
                    <div className="caption bg-color">
                      <h5 className="mb-0 small">
                        {/* {(Librarylist[value].data.length > 1 && Librarylist[value].location)
                          ? (Librarylist[value].location)  
                          : Librarylist[value].title} */}
                          {(Librarylist[value].location) !== "" ?(Librarylist[value].location):Librarylist[value].title}
                      </h5>
                    </div>
                  </div>
                </LazyLoad>
              </div>
            );
          })}
        {Librarylist === undefined ||
          (Librarylist.length === 0 && (
            <div className="col text-center">
              <h5 className="textcolor1">{t('No library')} </h5>{" "}
            </div>
          ))}
      </div>
    </div>
    </div>
  );
};

export default LibraryHome
