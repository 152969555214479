/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect, useRef } from 'react';
// import LongPressable from 'react-longpressable';
import ReactionsPopup from "./ReactionPopup"
import { likes } from "../../Services/ApiEndPoints";
import { APICALL } from "../../Services/ApiServices";
import filledLikeIcon from './images/like.png';
import filledHeartIcon from './images/heart.png';
import filledIdeaIcon from './images/love.png';
import filledClappIcon from './images/clap.png';
import likeIcon from './images/like-l.png';
import './reactionpopup.css';

const Reactions = ({reactions, entryId, updateReactionsInList, reactionsList, newReactionEntryId, pageType}) => {

  const [reactPop, setReactPop] = useState(false);
  const [reaction, setReaction] = useState([]);
  const reactionClickPicker = useRef(null);
  const [delayHandler, setDelayHandler] = useState(null)
  let user_id = localStorage.getItem("id");

  // setting the reaction when reaction object is changing
  useEffect(() => {
    if(newReactionEntryId === entryId || newReactionEntryId === undefined ){
      setReaction(reactions)
    }
  }, [reactions])

  // setting the reaction when newReactionEntryId is changing
  useEffect(() => {
      setReaction(reactions)
  }, [newReactionEntryId])

  // setting the reaction when component is rendered
  useEffect(() => {
      setReaction(reactions)
  }, [])

  // react popup handler opens the popup after dealy
  const reactPopHandler = () => {
    setDelayHandler(setTimeout(() => {
      setReactPop(true);
  }, 500))
  }

  // closes the reaction popup when mouse leaves from the component
  const handleMouseLeave = () => {
    setDelayHandler(setTimeout(() => {
      setReactPop(false);
  }, 1000))
  }

  // rendering the reaction icons in popup
  const reactionIcon = (reactionKey) => {
    switch(reactionKey) {
      case "like":
        return getReactionDiv(filledLikeIcon);
      case 'love':
        return getReactionDiv(filledIdeaIcon);
      case 'heart':
        return getReactionDiv(filledHeartIcon);
      case 'clap':
        return getReactionDiv(filledClappIcon);
      default:
        return getReactionDiv(likeIcon);
    }
  }
// div structure for reaction icon
  const getReactionDiv = (icon) =>{
    return <span className="reaction-initial-wrap d-flex justify-content-end align-content-center align-items-center">
                <span className="d-flex" onClick={reactPopHandler}>
                {/* <span className="d-flex" onMouseOver={reactPopHandler} onMouseLeave={handleMouseLeave}> */}
                    <span className='d-flex div-width-reaction'>
                        <img src={icon} className='initial-reaction-icon'  alt={icon} />
                        {reaction.reactions_count > 0 &&
                      <span className='reaction-count div-width-reaction d-flex'>
                        {reaction.reactions_count}
                      </span>
                }
                    </span>
                </span>
                
            </span>
  }
  
  // whenever user clicks on reaction catches the event,
  //  and call api to update reaction and also updates in state also
  const HandleClickOnReaction = (e, reactionFromPop, entryIdFromPop) =>{
    e.preventDefault();
    setReactPop(false);
    let localReaction = reaction;
    let reactionId = reactionFromPop.id;
    let newReactionObj = newReactionChange(reactionFromPop, localReaction);
    setReaction(newReactionObj);
    updateReactionsInList(e, entryIdFromPop, newReactionObj, pageType);

    //  api call to fetch url from backend
     const param = {
      "user_id": user_id,
      "entry_id":entryIdFromPop,
      "reaction_id":reactionId,
    }
     APICALL.service(likes, "POST", param, 0, 0)
     .then(result => {
       if (result != null)
       // set result in state
         if(result.status === 201){
          //  api result to update in state
          setReaction(result.data.reactions);
          updateReactionsInList(e, entryIdFromPop, result.data.reactions, pageType);
         }else{
          setReaction([]);
         }
     });
    
  }

  // handling change in state when reaction changes 
  const newReactionChange = (reactionFromPop, localReaction) => {
    let oldReactionKey = localReaction.user_reaction_key;
    let oldReactionId = localReaction.user_reaction_id;
    // if already user reacted and wanted to remove it 
    if(oldReactionId === reactionFromPop.id){
      localReaction.reactions_count = localReaction.reactions_count - 1;
      localReaction.user_reaction_key = null;
      localReaction.user_reaction_id = null;
      localReaction.user_reaction_status = false;
      localReaction.reaction_count_obj = updateReactionCountObject(localReaction.reaction_count_obj, reactionFromPop.reactionKey, -1);
      // new reaction from user for this entry
    }else if(oldReactionId === null){
      localReaction.reactions_count = localReaction.reactions_count + 1;
      localReaction.user_reaction_key = reactionFromPop.reactionKey;
      localReaction.user_reaction_id = reactionFromPop.id;
      localReaction.user_reaction_status = true;
      localReaction.reaction_count_obj = updateReactionCountObject(localReaction.reaction_count_obj, reactionFromPop.reactionKey, +1);
      // upadting user reaction for entry
    }else{
      localReaction.user_reaction_key = reactionFromPop.reactionKey;
      localReaction.user_reaction_id = reactionFromPop.id;
      localReaction.reaction_count_obj = updateReactionCountObject(localReaction.reaction_count_obj, oldReactionKey, -1);
      localReaction.reaction_count_obj = updateReactionCountObject(localReaction.reaction_count_obj, reactionFromPop.reactionKey, +1);
    }
    return localReaction;
  }

  // handling the reaction count in reaction count object
  const updateReactionCountObject = (reaction_count_obj, reactionKey, count) => {
    switch(reactionKey){
      case('like'):
        reaction_count_obj.likes_count = reaction_count_obj.likes_count + count;
        break;
      case('love'):
        reaction_count_obj.love_count = reaction_count_obj.love_count + count;
        break;
      case('clap'):
        reaction_count_obj.clap_count = reaction_count_obj.clap_count + count;
        break;
      case('heart'):  
        reaction_count_obj.heart_count = reaction_count_obj.heart_count + count;
        break;
    }
    return reaction_count_obj;
  }

  // handling the click outside the popup to closse popup
  useEffect(() => {
    function handleClickOutside(event) {
      if (reactionClickPicker.current && !reactionClickPicker.current.contains(event.target)) {
        setReactPop(false);
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [reactionClickPicker]);
  
  let reactedKey = reaction.user_reaction_key;
  return (
    <span className="parent-reaction-div"  ref={reactionClickPicker}>
      {
        reactPop ?
        <ReactionsPopup 
            data={reactionsList} 
            ReactedKey={reactedKey}
            clickOnReacion={HandleClickOnReaction}
            entryId={entryId}
            reactionCountObj = {reaction.reaction_count_obj}
            />
        : null
      }
      {(!reaction.user_reaction_status) ? 
        reactionIcon("")
      :
      reactionIcon(reactedKey)
      }
      
    </span>
  );
}

export default Reactions;