import React from 'react';
import { Modal, Button } from "react-bootstrap";
import { t } from '../../Translation/Translation'
import { BrowserRouter as Router, Redirect } from "react-router-dom";
import { useHistory } from 'react-router-dom';


const modelPopup = (props) => {
    return (
      <Modal
        show={props.show}
        onHide={props.onHide}
        size={props.size ? props.size : "lg"}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        dialogClassName="desktoppopup"
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter" className="textcolor1">
            {t(props.title)}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>{props.body}</p>
        </Modal.Body>
        <Modal.Footer>
          <Button className="bg-color mybtn text-center mr-3" onClick={props.onHide}>
            {t('Close')}
          </Button>
        <div className="btn bg-color mybtn text-center block">
          <a className="text-white" href={"/ideabox"}>
            {" "}
            {t("Submit another idea")}{" "}
          </a>
        </div>
        </Modal.Footer>
      </Modal>
    );
}

export default modelPopup