import React, { useState, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { OCAlert } from "@opuscapita/react-alerts";
import { useAppContext } from "../../Config/contextLib";
import { t } from "../../Translation/Translation";
import { APICALL } from "../../Services/ApiServices";
import { LoginUrl } from "../../Services/ApiEndPoints";
import firebase from "firebase/app";
import { Notifications, DataTranslations } from "../../Services/ApiEndPoints";
// import { result } from "lodash";

const SsoLoginMiddleware = (props) => {
  const search = useLocation().search;
  const history = useHistory();
  const ALERT_ERROR_TIME = 2000;

  const { isAuthenticated, userHasAuthenticated } = useAppContext();

  useEffect(() => {
    if (!JSON.parse(localStorage.getItem("translations"))) {
      //Getting translation and storing in the localstorage..
     // APICALL.service(DataTranslations, "GET", "", 0, 0).then((result) => {
      //  localStorage.setItem("translations", JSON.stringify(result));
      //});
    }
  }, []);

  useEffect(() => {
    console.log("loggging");
    let scode = new URLSearchParams(search).get("scode");
    if (scode === "200") {
      let token = new URLSearchParams(search).get("token");

      let data = {
        auth_token: token,
        actions: "sso-login",
      };

      APICALL.service(LoginUrl, "POST", data)

        .then((res) => {
	  console.log(res);
          if (res.status === 200) {
            console.log(res);
            let auth_token = res.data.auth_token;
            askForPermissioToReceiveNotifications();
            localStorage.setItem("id", res.data.user_details[0].id);
            localStorage.setItem(
              "firstname",
              res.data.user_details[0].firstName
            );
            localStorage.setItem(
              "Roles",
              JSON.stringify(res.data.user_details[0].roles)
            );
            localStorage.setItem("loginType", "sso");
            localStorage.setItem("auth_token", auth_token);
            if (res.data.user_details[0].lastLoginDate == null) {
              userHasAuthenticated(false);
              localStorage.setItem("firstlogin", true);
              localStorage.setItem("isAuthenticated", false);
              localStorage.setItem("termsCondition", false);
              document.body.classList.remove("login");
              history.push("/termsConditions");
            } else {
              userHasAuthenticated(true);
              localStorage.setItem("isAuthenticated", true);
              document.body.classList.remove("login");
              // props.location.state._getPreviewAccessRights();
              // history.push(localStorage.getItem('preservedURL'));
              (localStorage.getItem("preservedURL").includes("preview") > 0) ?  window.location.assign(localStorage.getItem("preservedURL")) : history.push(localStorage.getItem("preservedURL"));
            }

          } else {
            history.push("/login");
            OCAlert.closeAlerts();
            OCAlert.alertError(t(res.data), { timeOut: ALERT_ERROR_TIME });
          }
        })
        .catch((error) => {
          history.push("/login");
          console.log(error);
        });
    } else if (scode === "401") {
      history.push("/login");
      OCAlert.closeAlerts();
      OCAlert.alertError(t("User is not logged in."), {
        timeOut: ALERT_ERROR_TIME,
      });
    } else if (scode === "404") {
      history.push("/login");
      OCAlert.closeAlerts();
      OCAlert.alertError(t("User not exists."), { timeOut: ALERT_ERROR_TIME });
    } else if (scode === "400") {
      history.push("/login");
      OCAlert.closeAlerts();
      OCAlert.alertError(t("Bad request."), { timeOut: ALERT_ERROR_TIME });
    }
  });

  const askForPermissioToReceiveNotifications = async (e) => {
    try {
      const messaging = firebase.messaging();
      await messaging.requestPermission();
      const token = await messaging.getToken();
      let fcm = {
        user_id: localStorage.getItem("id"),
        reg_token: token,
      };
      APICALL.service(Notifications, "POST", fcm).then((result) => {
        console.log("Push Notifications", result);
      });
      return token;
    } catch (error) {
      console.error("notfcm", error);
    }
  };

  return <div></div>;
};

export default SsoLoginMiddleware;
