import React from 'react'
import '../../Static/css/common.css'
import { t } from '../../Translation/Translation'

const SearchBox = ({ onSearchValue, searchVal}) => {
    return (
        <div>
        <div className="input-group col-12 my-4 searchsection p-0">
            <input className="form-control py-2 border-right-0 border float-right" type="search" onChange={onSearchValue} placeholder={t("Search")} value={searchVal} id="example-search-input" autoComplete="off"/>
                <span className="input-group-append">
                </span>
        </div>
        </div>
    )
}

export default SearchBox
