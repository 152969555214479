import React from "react";
import { Route } from "react-router-dom";
import Login from "../Login/Login/Login";
import Profile from "../Login/Profile/UpdateProfile";
import ForgotPassword from "../Login/Login/ForgotPassword";
import News from "../Pages/News/News";
import Home from "../Pages/Home/Home";
import Events from "../Pages/Events/Events";
import WhoisWho from "../Pages/WhoIsWho/whoiswho";
import Myprofile from "../Login/Profile/Myprofile";
import OtpVerify from "../Login/Login/OtpVerify";
import Settings from "../Pages/Settings/Settings";
import Library from "../Pages/Library/Library";
import LibraryView from "../Pages/Library/LibraryView";
import LibraryList from "../Pages/Library/LibraryList";
import TermsConditions from '../Pages/Settings/TermsCondition';

import commentsdetails from '../Pages/Common/comments/CommentDetails';
import OurLocations from '../Pages/Locations/OurLocations';
import Polls from '../Pages/Polls/Polls';
import ServeyReport from '../Pages/Polls/Charts';
import LunchTips from "../Pages/LunchTips/LunchtipsList";
import AddLunchTips from "../Pages/LunchTips/AddLunchTip";
import EShop from "../Pages/EShop/EShop";
import Embrace from "../Pages/Embrace/Embrace";
import Discounts from "../Pages/Discounts/Discounts";
import SocialMedia from "../Pages/SocialMedia/SocialMedia";
import Vacancies from "../Pages/Vacancy/Vacancy";
import Ideabox from "../Pages/Ideabox/Ideabox";
import ToolBox from '../Pages/Toolbox/Toolbox';
import SsoLoginMiddleware from "../Login/Login/SsoLoginMiddleware";
import MeetingRooms from "../Pages/MeetingRooms/MeetingRooms";
import ScanQR from '../Pages/MeetingRooms/ScanQR';
import Signature from "../Pages/Signature/Signature";

const BaseRouter = (props) => (
  <div>
    {/* route starts here */}
    <Route exact path="/login" component={Login} />
    <Route exact path="/otp" component={OtpVerify} />
    <Route exact path="/forgotpassword" component={ForgotPassword} />
    <Route exact path="/" component={Home} />
    <Route exact path="/news" component={News} />
    <Route exact path="/news/:id" component={News} />
    <Route exact path="/events" component={Events} />
    <Route exact path="/events/:id" component={Events} />
    <Route exact path="/profile" component={Profile} />
    <Route exact path="/whoiswho" component={WhoisWho} />
    <Route exact path="/whoiswho/:id" component={WhoisWho} />
    <Route exact path="/myprofile" component={Myprofile} />
    <Route exact path="/myprofile/:id" component={Myprofile} />
    <Route exact path="/settings" component={Settings} />
    <Route exact path="/settings/:id" component={Settings} />
    <Route exact path="/termsConditions" component={TermsConditions} /> 
    <Route exact path="/commentsdetails/:id" component={commentsdetails} />

    <Route exact path="/library" component={Library} />
    <Route exact path="/library-view" component={LibraryView} />
    <Route exact path="/library-list/:id" component={LibraryList} />
    <Route exact path="/ourlocations" component={OurLocations} />
    <Route exact path="/ourlocations/:id" component={OurLocations} />
    <Route exact path="/polls" component={Polls} />
    <Route exact path="/polls/:id" component={Polls} />
    <Route exact path="/lunchtips" component={LunchTips} />
    <Route exact path="/servey-report/:id" component={ServeyReport} />
    <Route exact path="/addlunchtip/" component={AddLunchTips} />
    <Route exact path="/lunchtips/:id" component={LunchTips} />
    <Route exact path="/eshop" component={EShop} />
    <Route exact path="/embrace" component={Embrace} />
    <Route exact path="/discounts" component={Discounts} />
    <Route exact path="/SocialMedia" component={SocialMedia} />
    <Route exact path="/vacancy" component={Vacancies} />
    <Route exact path="/ideabox" component={Ideabox} />
    <Route exact path='/toolbox' component={ToolBox} />
    <Route exact path='/meetingrooms' component={MeetingRooms} />
    <Route exact path='/meetingrooms/:id' component={MeetingRooms} />
    <Route exact path='/scanQR' component={ScanQR} />
    <Route exact path='/signature' component={Signature} />
    <Route exact path='/signature/:id' component={Signature} />
    <Route exact path="/news/newsletter/:id" component={News} /> 
    
    {/* preview routes starts here */}
    <Route exact path="/preview/newsPosts/:id" component={News} /> 
    <Route exact path="/preview/locations/:id" component={OurLocations} /> 
    <Route exact path="/preview/events/:id" component={Events} /> 
    <Route exact path="/preview/surveys/:id" component={Polls} /> 
    <Route exact path="/preview/lunchtips/:id" component={LunchTips} /> 
    <Route exact path="/sso-middlewere" component={SsoLoginMiddleware} />
  </div>
);

export default BaseRouter;
