/* eslint-disable no-extend-native */
import { users } from "../../../Services/ApiEndPoints";
import { APICALL } from "../../../Services/ApiServices";
import { MentionsInput, Mention } from "react-mentions";
import React, { useState, useRef, useEffect } from "react";
import { StylesViaJss } from "substyle-jss";
import { NimblePicker } from "emoji-mart";
import "emoji-mart/css/emoji-mart.css";
import "./Comments.css";
import Alert from "react-bootstrap/Alert";
import { t } from "../../../Translation/Translation";
import data from "../data.json";

function CommentList({ addComment, updateField, types }) {
  const [comment, setComment] = useState("");
  const [message, setmessage] = useState(false);
  const [showEmojis, setShowEmojis] = useState(false);
  const emojiPicker = useRef(null);
  const [userdata, setuserdata] = useState("");
  const [mentionid, setmentionid] = useState();
  const [commentText, setcommentText] = useState("");
  const emojiRegex = new RegExp(
    "(?:[\u2700-\u27bf]|(?:\ud83c[\udde6-\uddff]){2}|[\ud800-\udbff][\udc00-\udfff]|[\u0023-\u0039]\ufe0f?\u20e3|\u3299|\u3297|\u303d|\u3030|\u24c2|\ud83c[\udd70-\udd71]|\ud83c[\udd7e-\udd7f]|\ud83c\udd8e|\ud83c[\udd91-\udd9a]|\ud83c[\udde6-\uddff]|[\ud83c\ude01-\ude02]|\ud83c\ude1a|\ud83c\ude2f|[\ud83c\ude32-\ude3a]|[\ud83c\ude50-\ude51]|\u203c|\u2049|[\u25aa-\u25ab]|\u25b6|\u25c0|[\u25fb-\u25fe]|\u00a9|\u00ae|\u2122|\u2139|\ud83c\udc04|[\u2600-\u26FF]|\u2b05|\u2b06|\u2b07|\u2b1b|\u2b1c|\u2b50|\u2b55|\u231a|\u231b|\u2328|\u23cf|[\u23e9-\u23f3]|[\u23f8-\u23fa]|\ud83c\udccf|\u2934|\u2935|[\u2190-\u21ff])",
    "g"
  );
  const flagRegex = new RegExp("(?:\ud83c[\udde6-\uddff]){2}");

  useEffect(() => {
    const get_id = localStorage.getItem("id");
    //fetching the all user list
    APICALL.service(users, "GET").then((result) => {
      if (result) {
        try {
          //filtering the current login user details
          const userMention = result.data
            .filter((e) => e.id !== parseInt(get_id))
            .map((myUser) => ({
              id: myUser.id,
              display: myUser.firstName + " " + myUser.lastName,
            }));
          setuserdata(userMention);
        } catch (e) {
          console.error(e);
        }
      }
    });
  }, []);
  const handleSelectEmoji = (emoji) => {
    setShowEmojis(true);
    //to send in the API for backend
    setcommentText(`${commentText} ${emoji.colons}`);
    //display in the text box for user
    setComment(`${comment} ${emoji.native}`);
  };

  const handleChange = (event, newValue, newPlainTextValue, mentions) => {
    //update tagging userId deatiails
    setmentionid(mentions);
    let text = event.target.value;
    ////display in the text box for user
    setComment(text);
    //to send in the API for backend
    setcommentText(text);
  };
  /**
   *
   * @param {*} text : This function accepts a text (string) and converts only the emoji's in it
   *  to the unicode format using the reglar expression
   * The charcodepoint function helps to get the latest version of the represenation of emoji in unicode format
   */
  const emojiToUnicode = (text) => {
    let newText = text;
    newText = newText.replace(emojiRegex, function (char) {
      let status = flagRegex.test(char);
      if (status) {
        return "\\u" + char.codePointAt(0) + "\\u" + char.codePointAt(2);
      } else {
        return "\\u" + char.codePointAt(char);
      }
    });

    return newText;
  };

  useEffect(() => {
    function handleClickOutside(event) {
      if (emojiPicker.current && !emojiPicker.current.contains(event.target)) {
        setShowEmojis(false);
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [emojiPicker]);

  /**
   *
   *  This function accepts a text (string) and converts only the emoji's in it
   *  to the unicode format using the reglar expression
   * The charcodepoint function helps to get the latest version of the represenation of emoji in unicode format
   */
  const _handleSubmit = () => {
    //checking commentText isEmpty or not
    if (commentText === "") {
      setmessage(true);
    } else if (!commentText.trim().length) {
      setmessage(true);
    } else {
      setcommentText("");
      setComment("");
      let newComment = commentText;
      newComment = newComment
        .split("@@@__")
        .join("<a class ='commentTag' href =\"/whoiswho/");
      newComment = newComment.split("^^^__").join('">@');
      newComment = newComment.split("@@@^^^").join("</a>");

      let user_id = [];
      //tagging userId list
      if (mentionid !== undefined) {
        mentionid.map((values) => user_id.push(values.id));
      }
      let UnicodeFormat = emojiToUnicode(newComment);
      let body = {
        tagged_id: user_id,
        comment: UnicodeFormat,
      };
      if (types === "details") {
        updateField(body);
      } else {
        addComment(body);
      }
    }
  };
  return (
    <StylesViaJss>
      <div className="mt-3 col-md-12 commentWrap">
        <div>
          {message === true ? (
            <Alert variant="danger" onClose={() => setmessage("")} dismissible>
              <p>{t("Please enter the comment")}.</p>
            </Alert>
          ) : null}
        </div>
        <div className="row writeComment">
          <MentionsInput
            value={comment}
            onChange={handleChange}
            //className="mentions"
            className="mentions commentBox mx-1 col-12"
            //classNames={Style}
            placeholder={t("Write a comment") + "."}
            allowSuggestionsAboveCursor={true}
          >
            <Mention
              type="user"
              trigger="@"
              data={userdata}
              markup="@@@____id__^^^____display__@@@^^^"
              className="mentions__mention"
              displayTransform={(id, name) => `@${name}`}
              //style={ background-color: #cee4e5}
              style={{
                backgroundColor: "#d20c14",
              }}
            />
          </MentionsInput>
          {showEmojis ? (
            <span className="emoji__picker" ref={emojiPicker}>
              <NimblePicker
                onSelect={handleSelectEmoji}
                showSkinTones={false}
                emojiTooltip={true}
                showPreview={false}
                sheetSize={32}
                data={data}
              />
            </span>
          ) : (
            <button className="emoji__button mr-3 emoji_mob">
              <span
                className="customCursor"
                onClick={() => setShowEmojis(true)}
              >
                {String.fromCodePoint(0x1f60a)}
              </span>
            </button>
          )}
          <button className="commentpost col-3 mr-4 emoji_sub">
            <span className="customCursor" onClick={() => _handleSubmit()}>
              {t("Post")}
            </span>
          </button>
        </div>
      </div>
    </StylesViaJss>
  );
}
export default CommentList;
