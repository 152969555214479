import React, { useState, useEffect } from "react";
import BackButtonComponent from "../../Utilities/BackButtonComponent";
import BackButton from "../../Utilities/BackButton";
import { useParams } from "react-router";
import SignatureGenerator from "./SignatureGenerator";
import { t } from "../../Translation/Translation";
import { useHistory } from "react-router-dom";
import { UserProfile } from "../../Services/ApiEndPoints";
import { APICALL } from "../../Services/ApiServices";

function Signature() {
  const [signatureForm, setsignatureForm] = useState({
    salutation: "Kind regards",
    user_name: "",
    job_title: "",
    mobile: "",
    direct_phone: "",
    office_phone: "",
    email: "",
  });

  const [bleckmann_entity, setbleckmann_entity] = useState();
  const [bleckmann_address, setbleckmann_address] = useState();
  const [currentTab, setCurrentTab] = useState(1);
  const [DeviceMode, setDeviceMode] = useState(false);
  const [backbuttonUrl, setbackbuttonUrl] = useState();
  const [urlState, seturlState] = useState(false);
  const [listviewMode, setlistviewMode] = useState(false);
  let params = useParams();
  const history = useHistory();

  //To fetch data from API
  useEffect(() => {
    const get_id = localStorage.getItem("id");
    APICALL.service(UserProfile + get_id, "GET").then((result) => {
      if (result !== undefined) {
        try {
          setsignatureForm({
            ...signatureForm,
            user_name: result.firstName + " " + result.lastName,
            job_title: result.userJobFunction,
            mobile:
              result.personalPhoneNumber === null
                ? ""
                : result.personalPhoneNumber,
            direct_phone:
              result.workPhoneNumber === null ? "" : result.workPhoneNumber,
            email: result.workEmail,
          });
        } catch (e) {
          console.error(e);
        }
      }
    });
  }, [currentTab]);

  const updateField = (e) => {
    //Updating the signatureForm
    setsignatureForm({
      ...signatureForm,
      [e.target.name]: e.target.value,
    });
  };

  useEffect(() => {
    if (localStorage.getItem("DeviceMode") === "Desktop") {
      setDeviceMode(true);
    }
  }, []);

  useEffect(() => {
    if (urlState === false) {
      if (params.id === undefined) {
        setbackbuttonUrl("/signature");
      } else if (params.id === "1" || params.id === "2") {
        setCurrentTab(parseInt(params.id));
        setbackbuttonUrl("/signature/" + params.id);
      }
      seturlState(true);
    }
  }, []);

  function historyBack() {
    let response = BackButton();
    history.push(response);
  }

  //backbotton form details page to listview
  const backButton = (e) => {
    setlistviewMode(e);
  };

  //TO update detail page when user click on list item
  const updateTab = (e) => {
    setCurrentTab(e);
    if (localStorage.getItem("DeviceMode") === "Desktop") {
      setlistviewMode(false);
    } else {
      setlistviewMode(true);
    }
  };

  function UpdateNumbers(number, type) {
    setsignatureForm({
      ...signatureForm,
      [type]: number,
    });
  }

  function SelectionChange(e, type) {
    if (type === "bleckmann_entity") setbleckmann_entity(e);
    else setbleckmann_address(e);
  }

  const listMode = () => {
    return (
      <div className="container customContainer">
        <div className="post">
          <div className="mx-0">
            <div className="col-md-12 topsection-wrap  mt-0 px-0 pt-4">
              {/* <p className={DeviceMode === true ? "hide-class" : "browserback"} onClick={history.goBack}>back</p> */}
              <p
                className={DeviceMode === true ? "hide-class" : "browserback"}
                onClick={historyBack}
              >
                back
              </p>
              {backbuttonUrl && (
                <BackButtonComponent backButtonLink={backbuttonUrl} />
              )}
              <h1 className="pagetitle textcolor1 text-center">
                {t("Email Signature")}
              </h1>
            </div>
          </div>
          <div className="mt-5">
            <div className="row my-4">
              <div className="col-md-9 col-9">
                <div>
                  <h4>{t("Email")}</h4>
                </div>
              </div>
              <div className="col-md-3 col-3">
                <span
                  onClick={() => updateTab(1)}
                  className="customCursor textcolor1 mx-3"
                >
                  Create
                </span>
              </div>
            </div>
            {/* <div className="row my-1">
              <div className="col-md-9 col-9">
                <div>
                  <h4>{t("Reply email")}</h4>
                </div>
              </div>
              <div className="col-md-3 col-3">
                <span
                  onClick={() => updateTab(2)}
                  className="customCursor textcolor1 mx-3"
                >
                  Create
                </span>
              </div>
            </div> */}
          </div>
        </div>
      </div>
    );
  };

  return (
    <div>
      {DeviceMode === true ? (
        <div className="Desktop-mode-list-and-detail-view layoutSpace">
          <div className="Desktop-mode-list-view float-lg-left col-lg-4 pr-0">
            <div className="customscroll">{listMode()}</div>
          </div>
          <div className="Desktop-mode-Detail-view float-lg-left col-lg-8 pr-4 pl-1 location-desk">
            {/* <div className="customscroll">{defaultitem && <SignatureGenerator signatureForm={signatureForm} updateField={updateField}/>}</div> */}
            <div className="customscroll">
              <SignatureGenerator
                signatureForm={signatureForm}
                bleckmann_entity={bleckmann_entity}
                bleckmann_address={bleckmann_address}
                updateField={updateField}
                currentTab={currentTab}
                UpdateNumbers={UpdateNumbers}
                SelectionChange={SelectionChange}
                backButton={backButton}
              />
            </div>
          </div>
        </div>
      ) : listviewMode === false && params.id === undefined ? (
        listMode()
      ) : (
        <SignatureGenerator
          signatureForm={signatureForm}
          bleckmann_entity={bleckmann_entity}
          bleckmann_address={bleckmann_address}
          updateField={updateField}
          currentTab={currentTab}
          UpdateNumbers={UpdateNumbers}
          SelectionChange={SelectionChange}
          backButton={backButton}
        />
      )}
    </div>
  );
}

export default Signature;
